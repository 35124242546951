import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import constant from '../_config/constant';

const PrivateRoute = ({ component: Component, ...rest }) => {
    return (
        <Route {...rest} render={props => (
            localStorage.getItem(constant.TOKEN_KEY) ? <Component {...props} /> : <Redirect to={{ pathname: '/', state: { from: props.location } }} />
        )} />
    );

};

export default PrivateRoute;
