import constant from '../_config/constant';
import { authHeader } from '../_helpers';
import { history } from '../_helpers/history';
import { handleResponse } from "../_helpers/utils";

export const domicileService = {
    list,
    add,
    update,
    updateStatus,
    getDetail,
    // logout
};


function list(data) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
    };
    return fetch(`${constant.apiUrl}/domicile/list?search=${data.search}`, requestOptions)
        .then(handleResponse)
        .then(user => {
            return user;
        });
}

function updateStatus(data) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };
    return fetch(`${constant.apiUrl}/domicile/updateStatus`, requestOptions)
        .then(handleResponse)
        .then(user => {
            return user;
        });
}
function getDetail(id) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
    };
    return fetch(`${constant.apiUrl}/domicile/detail/${id}`, requestOptions)
        .then(handleResponse)
        .then(user => {
            return user;
        });
}
function add(data) {
    var fd = new FormData();
    fd.append('cityName', data.cityName);
    fd.append('pageLink', data.pageLink);
    fd.append('file', data.file);
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader() },
        body: fd
    };

    return fetch(`${constant.apiUrl}/domicile/add`, requestOptions)
        .then(handleResponse)
        .then(user => {
            return user;
        });
} function update(data) {
    var fd = new FormData();
    fd.append('cityName', data.cityName);
    fd.append('pageLink', data.pageLink);
    fd.append('domicileId', data.domicileId);
    fd.append('file', data.file);

    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader() },
        body: fd
    };
    return fetch(`${constant.apiUrl}/domicile/update`, requestOptions)
        .then(handleResponse)
        .then(user => {
            return user;
        });
}

// function logout() {
//     // remove user from local storage to log user out
//     localStorage.removeItem(constant.TOKEN_KEY);
//     localStorage.removeItem(constant.DATA_KEY);
// }

// function handleResponse(response) {
//     return response.text().then(text => {
//         const data = text && JSON.parse(text);
//         if (data.statusCode == 401 || data.statusCode == 403) {
//             logout();
//             window.location.reload();
//         }
//         if (data.statusCode != 200) {
//             const error = (data && data.userMessage) || response.statusText;
//             return Promise.reject(error);
//         }
//         return data;
//     });
// }