import React, { useEffect, useState, Component } from "react";
import axios from "axios";
import jwt_decode from "jwt-decode";
import Navbar from "./../../components/Navbar";
import TopBar from "./../../components/topbar";
import { feeService, generalService } from "../../_services";
import constant from "../../_config/constant";
import Footer from "./../../layout/footer";
import { toast, ToastContainer } from "react-toastify";
import { Wrapper } from "../Dashboard/Wrapper";

const MaintenanceModeButton = () => {
  const [siteSettings, setSiteSettings] = useState(null);
  const [isMaintenanceMode, setIsMaintenanceMode] = useState(false);
  const [isMaintenanceModeHost, setIsMaintenanceModeHost] = useState(false);
  const [refresh, setRefresh] = useState(1);

  useEffect(() => {
    generalService
      .siteSettings()
      .then((data) => {
        setSiteSettings(data.data);
        setIsMaintenanceMode(data.data.maintenanceMode);
        setIsMaintenanceModeHost(data.data.maintenanceModeHost)
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  const handleNavigationUpdate = async () => {
    try {
      const data = await generalService.updateMaintenanceMode(
        !isMaintenanceMode
      );
      setSiteSettings(data.data);
      setIsMaintenanceMode((prev) => !prev);
      toast.success("Update Maintenance Mode Successfuly");
    } catch (error) {
      console.log(error);
      console.log("Failed to Update Maintenance mode status");
    }
  };
  const handleHostNavigationUpdate = async () => {
    try {
      const data = await generalService.updateHostMaintenanceMode(
        !isMaintenanceModeHost
      );
      setSiteSettings(data.data);
      setIsMaintenanceModeHost((prev) => !prev);
      toast.success("Update Maintenance Mode Successfuly");
    } catch (error) {
      console.log(error);
      console.log("Failed to Update Maintenance mode status");
    }
  };


  return (
    <div className="form-group row kimargin m-b-10 ">
      <label for="staticEmail" className="col-sm-2 col-form-label">
       Guest Maintenance Mode
      </label>
      <div className="col-sm-10">
        <button
          onClick={handleNavigationUpdate}
          className={`toggle-button-maintenance ${
            isMaintenanceMode ? "on" : "off"
          }`}
          aria-pressed={isMaintenanceMode}
          aria-label={
            isMaintenanceMode ? "Disable Maintenance" : "Enable Maintenance"
          }
        >
          <div className="form-check form-switch">
            <input
              className="form-check-input tw-h-5"
              type="checkbox"
              role="switch"
              checked={isMaintenanceMode}
            />
          </div>
        </button>
      </div>
      <label for="staticEmail" className="col-sm-2 col-form-label mt-4">
        Host Maintenance Mode
      </label>
      <div className="col-sm-10">
        <button
          onClick={handleHostNavigationUpdate}
          className={`toggle-button-maintenance ${
            isMaintenanceMode ? "on" : "off"
          }`}
          aria-pressed={isMaintenanceModeHost}
          aria-label={
            isMaintenanceModeHost ? "Disable Maintenance" : "Enable Maintenance"
          }
        >
          <div className="form-check form-switch mt-4">
            <input
              className="form-check-input tw-h-5"
              type="checkbox"
              role="switch"
              checked={isMaintenanceModeHost}
            />
          </div>
        </button>
      </div>
    </div>
  );
};

export default function ManageFee(props) {
  const [requesting, setRequesting] = useState(false);
  const [id, setId] = useState(null);
  const [hostCommission, setHostCommission] = useState(null);
  const [guestService, setGuestService] = useState(null);
  const [crewmatesFlatPenalty, setCrewmatesFlatPenalty] = useState(null);

  const handleFeeUpdate = async (e) => {
    try {
      e.preventDefault();
      setRequesting(true);
      const res = await feeService.updateFee({
        hostCommissionFee: hostCommission,
        guestServiceFee: guestService,
        crewmatesFlatPenaltyFee: crewmatesFlatPenalty,
        id,
      });

      if (res.status) {
        toast.success(res.message);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setRequesting(false);
    }
  };

  useState(() => {
    const fetch = async () => {
      const { data } = await feeService.getFee();

      if (data.length) {
        setGuestService(parseFloat(data[data.length - 1].guestServiceFee) || 0);
        setHostCommission(
          parseFloat(data[data.length - 1].hostCommissionFee) || 0
        );
        setCrewmatesFlatPenalty(
          parseFloat(data[data.length - 1].crewmatesFlatPenaltyFee) || 0
        );
        if (data[data.length - 1].id) {
          setId(data[data.length - 1].id);
        }
      }
    };

    fetch();
  }, []);

  //  ^(0|[1-9][0-9]?|100)$
  const handleHostCommisionChange = (e) => {
    const value = e.target.value;

    // regex to check number between 0 to 100
    if (!/^(100|[1-9]?[0-9])$/.test(value) || value == "") {
      toast.warn("Please enter a number between 0 and 100");
      setHostCommission("");
    } else {
      setHostCommission(value);
    }
  };

  const handleGuestServiceChange = (e) => {
    const value = e.target.value;

    // regex to check number between 0 to 100
    if (!/^(100|[1-9]?[0-9])$/.test(value) || value == "") {
      toast.warn("Please enter a number between 0 and 100");
      setGuestService("");
    } else {
      setGuestService(value);
    }
  };

  return (
    <Wrapper title="Settings">
      <div className="row">
        <div className="col-xl-12">
          <div className="tab-content">
            <div className="tab-pane active show mb-4" id="All">
              <MaintenanceModeButton />
            </div>
            <div className="tab-pane active show" id="All">
              <form onSubmit={handleFeeUpdate} enctype="multipart/form-data">
                <div className="form-group row kimargin m-b-10">
                  <label for="staticEmail" className="col-sm-2 col-form-label">
                    Host Commission Fee (%)
                  </label>
                  <div className="col-sm-10">
                    <input
                      type="text"
                      name="title"
                      pattern="^(0|[1-9][0-9]?|100)$"
                      value={hostCommission}
                      onChange={handleHostCommisionChange}
                      className="form-control"
                      placeholder="Host Commission"
                      required
                    />
                  </div>
                </div>
                <div className="form-group row kimargin m-b-10">
                  <label for="staticEmail" className="col-sm-2 col-form-label">
                    Guest Service Fee (%)
                  </label>
                  <div className="col-sm-10">
                    <input
                      type="text"
                      name="title"
                      value={guestService}
                      onChange={handleGuestServiceChange}
                      className="form-control"
                      placeholder="Guest Service"
                      required
                    />
                  </div>
                </div>
                <div className="form-group row kimargin m-b-10">
                  <label for="staticEmail" className="col-sm-2 col-form-label">
                    Crewmates Flat Penalty Fee
                  </label>
                  <div className="col-sm-10">
                    <input
                      type="text"
                      name="title"
                      value={crewmatesFlatPenalty}
                      onChange={(e) => setCrewmatesFlatPenalty(e.target.value)}
                      className="form-control"
                      placeholder="Guest Service"
                      required
                    />
                  </div>
                </div>

                <div className="form-group row kimargin m-b-10">
                  <label
                    for="inputPassword"
                    className="col-sm-2 col-form-label"
                  >
                    &nbsp;
                  </label>
                  <div className="col-sm-10">
                    <button type="submit" className="btn btn-primary">
                      {requesting ? "Please wait ..." : "Update"}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <ToastContainer />
    </Wrapper>
  );
}
