import React, { useEffect, useState } from "react";
import Navbar from "./../../components/Navbar";
import TopBar from "./../../components/topbar";
import { generalService } from "../../_services";
import constant from "../../_config/constant";
import "react-confirm-alert/src/react-confirm-alert.css";
import Footer from "./../../layout/footer";
import Pagination from "react-js-pagination";
import moment from "moment";
import { AiOutlinePlus } from "react-icons/ai";
import CustomModal from "./../../components/ReactModal";
import { processMediaUrl } from "../../_helpers/utils";
import { Wrapper } from "../Dashboard/Wrapper";

function ReportModal({ toggleReportModal, data }) {
  return (
    <div className="tw-bg-white tw-rounded-lg tw-max-h-[100vh] md:tw-max-h-[95vh]   tw-overflow-scroll modal-content">
      <div className="tw-p-4">
        <div className="modal-header tw-sticky tw-top-0 tw-right-0 tw-left-0 tw-bg-white tw-z-10  ">
          <h5 className="modal-title">
            {data.reportedBy.name}-{data.reportedTo.name}{" "}
          </h5>
          <button
            type="button"
            className="tw-bg-transparent tw-border-0"
            onClick={toggleReportModal}
          >
            <span className="tw-cursor-pointer">
              <AiOutlinePlus size={24} className="tw-transform tw-rotate-45" />
            </span>
          </button>
        </div>
        <div className="  tw-my-8 ">
          <div className="tw-grid tw-grid-cols-2 lg:tw-grid-cols-3 tw-text-sm py-4">
            <div>
              <label className="tw-text-gray-800 tw-mb-1 tw-font-medium">
                Booking Id
              </label>
              <p className="tw-text-700">{data.bookingId || "--"}</p>
            </div>
            <div>
              <label className="tw-text-gray-800 tw-mb-1 tw-font-medium">
                Property Id
              </label>
              <p className="tw-text-700">{data.propertyId._id || "--"}</p>
            </div>
            <div>
              <label className="tw-text-gray-800 tw-mb-1 tw-font-medium">
                Listing Name
              </label>
              <p className="tw-text-700">
                {data.propertyId.listingName || "--"}
              </p>
            </div>
          </div>
          <div className="">
            {data.images.map((img) => {
              return (
                <img
                  className="tw-w-full tw-object-cover tw-rounded tw-mb-2 "
                  src={processMediaUrl(img)}
                />
              );
            })}
            {!Boolean(data.images.length) && (
              <p className="tw-text-center ">No Images provided</p>
            )}
          </div>
          <label className="tw-text-gray-800 tw-mb-1 tw-font-medium">
            Message
          </label>
          <p className="tw-font-medium tw-text-md"> {data.message}</p>
        </div>
      </div>
    </div>
  );
}

export default function List(props) {
  const [showReportModal, setShowReportModal] = useState(false);
  const [reportData, setReportData] = useState({});
  const [requesting, setRequesting] = useState(true);
  const [list, setList] = useState([]);
  const [search, setSearch] = useState("");
  const [refreshKey, setRefreshKey] = useState(0);
  var data = localStorage.getItem(constant.DATA_KEY);
  const decoded = data ? JSON.parse(data) : {};

  var limit = 10;
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);

  const pageChange = (page) => {
    setPage(page);
    setRequesting(true);
    setRefreshKey((oldKey) => oldKey + 1);
  };

  const toggleReportModal = () => {
    setShowReportModal((e) => !e);
  };

  useEffect(() => {
    generalService.reportList({ search, page }).then(
      (objS) => {
        console.log(objS.data);
        setList(objS.data.docs);
        setRequesting(false);
        setTotal(objS.data.total);
        window.scrollTo({ top: 0, behavior: "smooth" });
      },
      (error) => {
        console.log(error);
      }
    );
  }, [refreshKey]);
  return (
    <Wrapper>
      {showReportModal && (
        <CustomModal>
          <ReportModal
            toggleReportModal={toggleReportModal}
            data={reportData}
          />
        </CustomModal>
      )}
      <TopBar user={decoded} showSearch={true} title="Report List" />
          <div className="row">
            <div className="col-xl-12">
              <div className="tab-content">
                <div className="tab-pane active show" id="All">
                  <div className="table-responsive">
                    <table
                      className="table card-table  display mb-4 dataTablesCard booking-table room-list-tbl table-responsive-lg "
                      id="guestTable-all"
                    >
                      <thead>
                        <tr>
                          <th>Action</th>
                          <th>Booking Id</th>
                          <th>Reported By</th>
                          <th>Reported To</th>
                          <th>Listing Id</th>
                          <th>Listing Name</th>
                          <th>Created At</th>
                        </tr>
                      </thead>
                      <tbody>
                        {list.map((data) => (
                          <tr key={data.id}>
                            <td>
                              <span className="fs-16">
                                <li
                                  onClick={() => {
                                    setReportData(data);
                                    toggleReportModal();
                                  }}
                                  className="tw-text-center tw-cursor-pointer  tw-p-2 tw-px-4 p-2 tw-text-xs tw-bg-primary tw-text-white"
                                >
                                  View
                                </li>
                              </span>
                            </td>
                            <td>
                              <span className="fs-16">{data.bookingId}</span>
                            </td>
                            <td>
                              <span className="fs-16">
                                {data.reportedBy.name}
                              </span>
                            </td>
                            <td>
                              <span className="fs-16">
                                {data.reportedTo.name}
                              </span>
                            </td>
                            <td>
                              <span className="fs-16">
                                {data.propertyId._id}
                              </span>
                            </td>
                            <td>
                              <span className="fs-16">
                                {data.propertyId.listingName}
                              </span>
                            </td>
                            <td>
                              <span className="fs-16">
                                {data.createdAt
                                  ? moment(data.createdAt.split("T")[0]).format(
                                    "MMMM Do, YYYY"
                                  )
                                  : "--"}
                              </span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            {requesting && (
              <div className="tw-w-full tw-my-10 tw-flex tw-items-center tw-justify-center">
                <div className="bg-white tw-w-max rounded shadow-md p-4">
                  <div className="loader"></div>
                </div>
              </div>
            )}
          </div>
          <Pagination
            activePage={page}
            itemsCountPerPage={limit}
            totalItemsCount={total}
            pageRangeDisplayed={10}
            onChange={pageChange}
          />
      <Footer />
    </Wrapper>
  );
}
