import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import moment from "moment";
import constant from "../../_config/constant";
import { processMediaUrl } from "../../_helpers/utils";
import ClockImage from "./clock.svg";

function messageToReadable(arr, userId, channelDetails) {
  function checkAuthor(curr) {
    let type = "received";

    if (curr.state.author === userId.toString()) {
      type = "sent";
    }

    return type;
  }
  return arr.map((curr) => ({
    text: curr.state.body,
    author: curr.state.author,
    type: checkAuthor(curr),
    timestamp: new Date(curr.state.timestamp + ""),
  }));
}

function ConversationCard({ messageInstance, members, channelDetails }) {
  let profile = "user.png";
  let name = "";
  let type = "received";
  let [, userId, hostId] = channelDetails.channelId.split("-");

  if (members[messageInstance.author]) {
    profile = members[messageInstance.author].profile;
    name = members[messageInstance.author].name;
  }

  if (messageInstance.author === hostId) {
    type = "sent";
  }

  switch (type) {
    case "received": {
      return (
        <div className="tw-m-4 tw-mx-2 tw-w-max tw-flex tw-items-end tw-max-w-md">
          <img
            alt=""
            src={processMediaUrl(profile)}
            className="tw-h-8 tw-w-8 tw-rounded-full tw-object-cover tw-mr-2  tw-flex-shrink-0"
          />
          <div>
            <p className="tw-text-xs chat-color tw-p-4 tw-text-black tw-rounded-lg tw-rounded-bl-none tw-relative">
              <span style={{ color: "black" }}>{messageInstance.text}</span>
              <span className=" tw-font-medium tw-text-right tw-text-[12px] tw-mt-2 tw-flex tw-items-center tw-gap-2 tw tw-text-primary-blue">
                <img
                  src={ClockImage}
                  alt="clock"
                  style={{ width: "0.75rem", height: "0.75rem" }}
                />
                {moment(messageInstance.timestamp).fromNow()}
              </span>
              <span
                className="tw-absolute"
                style={{
                  left: "0",
                  bottom: "-8px",
                  widows: "0",
                  height: "0",
                  borderLeft: "0 solid transparent",
                  borderRight: "8px solid transparent",
                  borderTop: "8px solid #E4ECF8",
                }}
              ></span>
            </p>
            <span
              className="tw-block tw-mt-2 tw-font-medium text-primary-blue"
              style={{ fontSize: "14px", color: "rgb(35, 66, 110)" }}
            >
              {" "}
              {name}
            </span>
          </div>
        </div>
      );
    }

    case "sent": {
      return (
        <div className="tw-m-4 tw-mx-2 tw-w-max tw-flex tw-items-end tw-max-w-md tw-ml-auto">
          <div>
            <p className="tw-text-xs chat-color tw-p-4 tw-text-black tw-rounded-lg tw-rounded-br-none tw-relative">
              <span style={{ color: "black" }}>{messageInstance.text}</span>
              <span className=" tw-font-medium tw-text-right tw-text-[12px] tw-mt-2 tw-flex tw-items-center tw-gap-2 tw tw-text-primary-blue">
                <img
                  src={ClockImage}
                  alt="clock"
                  style={{ width: "0.75rem", height: "0.75rem" }}
                />
                {moment(messageInstance.timestamp).fromNow()}
              </span>
              <span
                className="tw-absolute"
                style={{
                  right: "0",
                  bottom: "-8px",
                  widows: "0",
                  height: "0",
                  borderRight: "0 solid transparent",
                  borderLeft: "8px solid transparent",
                  borderTop: "8px solid #E4ECF8",
                }}
              ></span>
            </p>
            <span
              className="tw-block tw-mt-2 tw-font-medium text-primary-blue"
              style={{
                fontSize: "14px",
                textAlign: "right",
                color: "rgb(35, 66, 110)",
              }}
            >
              {name}
            </span>
          </div>
          <img
            alt=""
            src={processMediaUrl(profile)}
            className="tw-h-8 tw-w-8 tw-rounded-full tw-object-cover tw-ml-2  tw-flex-shrink-0"
          />
        </div>
      );
    }

    default: {
      return <p>Specify chat type</p>;
    }
  }
}

function Conversation({ userId, client, channelDetails, setViewList }) {
  const [loading, setLoading] = useState(true);
  const [messages, setMessages] = useState([]);
  const [nextPageFunc, setNextPageFunc] = useState(null);
  const [prevPageFunc, setPrevPageFunc] = useState(null);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasPrevPage, setHasPrevPage] = useState(false);
  const [refreshKey, setRefreshKey] = useState(0);
  const [members, setMembers] = useState({});

  const channel = useRef();
  const chatRef = useRef();

  const data = localStorage.getItem(constant.DATA_KEY);
  const user = data ? JSON.parse(data) : {};

  const scrollToBottom = () => {
    if (chatRef.current) {
      chatRef.current.scrollTop = chatRef.current.scrollHeight;
    }
  };

  const handleMessage = (messages, curr) => {
    setMessages(messages);
    setTimeout(() => {
      scrollToBottom();
    }, 1000);
    if (curr.hasNextPage) {
      setNextPageFunc(curr.nextPage);
      setHasNextPage(true);
    } else setHasNextPage(false);
    if (curr.hasPrevPage) {
      setHasPrevPage(true);
      setPrevPageFunc(curr.prevPage);
    } else setHasPrevPage(false);
  };

  const onLoadingPreviousMessage = async () => {
    const obj = await prevPageFunc;
    const messages = messageToReadable(obj.items, userId);
    handleMessage(messages, obj);
  };

  const onLoadingNextMessage = async () => {
    const obj = await nextPageFunc;
    const messages = messageToReadable(obj.items, userId);
    handleMessage(messages, obj);
  };

  const messagesLoaded = (curr) => {
    const messages = messageToReadable(
      curr.items || [],
      userId,
      channelDetails
    );
    // channel.current.setAllMessagesConsumed();

    handleMessage(messages, curr);
  };

  const setupChatClient = () => {
    setMessages([]);
    setLoading(true);

    client.current
      .getChannelByUniqueName(channelDetails.channelId)
      .then((channel) => {
        return channel;
      })
      .catch((error) => {
        console.log(error);
      })
      .then((cc) => {
        channel.current = cc;
        setRefreshKey((e) => 1 + e);
        cc.getMembers()
          .then((res) => {
            res.forEach((e) =>
              e
                .getUser()
                .then((res) => {
                  setMembers((e) => {
                    let curr = res.state;
                    e[curr.identity] = curr.attributes;
                    return e;
                  });
                })
                .catch((err) => console.log(err))
            );
          })
          .catch((err) => console.log(err));
        return cc
          .join()
          .then((res) => {
            setRefreshKey((e) => e + 1);
          })
          .catch((err) => {});
      })
      .then(() => {
        setLoading(false);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (channelDetails) {
      setupChatClient();
    }
  }, [channelDetails]);

  useEffect(() => {
    const func = async () => {
      if (channel.current) {
        channel.current.getMessages().then(messagesLoaded);

        channel.current.on("messageAdded", function(message) {
          if (channelDetails.channelId === message.channel.uniqueName) {
            messageAdded(message);
          }
        });
      }
    };

    func();

    return function() {
      if (channel.current) {
        channel.current.removeAllListeners();
      }
    };
  }, [channel.current, channelDetails, refreshKey]);

  const messageAdded = (newMessage) => {
    let type;

    if (newMessage.state.author === user.id.toString()) {
      type = "sent";
    } else type = "received";

    setMessages((e) => [
      ...e,
      {
        text: newMessage.state.body,
        author: newMessage.state.author,
        type: type,
        timestamp: new Date(newMessage.state.timestamp + ""),
      },
    ]);
    scrollToBottom();
    // channel.current
    //   .updateLastConsumedMessageIndex(newMessage.index)
    //   .then(() => { })
    //   .catch((err) => { });
  };

  return (
    <div
      className="md:tw-col-span-4 tw-flex tw-flex-col tw-text-sm  tw-overflow-hidden"
      style={{ borderLeft: "1px solid #E4ECF8" }}
    >
      <>
        {channelDetails && channelDetails.property && loading && (
          <>
            <div className=" tw-flex-center tw-h-screen tw-justify-center tw-items-center">
              <div className="tw-bg-white tw-w-max tw-m-auto tw-rounded  tw-p-4">
                <div className="loader"></div>
              </div>
            </div>
          </>
        )}
        {channelDetails && channelDetails.property && !loading && (
          <>
            <div
              className="tw-flex tw-justify-between tw-cursor-pointer  tw-p-4 tw-space-x-2 tw-border-b tw-border-[#E4ECF8] "
              style={{ borderBottom: "1px solid #E4ECF8" }}
            >
              <div className="tw-flex tw-items-center tw-justify-start tw-space-x-4">
                <span
                  className="md:tw-hidden"
                  onClick={() => setViewList((e) => !e)}
                >
                  <IoIosArrowBack size={24} />
                </span>
                <span className="tw-relative tw-flex-shrink-0 tw-z-0">
                  <img
                    alt=""
                    src={processMediaUrl(channelDetails.property.picture)}
                    className="tw-h-11 tw-w-11  tw-rounded-full tw-object-cover  tw-ring-1 tw-ring-gray-700 tw-flex-shrink-0"
                  />
                </span>
                <div className="tw-truncate tw-text-primary-blue">
                  <p className="tw-text-base tw-font-medium tw-mb-1 tw-block tw-truncate text-black tw-flex tw-items-center">
                    {channelDetails.property.name}
                    <span
                      className="tw-block border border-white  tw-rounded"
                      style={{
                        marginLeft: "10px",
                        backgroundColor: "#06d6a0",
                        height: "8px",
                        width: "8px",
                      }}
                    ></span>
                  </p>
                  <p className="tw-mb-0">
                    Host: {channelDetails.property.host}
                  </p>
                  <p className="tw-mb-1">
                    User: {channelDetails.property.user}
                  </p>
                  {channelDetails.property.reservationId && (
                    <p className="tw-mb-1">
                      {channelDetails.property.reservationId}
                    </p>
                  )}
                </div>
              </div>
              <span className="tw-flex tw-items-center tw-space-x-2 tw-text-primary-blue-dim"></span>
            </div>
            <div
              ref={chatRef}
              className="tw-flex-grow tw-overflow-y-scroll tw-scrollbar-hide tw-px-4"
              style={{ scrollbarWidth: "none" }}
            >
              {hasPrevPage && (
                <p
                  onClick={onLoadingPreviousMessage}
                  className="tw-cursor-pointer tw-text-xs tw-w-max tw-mx-auto tw-my-2 tw-text-center tw-p-2 tw-px-4 tw-bg-primary-blue tw-rounded"
                >
                  Load more
                </p>
              )}
              {messages.map((curr, ind) => (
                <ConversationCard
                  user={channelDetails.user}
                  host={channelDetails.host}
                  key={ind}
                  members={members}
                  messageInstance={curr}
                  channelDetails={channelDetails}
                />
              ))}
              {messages.length === 0 && (
                <div className="tw-flex tw-items-center tw-justify-center tw-mt-10">
                  <p>No conversation found!</p>
                </div>
              )}
              {hasNextPage && (
                <p
                  onClick={onLoadingNextMessage}
                  className="tw-cursor-pointer tw-text-xs tw-w-max tw-mx-auto tw-my-2 tw-text-center tw-p-2 tw-px-4 tw-bg-primary-blue  tw-rounded"
                >
                  Next
                </p>
              )}
            </div>
          </>
        )}
        {!channelDetails && <></>}
      </>
    </div>
  );
}

export default Conversation;
