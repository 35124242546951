import axios from "axios";
import constant from "../_config/constant";
import { authHeader } from "../_helpers";

const api = axios.create({ baseURL: constant.apiHostUrl });
// const apiHost = axios.create({ baseURL: constant.apiUrl });

// const getTransactions = async ({ bookingId }) => {
//     const response = await apiHost.get(`/transaction/booking/${bookingId}`, {
//         headers: authHeader(),
//     });
//     return response;
// };

// const getTransactionDetails = async ({ transactionId }) => {
//     // const response = await api.get(`/transaction/${transactionId}/detail`, {
//     const response = await apiHost.get(`/transaction/${transactionId}/detail`, {
//         headers: authHeader(),
//     });
//     return response;
// };

// const getPayouts = async ({ bookingId }) => {
//     console.log("line 2345678------");
//     const response = await api.get(`/transaction/payout/${bookingId}`, {
//         headers: authHeader(),
//     });
//     return response;
// };

// const getPayoutDetails = async ({ payoutId }) => {
//     const response = await api.get(`/transaction/payout/${payoutId}/detail`, {
//         headers: authHeader(),
//     });
//     return response;
// };

// const requestPayout = async () => {
//     const response = await api.post(`/transferVirtualAmountToHostAccount`, null, {
//         headers: authHeader(),
//     });
//     return response;
// };

// const comissionList = async (page, searchValue) => {
//     const response = await apiHost.get(
//         `/crewmate/commissions?page=${page}&reservationId=${searchValue}`,
//         {
//             headers: authHeader(),
//         }
//     );
//     return response;
// };

const retriveCustomBalances = async () => {

    const response = await api.get(`/transaction/custom/balance_retrive`, {
        headers: authHeader(),
    });
    return response;
    //   const requestOptions = {
    //     method: "GET",
    //     headers: { ...authHeader(), "Content-Type": "application/json" },
    //   };

    //   let url = `${constant.apiUrl}/transaction/custom/balance_retrive`;

    //   return fetch(url, requestOptions)
    //     .then(handleResponse)
    //     .then((data) => {
    //       return data;
    //     });
};

export const paymentService = {
    retriveCustomBalances
};
