import axios from "axios";
import constant from "../_config/constant";
import { authHeader } from "../_helpers";
import { history } from "../_helpers/history";

const api = axios.create({ baseURL: constant.apiUrl });

export const generalService = {
  reportList,
  siteSettings,
  updateMaintenanceMode,
  updateHostMaintenanceMode
};

function reportList(data) {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  return fetch(
    `${constant.apiUrl}/report?search=${data.search}&page=${data.page}&limit=${data.limit}`,
    requestOptions
  )
    .then(handleResponse)
    .then((user) => {
      return user;
    });
}

function siteSettings() {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  return fetch(`${constant.apiHostUrl}/site-settings`, requestOptions)
    .then(handleResponse)
    .then((user) => {
      return user;
    });
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem(constant.TOKEN_KEY);
  localStorage.removeItem(constant.DATA_KEY);
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (data.statusCode == 401 || data.statusCode == 403) {
      logout();
      window.location.reload();
    }
    if (data.statusCode != 200) {
      const error = (data && data.userMessage) || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}


async function updateMaintenanceMode(maintenanceMode) {
  try {
    const response = await api.post(
      `/site/update-settings`,
      {
        maintenanceMode,
      },
      { headers: authHeader() }
    );
    return response;
  } catch (error) {
    console.log(error);
  }
};

async function updateHostMaintenanceMode(maintenanceMode) {
  try {
    const response = await api.post(
      `/site/update-settings`,
      {
        maintenanceModeHost: maintenanceMode,
      },
      { headers: authHeader() }
    );
    return response;
  } catch (error) {
    console.log(error);
  }
};
