import React, { useEffect, useState } from "react";
import axios from "axios";
import jwt_decode from "jwt-decode";
import Navbar from "./../../components/Navbar";
import { useParams } from "react-router-dom";
import TopBar from "./../../components/topbar";
import Footer from "./../../layout/footer";
import constant from '../../_config/constant';



export default function editPropertytype(props) {
  var [usertypeid, setUserTypeid] = useState("");
  var [name, setName] = useState("");
  var [lastconversationmessage, setLastConversationMessage] = useState("");
  var [datak, setDatak] = useState([]);
  var [status, setStatus] = useState("");

  const handleUserTypeidChange = (e) => {
    console.clear();
    setUserTypeid(e.target.value.toString());
  };

  const handleStatusChange = (e) => {
    status = setStatus(e.target.value.toString());
  };

  const [list, setList] = useState([]);
  console.log(list);

  const onChangeName = (e) => {
    if (e.target.value != "") {
      list.name = setName(e.target.value.toString());
    }
  };

  const onChangeLastConversationMessage = (e) => {
    if (e.target.value != "") {
      list.lastconversationmessage = setLastConversationMessage(
        e.target.value.toString()
      );
    }
  };

  const params = useParams();

  var data = localStorage.getItem(constant.DATA_KEY);
  const decoded  = data ? JSON.parse(data): {};

  useEffect(() => {
    axios.get("/api/usertype/").then((todo) => setDatak(todo.data));
    axios
      .get("/api/messageboard/" + params.id)
      .then((todo) => setList(todo.data));
  }, []);

  function onEditPost(e) {
    if (list.usertypeid !== undefined) {
      usertypeid = list.usertypeid;
    }

    if (list.name !== undefined) {
      name = list.name;
    }

    if (list.lastconversationmessage !== undefined) {
      lastconversationmessage = list.lastconversationmessage;
    }

    e.preventDefault();
    const postData = {
      usertypeid,
      name,
      lastconversationmessage,
      status,
    };

    axios.patch(`/api/messageboard/${params.id}`, postData).then((response) => {
      // props.onPostAdded();
      props.history.push("/messageboard");
    });
  }

  return (
    <div>
      <TopBar user={decoded} title="Edit"/>
        <div className="deznav">
          <div className="deznav-scroll">
            <Navbar />
          </div>
        </div>

        <div className="content-body">
          <div className="container-fluid">
            <div className="row">
              <div className="col-xl-12">
                <div className="tab-content">
                  <div className="tab-pane active show" id="All">
                    <form onSubmit={onEditPost} enctype="multipart/form-data">
                      <div className="form-group row kimargin">
                        <label
                          for="staticEmail"
                          className="col-sm-2 col-form-label"
                        >
                          User Type
                        </label>
                        <div className="col-sm-10">
                          <select
                            name="usertypeid"
                            className="form-select kiselect"
                            onChange={(e) => handleUserTypeidChange(e)}
                            aria-label="Default select example"
                            required
                          >
                            <option value="">User Type</option>
                            {datak.map((property) => (
                              <option value={property._id}>
                                {property.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="form-group row kimargin">
                        <label
                          // for="staticEmail"
                          className="col-sm-2 col-form-label"
                        >
                          Name
                        </label>
                        <div className="col-sm-10">
                          <input
                            type="text"
                            name="name"
                            value={list.name}
                            onChange={onChangeName}
                            className="form-control"
                            placeholder="Name"
                            required
                          />
                        </div>
                      </div>

                      <div className="form-group row kimargin">
                        <label
                          // for="staticEmail"
                          className="col-sm-2 col-form-label"
                        >
                          Last Conversation Message
                        </label>
                        <div className="col-sm-10">
                          <input
                            type="text"
                            name="lastconversationmessage"
                            value={list.lastconversationmessage}
                            onChange={onChangeLastConversationMessage}
                            className="form-control"
                            placeholder="Last Conversation Message"
                            required
                          />
                        </div>
                      </div>

                      <div className="form-group row kimargin">
                        <label
                          for="staticEmail"
                          className="col-sm-2 col-form-label"
                        >
                          Status
                        </label>
                        <div className="col-sm-10">
                          <select
                            name="status"
                            className="form-select kiselect"
                            onChange={(e) => handleStatusChange(e)}
                            aria-label="Default select example"
                            required
                          >
                            <option value="">Status</option>
                            <option value="Active">Active</option>
                            <option value="In-Active">In-Active</option>
                          </select>
                        </div>
                      </div>

                      <div className="form-group row kimargin">
                        <label
                          for="inputPassword"
                          className="col-sm-2 col-form-label"
                        >
                          &nbsp;
                        </label>
                        <div className="col-sm-10">
                          <button type="submit" className="btn btn-primary">
                            Edit
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
  );
}
