import React, { Component, useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { MdSpaceDashboard } from "react-icons/md";
import loadjs from "loadjs";
import constant from "../_config/constant";


class Landing extends Component {
  componentWillMount() {
    loadjs(
      [
        "/vendor/global/global.min.js",
        "/vendor/jquery-nice-select/js/jquery.nice-select.min.js",
        "/js/custom.min.js",
        "/js/deznav-init.js",
        "/vendor/bootstrap/js/bootstrap.bundle.min.js",
      ],
      {
        async: false,
      }
    );
  }

  render() {
    const loginRegLink = (
      <ul className="navbar-nav">
        <li className="nav-item">
          <Link to="/login" className="nav-link">
            Login
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/register" className="nav-link">
            Register
          </Link>
        </li>
      </ul>
    );

    const userLink = (
      <ul className="metismenu" id="menu">
        <li>
          <Link
            to="/profile"
            className="has-arrow ai-icon"
            aria-expanded="false"
            href="javascript:void(0)"
          >
            <i className="fa fa-user"></i>
            <span className="nav-text">Profile</span>
          </Link>
        </li>
        {/* <li>
          <Link to="/dashboard" className="has-arrow ai-icon" aria-expanded="false" href="javascript:void(0)">
            <i className="flaticon-025-dashboard"></i>
            <span className="nav-text">Dashboard</span>
          </Link>
        </li> */}
        <li>
          <a
            className="has-arrow ai-icon"
            aria-expanded="false"
            href="javascript:void(0)"
          >
            <i className="flaticon-381-user"></i>
            <span className="nav-text">User</span>
          </a>
          <ul aria-expanded="false">
            <li>
              {" "}
              <Link to="/user"> List </Link>{" "}
            </li>
          </ul>
        </li>
        
        <li>
          <a
            className="has-arrow ai-icon"
            aria-expanded="false"
            href="javascript:void(0)"
          >
            <i className="flaticon-381-list"></i>
            <span className="nav-text">Properties</span>
          </a>
          <ul aria-expanded="false">
            <li>
              {" "}
              <Link to="/properties"> Property List </Link>{" "}
            </li>
            <li>
              {" "}
              <Link to="/properties/add"> Add Property </Link>{" "}
            </li>
          </ul>
        </li>
        <li>
          <Link to="/reservation">
            <i className="flaticon-381-list"></i>
            <span className="nav-text">Reservation </span>
          </Link>
        </li>
        <li>
          <Link to="/dashboard">
            <i className="flaticon-381-list"></i>
            <span className="nav-text">Dashboard </span>
          </Link>
        </li>
        {/* <li>
          <a className="has-arrow ai-icon" aria-expanded="false">
            <i className="flaticon-381-list"></i>
            <span className="nav-text">Reservation</span>
          </a>
          <ul aria-expanded="false">
            <li>
              <Link to="/reservation"> Reservation List </Link>
            </li>
          </ul>
        </li> */}
        <li>
          <Link to="/message">
            <i className="fa fa-comment"></i>
            <span className="nav-text">Message </span>
          </Link>
        </li>
        <li>
          <Link to="/managefee">
            <i className="fa fa-coins"></i>
            <span className="nav-text">Settings </span>
          </Link>
        </li>
        {/* <li>
          <a className="has-arrow ai-icon" aria-expanded="false" href="javascript:void(0)">
            <i className="flaticon-381-gift"></i>
            <span className="nav-text">Reservation</span>
          </a>
          <ul aria-expanded="false">
            <li> <Link to="/reservation"> Reservation List</Link> </li>
            <li> <Link to="/reservation/add">Add Reservation</Link> </li>
          </ul>
        </li>

        <li>
          <a className="has-arrow ai-icon" aria-expanded="false" href="javascript:void(0)">
            <i className="flaticon-381-notebook"></i>
            <span className="nav-text">Dispute </span>
          </a>
          <ul aria-expanded="false">
            <li><Link to="/dispute">Dispute List</Link> </li>
            <li><Link to="/dispute/add">Add Dispute</Link></li>
          </ul>
        </li>

        <li>
          <a className="has-arrow ai-icon" aria-expanded="false" href="javascript:void(0)">
            <i className="flaticon-381-error"></i>
            <span className="nav-text">Host Penality</span>
          </a>
        </li>

        <li>
          <a className="has-arrow ai-icon" aria-expanded="false" href="javascript:void(0)" >
            <i className="flaticon-025-dashboard"></i>
            <span className="nav-text">Domicile</span>
          </a>
          <ul aria-expanded="false">
            <li><Link to="/domicile">Domicile List</Link> </li>
            <li><Link to="/domicile/add">Add Domicile</Link> </li>
          </ul>
        </li>
*/}
        <li>
          <a
            className="has-arrow ai-icon"
            aria-expanded="false"
            href="javascript:void(0)"
          >
            <i className="flaticon-381-compass"></i>
            <span className="nav-text">Amenities</span>
          </a>
          <ul aria-expanded="false">
            <li>
              <Link to="/amenity">Amenity List</Link>
            </li>
            <li>
              <Link to="/amenity/add">Add Amenity</Link>{" "}
            </li>
          </ul>
        </li>
        <li>
          <a
            className="has-arrow ai-icon"
            aria-expanded="false"
            href="javascript:void(0)"
          >
            <i className="flaticon-025-dashboard"></i>
            <span className="nav-text">Safety Feature</span>
          </a>
          <ul aria-expanded="false">
            <li>
              <Link to="/safetyfeature">Safety Feature List</Link>
            </li>
            <li>
              <Link to="/safetyfeature/add">Add Safety Feature</Link>{" "}
            </li>
          </ul>
        </li>
        <li>
          <a className="has-arrow ai-icon" aria-expanded="false">
            <i className="fa fa-balance-scale"></i>
            <span className="nav-text">Dispute </span>
          </a>
          <ul aria-expanded="false">
            <li>
              <Link to="/dispute/list"> Dispute List </Link>
            </li>
          </ul>
        </li>
        <li>
          <a
            className="has-arrow ai-icon"
            aria-expanded="false"
            href="javascript:void(0)"
          >
            <i className="flaticon-050-info"></i>
            <span className="nav-text">FAQ</span>
          </a>
          <ul aria-expanded="false">
            <li>
              <Link to="/faq">FAQ List</Link>
            </li>
            <li>
              {" "}
              <Link to="/faq/add">Add FAQ</Link>
            </li>

            <li>
              <a
                className="has-arrow"
                aria-expanded="false"
                href="javascript:void(0)"
              >
                {" "}
                FAQ Category{" "}
              </a>
              <ul aria-expanded="false">
                <li>
                  <Link to="/faqcategory">FAQ Category List</Link>
                </li>
                <li>
                  <Link to="/faqcategory/add">Add FAQ Category</Link>{" "}
                </li>
              </ul>
            </li>

            {/* <li>
              <a className="has-arrow" aria-expanded="false" href="javascript:void(0)" > Faq Sub Category</a>
              <ul aria-expanded="false">
                <li> <Link to="/faqsubcategory">Faq Sub Category List</Link></li>
                <li> <Link to="/faqsubcategory/add">Add Faq Sub Category</Link> </li>
              </ul>
            </li> */}
          </ul>
        </li>
        <li>
          <a
            className="has-arrow ai-icon"
            aria-expanded="false"
            href="javascript:void(0)"
          >
            <i className="flaticon-381-smartphone-5"></i>
            <span className="nav-text">Contact us</span>
          </a>
          <ul aria-expanded="false">
            <li>
              <Link to="/contact-us">List</Link>
            </li>
          </ul>
        </li>
        <li>
          <Link to="/review">
            <i className="fa fa-star"></i>
            <span className="nav-text">Reviews </span>
          </Link>
        </li>
        <li>
          <Link
            to="/report"
            className="has-arrow ai-icon"
            aria-expanded="false"
            href="javascript:void(0)"
          >
            <i className="fa fa-user"></i>
            <span className="nav-text">Reports</span>
          </Link>
        </li>{" "}
        <li>
          <Link
            to="/terms-condition"
            className="has-arrow ai-icon"
            aria-expanded="false"
            href="javascript:void(0)"
          >
            <i className="fa fa-user"></i>
            <span className="nav-text">Terms</span>
          </Link>
        </li>
        {/* <li>
          <a className="has-arrow ai-icon" aria-expanded="false" href="javascript:void(0)">
            <i className="flaticon-381-star"></i>
            <span className="nav-text">Review </span>
          </a>
          <ul aria-expanded="false">
            <li><Link to="/review">Review List</Link></li>
            <li> <Link to="/review/add">Add Review</Link></li>
          </ul>
        </li>

        <li>
          <a className="has-arrow ai-icon" aria-expanded="false" href="javascript:void(0)" >
            <i className="flaticon-025-dashboard"></i>
            <span className="nav-text">Cancellation</span>
          </a>
          <ul aria-expanded="false">
            <li><Link to="/cancellationtype">Cancellation Type List</Link></li>
            <li><Link to="/cancellationtype/add">Add Cancellation Type</Link> </li>
          </ul>
        </li>

        <li>
          <a className="has-arrow ai-icon" aria-expanded="false" href="javascript:void(0)">
            <i className="flaticon-021-command"></i>
            <span className="nav-text">Message </span>
          </a>
        </li>

        <li>
          <a className="has-arrow ai-icon" aria-expanded="false" href="javascript:void(0)">
            <i className="flaticon-381-settings-1"></i>
            <span className="nav-text">Settings </span>
          </a>
        </li>

        <li>
          <a className="has-arrow ai-icon" aria-expanded="false" href="javascript:void(0)">
            <i className="flaticon-025-dashboard"></i>
            <span className="nav-text">Bed Type</span>
          </a>
          <ul aria-expanded="false">
            <li><Link to="/bedtype">Bed Type List</Link></li>
            <li><Link to="/bedtype/add">Add Bed Type</Link> </li>
          </ul>
        </li> */}
      </ul>
    );

    return (
      <div className="deznav-scroll">
        {/* <ul className="navbar-nav">
          <li className="nav-item">
            <Link to="/" className="nav-link">
              Add another link 
            </Link>
          </li>
        </ul> */}
        {localStorage.getItem(constant.TOKEN_KEY) ? userLink : loginRegLink}
      </div>
    );
  }
}

export default withRouter(Landing);
