import React, { useEffect, useState, Component } from "react";
import axios from "axios";
import jwt_decode from "jwt-decode";
import Navbar from "./../../components/Navbar";
import TopBar from "./../../components/topbar";
import Footer from "./../../layout/footer";

import constant from '../../_config/constant';


export default function addPropertytype(props) {
  const [hostname, setHostName] = useState("");
  const [guestname, setGuestName] = useState("");
  const [listingname, setListingName] = useState("");
  const [roomname, setRoomName] = useState("");
  const [bednumber, setBedNumber] = useState("");
  const [status, setStatus] = useState("");

  const handleStatusChange = (e) => {
    console.clear();
    setStatus(e.target.value.toString());
  };
  var data = localStorage.getItem(constant.DATA_KEY);
  const decoded  = data ? JSON.parse(data): {};

  function onCreatePost(e) {
    const postData = {
      hostname,
      guestname,
      listingname,
      roomname,
      bednumber,
      status,
    };

    e.preventDefault();

    axios.post(`/api/penalty`, postData).then((response) => {
      // props.onPostAdded();
      props.history.push("/penalty");
    });
  }

  return (
    <div>
        <TopBar user={decoded} title="Add"/>

        <div className="deznav">
          <div className="deznav-scroll">
            <Navbar />
          </div>
        </div>

        <div className="content-body">
          <div className="container-fluid">
            <div className="row">
              <div className="col-xl-12">
                <div className="tab-content">
                  <div className="tab-pane active show" id="All">
                    <form onSubmit={onCreatePost} enctype="multipart/form-data">
                      <div className="form-group row kimargin">
                        <label
                          for="staticEmail"
                          className="col-sm-2 col-form-label"
                        >
                          Host Name
                        </label>
                        <div className="col-sm-10">
                          <input
                            type="text"
                            name="hostname"
                            value={hostname}
                            onChange={(e) =>
                              setHostName(e.target.value.toString())
                            }
                            className="form-control"
                            placeholder="Host Name"
                            required
                          />
                        </div>
                      </div>

                      <div className="form-group row kimargin">
                        <label
                          for="staticEmail"
                          className="col-sm-2 col-form-label"
                        >
                          Guest Name
                        </label>
                        <div className="col-sm-10">
                          <input
                            type="text"
                            name="guestname"
                            value={guestname}
                            onChange={(e) =>
                              setGuestName(e.target.value.toString())
                            }
                            className="form-control"
                            placeholder="Guest Name"
                            required
                          />
                        </div>
                      </div>

                      <div className="form-group row kimargin">
                        <label
                          for="staticEmail"
                          className="col-sm-2 col-form-label"
                        >
                          Listing Name
                        </label>
                        <div className="col-sm-10">
                          <input
                            type="text"
                            name="listingname"
                            value={listingname}
                            onChange={(e) =>
                              setListingName(e.target.value.toString())
                            }
                            className="form-control"
                            placeholder="Listing Name"
                            required
                          />
                        </div>
                      </div>

                      <div className="form-group row kimargin">
                        <label
                          for="staticEmail"
                          className="col-sm-2 col-form-label"
                        >
                          Room Name
                        </label>
                        <div className="col-sm-10">
                          <input
                            type="text"
                            name="roomname"
                            value={roomname}
                            onChange={(e) =>
                              setRoomName(e.target.value.toString())
                            }
                            className="form-control"
                            placeholder="Room Name"
                            required
                          />
                        </div>
                      </div>

                      <div className="form-group row kimargin">
                        <label
                          for="staticEmail"
                          className="col-sm-2 col-form-label"
                        >
                          Bed Number
                        </label>
                        <div className="col-sm-10">
                          <input
                            type="text"
                            name="bednumber"
                            value={bednumber}
                            onChange={(e) =>
                              setBedNumber(e.target.value.toString())
                            }
                            className="form-control"
                            placeholder="Bed Number"
                            required
                          />
                        </div>
                      </div>

                      <div className="form-group row kimargin">
                        <label
                          for="staticEmail"
                          className="col-sm-2 col-form-label"
                        >
                          Total Amount
                        </label>
                        <div className="col-sm-10">
                          not completed----- (show $75 if host cancels after
                          approving a reservation)
                        </div>
                      </div>

                      <div className="form-group row kimargin">
                        <label
                          for="staticEmail"
                          className="col-sm-2 col-form-label"
                        >
                          Remaining Amount
                        </label>
                        <div className="col-sm-10">
                          not completed----- 99999
                        </div>
                      </div>

                      <div className="form-group row kimargin">
                        <label
                          for="staticEmail"
                          className="col-sm-2 col-form-label"
                        >
                          Status
                        </label>
                        <div className="col-sm-10">
                          <select
                            name="status"
                            value={status}
                            className="form-select kiselect"
                            onChange={(e) => handleStatusChange(e)}
                            aria-label="Default select example"
                            required
                          >
                            <option value="">Status</option>
                            <option value="Complete">Collected</option>
                            <option value="Pending">
                              In process of trying to collect
                            </option>
                            <option value="Closed">Host penalty waived</option>
                          </select>
                        </div>
                      </div>

                      <div className="form-group row kimargin">
                        <label
                          for="inputPassword"
                          className="col-sm-2 col-form-label"
                        >
                          &nbsp;
                        </label>
                        <div className="col-sm-10">
                          <button type="submit" className="btn btn-primary">
                            Save
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
  );
}
