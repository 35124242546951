import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Navbar from "../Navbar";
import TopBar from "../topbar";
import Footer from "../../layout/footer";
import constant from "../../_config/constant";
import Moment from "react-moment";
import Pagination from "react-js-pagination";
import Tippy from "@tippy.js/react";
import { reviewService, propertyService } from "../../_services";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function ReviewList(props) {
  const [requesting, setRequesting] = useState(true);
  const [list, setList] = useState([]);
  const [refreshKey, setRefreshKey] = useState(0);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);

  var limit = 10;

  var data = localStorage.getItem(constant.DATA_KEY);
  const decoded = data ? JSON.parse(data) : {};

  const pageChange = (page) => {
    setPage(page);
    setRequesting(true);
    setRefreshKey((oldKey) => oldKey + 1);
  };

  // const deleteReview = async (id) => {
  //   try {
  //     await reviewService.remove({ reviewId: id });
  //   } catch (error) {
  //     toast.error("Failed to remove review");
  //   } finally {
  //     setRefreshKey((e) => e + 1);
  //   }
  // };

  const handleReviewStatus = async (id) => {
    try {
      await reviewService.remove({ reviewId: id });
    } catch (error) {
      toast.error("Failed to remove review");
    } finally {
      setRefreshKey((e) => e + 1);
    }
  };

  const handlePropertyUnlist = async (id) => {
    try {
      await propertyService.updateStatus({
        propertyId: id,
        status: "Unlisted",
      });
    } catch (error) {
      toast.error("Failed to unlist property");
    } finally {
      setRefreshKey((e) => e + 1);
    }
  };

  useEffect(() => {
    setRequesting(true);

    reviewService.list({ page, limit: 10 }).then(
      (objS) => {
        console.clear();
        console.log(objS.data);
        setList(objS.data.docs);
        setTotal(objS.data.total);
        setRequesting(false);
        // window.scrollTo({ top: 0, behavior: "smooth" });
      },
      (error) => {
        console.log(error);
        setRequesting(false);
      }
    );
  }, [refreshKey]);

  return (
    <div id="main-wrapper">
      <ToastContainer />
      <TopBar user={decoded} showSearch={false} title="Review List" />

      <div className="deznav">
        <div className="deznav-scroll">
          <Navbar />
        </div>
      </div>

      <div className="content-body">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-12">
              <div className="tab-content">
                <div className="tab-pane active show" id="All">
                  <div className="table-responsive">
                    {requesting && (
                      <div className="tw-my-10 tw-flex tw-items-center tw-justify-center">
                        <div className="bg-white tw-w-max rounded shadow-md p-4">
                          <div className="loader"></div>
                        </div>
                      </div>
                    )}
                    <table
                      className="table card-table  display mb-4 dataTablesCard booking-table room-list-tbl table-responsive-lg "
                      id="guestTable-all"
                    >
                      <thead>
                        <tr>
                          <th style={{ minWidth: "140px" }}>Action</th>
                          <th>Review Id</th>
                          <th>Listing Name</th>
                          <th>User Name</th>
                          <th style={{ minWidth: "240px" }}>Message</th>
                          <th>Rating</th>
                          <th>Created At</th>
                          <th>Updated At</th>
                        </tr>
                      </thead>
                      <tbody>
                        {list.map((curr, id) => (
                          <tr key={id}>
                            <td className="tw-flex tw-flex-col tw-space-y-1">
                              <li
                                onClick={(e) => {
                                  handleReviewStatus(curr.id);
                                }}
                                className="tw-cursor-pointer tw-text-center  tw-p-2 tw-px-4 p-2 tw-text-xs tw-bg-primary tw-text-white"
                              >
                                <a id={curr.id} key={curr.id}>
                                  {`${
                                    curr.status === "Inactive" ? "Show" : "Hide"
                                  }`}{" "}
                                  Review
                                </a>
                              </li>
                              {/* {curr.propertyId.status === "Listed" && (
                                <li
                                  onClick={(e) => {
                                    handlePropertyUnlist(curr.propertyId._id);
                                  }}
                                  className="tw-cursor-pointer tw-mt-1 tw-text-center  tw-p-2 tw-px-4 p-2 tw-text-xs tw-bg-primary tw-text-white"
                                >
                                  <a id={curr.id} key={curr.id}>
                                    Unlist Property
                                  </a>
                                </li>
                              )} */}
                            </td>
                            <td>{curr.id}</td>
                            <td>{curr.propertyId.listingName}</td>
                            <td>{curr.userId.name}</td>
                            <td>{curr.message}</td>
                            <td>{curr.rating}</td>
                            <td>
                              <Moment format="MMMM Do, YYYY">
                                {curr.createdAt}
                              </Moment>
                            </td>
                            <td>
                              <Moment format="MMMM Do, YYYY">
                                {curr.updatedAt}
                              </Moment>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Pagination
            activePage={page}
            itemsCountPerPage={limit}
            totalItemsCount={total}
            pageRangeDisplayed={10}
            onChange={pageChange}
          />
        </div>
      </div>

      <Footer />
    </div>
  );
}
