import React, { useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
import Navbar from "./../../components/Navbar";
import { useParams } from "react-router-dom";
import TopBar from "./../../components/topbar";
import { domicileService, userService } from "../../_services";
import constant from '../../_config/constant';
import Footer from "./../../layout/footer";
import { processMediaUrl } from "../../_helpers/utils";


export default function editCategory(props) {
  const [fileData, setFileData] = useState("");
  const getFile = (e) => { setFileData(e.target.files[0]); };
  var [name, setName] = useState("");
  var [description, setDescription] = useState("");

  const [list, setList] = useState({});
  const [requesting, setRequest] = useState(false);


  const onChangeName = (e) => {
    if (e.target.value != "") {
      list.name = setName(e.target.value);
    }
  };
  const onChangeDescription = (e) => {
    if (e.target.value != "") {
      list.description = setDescription(e.target.value);
    }
  };

  const params = useParams();
  var data = localStorage.getItem(constant.DATA_KEY);
  const decoded = data ? JSON.parse(data) : {};

  useEffect(() => {
    domicileService.getDetail(params.id).then(
      objS => {
        if (objS.status) {
          setName(objS.data.cityName);
          setDescription(objS.data.pageLink);
          setList({ name: objS.data.cityName, description: objS.data.pageLink, image: objS.data.image })
        }

      },
      error => { console.log(error) }
    );
  }, []);

  function onEditPost(e) {
    const postData = { cityName: name, pageLink: description, file: fileData, domicileId: params.id };
    e.preventDefault();
    setRequest(true);
    domicileService.update(postData).then(
      objS => { props.history.push("/domicile"); },
      error => { console.log(error) }
    );
  }


  return (
    <div>
      <TopBar user={decoded} showSearch={false} title="Edit Domiclie" />

      <div className="deznav">
        <div className="deznav-scroll">
          <Navbar />
        </div>
      </div>

      <div className="content-body">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-12">
              <div className="tab-content">
                <div className="tab-pane active show" id="All">
                  <form onSubmit={onEditPost} enctype="multipart/form-data">
                    <div className="form-group row kimargin">
                      <label
                        // for="staticEmail"
                        className="col-sm-2 col-form-label"
                      >
                        Name
                      </label>
                      <div className="col-sm-10">
                        <input
                          type="text"
                          name="name"
                          value={list.name}
                          onChange={onChangeName}
                          className="form-control"
                          placeholder="Name"
                          required
                        />
                      </div>
                    </div>

                    <div className="form-group row kimargin">
                      <label
                        className="col-sm-2 col-form-label"
                      >
                        Description
                      </label>
                      <div className="col-sm-10">
                        <input
                          type="text"
                          name="description"
                          value={list.description}
                          onChange={onChangeDescription}
                          className="form-control"
                          placeholder="Description"
                          required
                        />
                      </div>
                    </div>

                    <div className="form-group row kimargin">
                      <label
                        for="staticEmail"
                        className="col-sm-2 col-form-label"
                      >
                        Image
                      </label>
                      <div className="col-sm-10">
                        <input
                          type="file"
                          name="images"
                          onChange={getFile}
                        />
                        <img
                          className="me-3"
                          width="200px"
                          src={processMediaUrl(list.image)}
                        />
                      </div>
                    </div>



                    {/* <div className="form-group row kimargin">
                        <label
                          for="staticEmail"
                          className="col-sm-2 col-form-label"
                        >
                          Status {list.status}
                        </label>
                        <div className="col-sm-10">
                          <select
                            name="status"
                            className="form-select kiselect"
                            // onChange={(e) => handleStatusChange(e)}
                            aria-label="Default select example"
                            required
                          >
                            <option value="">Status</option>
                            <option value="Active">Active</option>
                            <option value="InActive">InActive</option>
                          </select>
                        </div>
                      </div> */}
                    <div className="form-group row kimargin">
                      <label
                        for="inputPassword"
                        className="col-sm-2 col-form-label"
                      >
                        &nbsp;
                      </label>
                      <div className="col-sm-10">
                        <button type="submit" className="btn btn-primary">
                          {requesting ? "Please wait ..." : "Update"}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
