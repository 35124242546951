import React from "react";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { payoutService } from "../../../_services/payout.service";
import moment from "moment";

function PayoutSchedule({ details }) {
  const [fetching, setFetching] = useState(false);
  const [list, setList] = useState([]);
  let totalPrice = details.totalPrice || 55;

  // useEffect(() => {

  //   const fetchPayoutDetails = async () => {
  //     try {
  //       setFetching(true);
  //       console.log("lliner 16-")
  //       const { data } = await payoutService.getPayouts({
          
  //         bookingId: details._id,
  //       });
  //       console.log(
  //         "data OP received in getting payout details in payoutSchedule.js function :::",
  //         data.data
  //       );
  //       setList(data.data);
  //     } catch (error) {
  //       toast.error("Something went wrong!");
  //     } finally {
  //       setFetching(false);
  //     }
  //   };
  //   fetchPayoutDetails();
  // }, []);
  const fetchPayoutDetails = async (id) => {
    try {
      setFetching(true);
      const { data } = await payoutService.getPayouts({
        bookingId: id,
      });
      console.log(
        "data OP received in getting payout details in payoutSchedule.js function :::",
        data.data
      );
      setList(data.data);
    } catch (error) {
      toast.error("Something went wrong!");
    } finally {
      setFetching(false);
    }
  };
  useEffect(() => {
    if (details._id) {
      fetchPayoutDetails(details._id);
    }
  }, [details._id]);
  return (
    <div className="py-4">
      <table className="tw-w-full">
        <thead>
          <tr>
            <td className="tw-p-2 tw-font-medium tw-text-sm tw-text-gray-700">
              Weekly Payout Dates
            </td>
            <td className="tw-p-2 tw-font-medium tw-text-sm tw-text-gray-700">
              Amount
            </td>
            <td className="tw-p-2 tw-font-medium tw-text-sm tw-text-gray-700">
              Status
            </td>
            <td className="tw-p-2 tw-font-medium tw-text-sm tw-text-gray-700">
              Date of Disbursal
            </td>
          </tr>
        </thead>
        <tbody>
          {list.map((data) => (
            <tr>
              <td className="tw-p-2 tw-pr-4">
                {moment(data.startDate.split("T")[0]).format("Do MMMM")}-
                {moment(data.endDate.split("T")[0]).format("Do MMMM")}
              </td>
              <td className="tw-p-2 tw-pr-4">
                {data.payAmount &&
                  data.payAmount.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })}
              </td>
              <td className="tw-p-2 tw-pr-4">{data.status}</td>
              <td className="tw-p-2 tw-pr-4">
                {moment(data.createdAt.split("T")[0]).format("MMMM Do, YYYY")}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {!Boolean(list.length) && (
        <div className="tw-p-8 tw-grid tw-place-content-center">
          No Transactions found
        </div>
      )}
    </div>
  );
}

export default PayoutSchedule;
