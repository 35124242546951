import axios from "axios";
import constant from "../_config/constant";
import { authHeader } from "../_helpers";

const api = axios.create({ baseURL: constant.apiHostUrl });
const apiHost = axios.create({ baseURL: constant.apiUrl });

const getTransactions = async ({ bookingId }) => {
  const response = await apiHost.get(`/transaction/booking/${bookingId}`, {
    headers: authHeader(),
  });
  return response;
};

const getTransactionDetails = async ({ transactionId }) => {
  // const response = await api.get(`/transaction/${transactionId}/detail`, {
  const response = await apiHost.get(`/transaction/${transactionId}/detail`, {
    headers: authHeader(),
  });
  return response;
};

const getPayouts = async ({ bookingId }) => {
  console.log("line 2345678------");
  const response = await api.get(`/transaction/payout/${bookingId}`, {
    headers: authHeader(),
  });
  return response;
};

const getPayoutDetails = async ({ payoutId }) => {
  const response = await api.get(`/transaction/payout/${payoutId}/detail`, {
    headers: authHeader(),
  });
  return response;
};

const requestPayout = async () => {
  const response = await api.post(`/transferVirtualAmountToHostAccount`, null, {
    headers: authHeader(),
  });
  return response;
};
const comissionList = async (page, searchValue) => {
  const response = await apiHost.get(
    `/crewmate/commissions?page=${page}&reservationId=${searchValue}`,
    {
      headers: authHeader(),
    }
  );
  return response;
};

const handlePayoutRequestForAdmin = async () => {
  const response = await apiHost.get(`/crewmate/handle-payout-request-admin`, {
    headers: authHeader(),
  });
  return response;
};

const getGraphData = async (data) => {
  return await api.post("/custom/admin-graph-data", data, {
    headers: authHeader(),
  });
};

export const payoutService = {
  getTransactions,
  getTransactionDetails,
  getPayouts,
  getPayoutDetails,
  requestPayout,
  comissionList,
  handlePayoutRequestForAdmin,
  getGraphData,
};
