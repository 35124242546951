
import constant from '../_config/constant';

export function authHeader() {
    // return authorization header with jwt token
    let token = localStorage.getItem(constant.TOKEN_KEY);
    if (token) {
        return { 'Authorization': 'Bearer ' + token };
    } else {
        return {};
    }
}