import React, { useEffect, useState, Component } from "react";
import axios from "axios";
import jwt_decode from "jwt-decode";
import Navbar from "./../../components/Navbar";
import TopBar from "./../../components/topbar";
import Footer from "./../../layout/footer";
import constant from '../../_config/constant';



export default function addPropertytype(props) {
  const [hostpenalty, setHostpenalty] = useState("");
  const [cancelationdays, setCancelationDays] = useState("");
  const [cancellationnameid, setCancellationNameid] = useState("");
  const [datak, setDatak] = useState([]);
  const [status, setStatus] = useState("");
  const handleCancellationNameidChange = (e) => {
    console.log("AAAAAAAAAAA");
    console.log(e.target.value);
    console.log("AAAAAAAAAAA");
    setCancellationNameid(e.target.value.toString());
  };
  const handleHostPenaltyChange = (e) => {
    console.log("AAAAAAAAAAA");
    console.log(e.target.value);
    console.log("AAAAAAAAAAA");
    setHostpenalty(e.target.value.toString());
  };

  const handleCancelationDaysChange = (e) => {
    console.log("AAAAAAAAAAA");
    console.log(e.target.value);
    console.log("AAAAAAAAAAA");
    setCancelationDays(e.target.value.toString());
  };

  const handleStatusChange = (e) => {
    console.log("AAAAAAAAAAA");
    console.log(e.target.value);
    console.log("AAAAAAAAAAA");
    setStatus(e.target.value.toString());
  };

  var data = localStorage.getItem(constant.DATA_KEY);
  const decoded  = data ? JSON.parse(data): {};

  function onCreatePost(e) {
    const postData = {
      hostpenalty,
      cancelationdays,
      cancellationnameid,
      status,
    };

    e.preventDefault();

    axios.post(`/api/fees`, postData).then((response) => {
      // props.onPostAdded();
      props.history.push("/fees");
    });
  }

  useEffect(() => {
    axios.get("/api/cancellationname/").then((todo) => setDatak(todo.data));
  }, []);

  return (
    <div>
      <TopBar user={decoded} title="Add Fee"/>

        <div className="deznav">
          <div className="deznav-scroll">
            <Navbar />
          </div>
        </div>

        <div className="content-body">
          <div className="container-fluid">
            <div className="row">
              <div className="col-xl-12">
                <div className="tab-content">
                  <div className="tab-pane active show" id="All">
                    <form onSubmit={onCreatePost} enctype="multipart/form-data">
                      <div className="form-group row kimargin">
                        <label
                          for="staticEmail"
                          className="col-sm-2 col-form-label"
                        >
                          Cancellation Name
                        </label>
                        <div className="col-sm-10">
                          <select
                            name="cancellationnameid"
                            className="form-select kiselect"
                            onChange={(e) => handleCancellationNameidChange(e)}
                            aria-label="Default select example"
                            required
                          >
                            <option value="">Cancellation Name</option>
                            {/* {datak.map((property) => (
                              <option value={property._id}>
                                {property.name}
                              </option>
                            ))} */}
                          </select>
                        </div>
                      </div>
                      <div className="form-group row kimargin">
                        <label
                          for="staticEmail"
                          className="col-sm-2 col-form-label"
                        >
                          Do You want to Keep Host Penalty
                        </label>
                        <div className="col-sm-10">
                          <input
                            type="radio"
                            value="1"
                            name="hostpenalty"
                            onChange={(e) => handleHostPenaltyChange(e)}
                          />
                          Yes
                          <input
                            type="radio"
                            value="2"
                            name="hostpenalty"
                            onChange={(e) => handleHostPenaltyChange(e)}
                          />{" "}
                          No
                        </div>
                      </div>

                      <div className="form-group row kimargin">
                        <label
                          for="staticEmail"
                          className="col-sm-2 col-form-label"
                        >
                          Cancellation Before 7 days checkin
                        </label>
                        <div className="col-sm-10">
                          <input
                            type="radio"
                            value="1"
                            name="cancelationdays"
                            onChange={(e) => handleCancelationDaysChange(e)}
                          />
                          Yes
                          <input
                            type="radio"
                            value="2"
                            name="cancelationdays"
                            onChange={(e) => handleCancelationDaysChange(e)}
                          />{" "}
                          No
                        </div>
                      </div>

                      <div className="form-group row kimargin">
                        <label
                          for="staticEmail"
                          className="col-sm-2 col-form-label"
                        >
                          Status
                        </label>
                        <div className="col-sm-10">
                          <select
                            name="status"
                            value={status}
                            className="form-select kiselect"
                            onChange={(e) => handleStatusChange(e)}
                            aria-label="Default select example"
                            required
                          >
                            {" "}
                            <option value=""> Status</option>
                            <option value="Active">Active</option>
                            <option value="InActive">InActive</option>
                          </select>
                        </div>
                      </div>

                      <div className="form-group row kimargin">
                        <label
                          for="inputPassword"
                          className="col-sm-2 col-form-label"
                        >
                          &nbsp;
                        </label>
                        <div className="col-sm-10">
                          <button type="submit" className="btn btn-primary">
                            Save
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
  );
}
