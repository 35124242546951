import { AppConfig } from "../Config";

const constant = {
  TOKEN_KEY: "aut",
  DATA_KEY: "aud",
  BASIC_AUTH: {
    USERNAME: "dummy",
    PASSWORD: "dummy@12345",
  },
  TEMP_REF: "tempRef",
  // googleAPI_KEY: "AIzaSyCryqdJrYXn4RAZ6LU_kC-uYB2Tn2K00_M",
  googleAPI_KEY: AppConfig.googleAPI_KEY,
  lat: 10.99835602,
  lng: 77.01502627,

  // local
  // apiUrl: 'http://localhost:4500/api/v1/admin',
  // apiHostUrl: 'http://localhost:4500/api/v1/user',
  // mediaUrl: 'http://localhost:4500/media/',
  // USER_WEB_URL: "http://35.90.236.251:4400",

  // development server - newly created on 22-08-2023
  apiUrl: AppConfig.apiUrl,
  apiHostUrl: AppConfig.apiHostUrl,
  USER_WEB_URL: AppConfig.USER_WEB_URL,
  mediaUrl: AppConfig.mediaUrl,

  // development server - newly created on 11-01-2022
  // apiUrl: "http://35.90.236.251:4500/api/v1/admin",
  // apiHostUrl: 'http://35.90.236.251:4500/api/v1/user',
  // USER_WEB_URL: "http://35.90.236.251:4400",
  // mediaUrl: "http://35.90.236.251:4500/media/",

  // staging
  // apiUrl: "http://35.85.155.38:4500/api/v1/admin",
  // USER_WEB_URL: "http://35.85.155.38:4400",
  // mediaUrl: "http://35.85.155.38:4500/media/",

  // production
  // apiUrl: "https://api.crewmatesapp.com/api/v1/admin",
  // USER_WEB_URL: "https://crewmatesapp.com",
  // mediaUrl: "https://api.crewmatesapp.com/media/",
};

export default constant;
