import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Navbar from "./../../components/Navbar";
import TopBar from "./../../components/topbar";
import { ContactusService } from "../../_services";
import constant from "../../_config/constant";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Footer from "./../../layout/footer";
import Pagination from "react-js-pagination";
import Tippy from "@tippy.js/react";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import { Wrapper } from "../Dashboard/Wrapper";

export default function List(props) {
  const [requesting, setRequesting] = useState(true);
  const [list, setList] = useState([]);
  const [search, setSearch] = useState("");
  const [refreshKey, setRefreshKey] = useState(0);
  var data = localStorage.getItem(constant.DATA_KEY);
  const decoded = data ? JSON.parse(data) : {};

  var limit = 10;
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);

  const handleSearch = (e) => {
    setSearch(e.target.value);
    setRequesting(true);
    setRefreshKey((oldKey) => oldKey + 1);
  };

  const pageChange = (page) => {
    setPage(page);
    setRequesting(true);
    setRefreshKey((oldKey) => oldKey + 1);
  };

  useEffect(() => {
    ContactusService.listWithPagination({ search, page }).then(
      (objS) => {
        setList(objS.data.docs);
        setRequesting(false);
        setTotal(objS.data.total);
      },
      (error) => {
        console.log(error);
      }
    );
  }, [refreshKey]);

  const exportContacts = () => { 

    ContactusService.exportContacts({search})

   }



  return (
    <Wrapper
      user={decoded}
      showDownload={true}
      showSearch={true}
      handleSearch={handleSearch}
      search={search}
      exportContacts={exportContacts}
      title="Contact us List"
    >
      <div className="row">
        <div className="col-xl-12">
          <div className="tab-content">
            <div className="tab-pane active show" id="All">
              <div className="table-responsive">
                <table
                  className="table card-table  display mb-4 dataTablesCard booking-table room-list-tbl table-responsive-lg "
                  id="guestTable-all"
                >
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Phone</th>
                      <th>Message</th>
                    </tr>
                  </thead>
                  <tbody>
                    {list.map((data) => (
                      <tr key={data.id}>
                        <td>
                          <span className="fs-16">{data.name}</span>
                        </td>
                        <td>
                          <span className="fs-16">{data.email}</span>
                        </td>
                        <td>
                          <span className="fs-16">{data.mobile}</span>
                        </td>
                        <td>
                          <span className="fs-16">{data.message}</span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {requesting && (
          <div className="tw-w-full tw-my-10 tw-flex tw-items-center tw-justify-center">
            <div className="bg-white tw-w-max rounded shadow-md p-4">
              <div className="loader"></div>
            </div>
          </div>
        )}
      </div>
      <Pagination
        activePage={page}
        itemsCountPerPage={limit}
        totalItemsCount={total}
        pageRangeDisplayed={10}
        onChange={pageChange}
      />
      <Footer />
    </Wrapper>
  );
}
