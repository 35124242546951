import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import jwt_decode from "jwt-decode";
import Navbar from "./../../components/Navbar";
import TopBar from "./../../components/topbar";
import { cancellationService } from "../../_services";
import constant from '../../_config/constant';
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import Footer from "./../../layout/footer";



export default function list(props) {
  const [list, setList] = useState([]);
  const [search, setSearch] = useState('');
  const [refreshKey, setRefreshKey] = useState(0);
 
  var data = localStorage.getItem(constant.DATA_KEY);
  const decoded  = data ? JSON.parse(data): {};

  const handleDelete = (e) => {
    var id = e.target.id;
    confirmAlert({
      title: 'Confirm to delete',
      message: 'Are you sure to delete.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            cancellationService.updateStatus({ cancellationTypeId : id, status: "Inactive"}).then(
              objS => { setRefreshKey(oldKey => oldKey +1)},
              error => { console.log(error) }
            );
          }
        },
        {
          label: 'No',
          onClick: () => console.log('Click No')
        }
      ]
    });
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
    setRefreshKey(oldKey => oldKey +1)
  }

 

  useEffect(() => {
    cancellationService.list({ search}).then(
      objS => { setList(objS.data) },
      error => { console.log(error) }
    );
  }, [refreshKey]);
  return (
    <div>
      <TopBar user={decoded} showSearch={true} handleSearch={handleSearch} 
                search={search} title="Cancellation type list" />

        <div className="deznav">
          <div className="deznav-scroll">
            <Navbar />
          </div>
        </div>

        <div className="content-body">
          <div className="container-fluid">
            <div className="row">
              <div className="col-xl-12">
                <div className="tab-content">
                  <div className="tab-pane active show" id="All">
                    <div className="table-responsive">
                      <table
                        className="table card-table  display mb-4 dataTablesCard booking-table room-list-tbl table-responsive-lg "
                        id="guestTable-all"
                      >
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Description</th>
                            <th>Status</th>
                            <th>Action</th>
                            <th className="bg-none"></th>
                          </tr>
                        </thead>
                        <tbody>
                          {list.map((cancellation) => (
                            <tr key={cancellation.id}>
                              <td>
                                <div className="guest-bx">

                                  <div>
                                    <span className="text-primary">
                                      {/* {cancellation.length} */}
                                    </span>
                                    <h4 className="mb-0 mt-1">
                                      <a
                                        className="text-black"
                                        href="guest-detail.html"
                                      >
                                        {cancellation.name}
                                      </a>
                                    </h4>
                                  </div>
                                </div>
                              </td>

                              <td>
                                <div className="guest-bx">
                                  <div>
                                    <span className="text-primary"></span>
                                    <h4 className="mb-0 mt-1">
                                      <a
                                        className="text-black"
                                      >
                                        {cancellation.description}
                                      </a>
                                    </h4>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="guest-bx">
                                  <div>
                                    <span className="text-primary"></span>
                                    <h4 className="mb-0 mt-1">
                                      <a
                                        className="text-black"
                                        href="guest-detail.html"
                                      >
                                        {cancellation.status}
                                      </a>
                                    </h4>
                                  </div>
                                </div>
                              </td>

                              <td>
                                <div className="dropdown dropstart">
                                  <a
                                    href="javascript:void(0);"
                                    className="btn-link"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    <svg
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M11 12C11 12.5523 11.4477 13 12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12Z"
                                        stroke="#262626"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                      <path
                                        d="M18 12C18 12.5523 18.4477 13 19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12Z"
                                        stroke="#262626"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                      <path
                                        d="M4 12C4 12.5523 4.44772 13 5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12Z"
                                        stroke="#262626"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                    </svg>
                                  </a>
                                  <div className="dropdown-menu">
                                    <Link
                                      className="dropdown-item"
                                      to={"/cancellationtype/edit/" + cancellation.id}
                                    >
                                      Edit
                                    </Link>
                                    <a
                                      className="dropdown-item"
                                      id={cancellation.id}
                                      key={cancellation.id}
                                      onClick={handleDelete}
                                    >
                                      Delete
                                    </a>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
  );
}
