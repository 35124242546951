import constant from "../_config/constant";
import { handleResponse } from "../_helpers/utils";
import { authHeader, 
  // logout, 
  basicAuthHeader } from "../_helpers";

function getFee() {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  return fetch(`${constant.apiUrl}/fee`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

function updateFee(data) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };
  return fetch(`${constant.apiUrl}/fee`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

export const feeService = {
  getFee,
  updateFee,
};

// function handleResponse(response) {
//   return response.text().then((text) => {
//     const data = text && JSON.parse(text);
//     if (data.statusCode == 401 || data.statusCode == 403) {
//       logout();
//       window.location.reload();
//     }
//     if (data.statusCode != 200) {
//       const error = (data && data.userMessage) || response.statusText;
//       return Promise.reject(error);
//     }
//     return data;
//   });
// }
