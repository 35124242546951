import React, { useEffect, useState } from "react";
import Navbar from "./../../components/Navbar";
import { useParams } from "react-router-dom";
import TopBar from "./../../components/topbar";
import { amenityService, userService } from "../../_services";
import constant from '../../_config/constant';
import Footer from "./../../layout/footer";
import { processMediaUrl } from "../../_helpers/utils";
import { Wrapper } from "../Dashboard/Wrapper";

export default function editProperty(props) {
  const [fileData, setFileData] = useState("");
  const getFile = (e) => { setFileData(e.target.files[0]); };
  var [title, setTitle] = useState("");
  var [status, setStatus] = useState("");
  const [list, setList] = useState({});
  const [requesting, setRequest] = useState(false);


  const onChangeTitle = (e) => {
    if (e.target.value != "") {
      list.title = setTitle(e.target.value);
    }
  };

  const handleStatusChange = (e) => {
    if (e.target.value != "") {
      list.status = setStatus(e.target.value);
    }
  };

  const params = useParams();

  useEffect(() => {
    amenityService.getDetail(params.id).then(
      objS => {
        if (objS.status) {
          setList({ title: objS.data.name, image: objS.data.image, status: objS.data.status })
          setTitle(objS.data.name)
          setStatus(objS.data.status)
        }
      },
      error => { console.log(error) }
    );
  }, []);

  function onEditPost(e) {
    const postData = { name: title, image: fileData, amenityId: params.id, status };
    e.preventDefault();
    setRequest(true);
    amenityService.update(postData).then(
      objS => { props.history.push("/amenity"); },
      error => { console.log(error) }
    );
  }


  return (
    <Wrapper showSearch={false} title="Edit Amentiy">
          <div className="row">
            <div className="col-xl-12">
              <div className="tab-content">
                <div className="tab-pane active show" id="All">
                  <form onSubmit={onEditPost} enctype="multipart/form-data">
                    <div className="form-group row kimargin m-b-10">
                      <label
                        // for="staticEmail"
                        className="col-sm-2 col-form-label"
                      >
                        Title
                      </label>
                      <div className="col-sm-10">
                        <input
                          type="text"
                          name="title"
                          value={list.title}
                          onChange={onChangeTitle}
                          className="form-control"
                          placeholder="Title"
                          required
                        />
                      </div>
                    </div>

                    <div className="form-group row kimargin m-b-10">
                      <label
                        for="staticEmail"
                        className="col-sm-2 col-form-label"
                      >
                        Image
                      </label>
                      <div className="col-sm-10">
                        <input
                          type="file"
                          name="images"
                          onChange={getFile}
                        />
                        <img
                          className="me-3"
                          width="200px"
                          src={processMediaUrl(list.image)}
                        />
                      </div>
                    </div>

                    <div className="form-group row kimargin m-b-10">
                      <label
                        for="staticEmail"
                        className="col-sm-2 col-form-label"
                      >
                        Status
                      </label>
                      <div className="col-sm-10">
                        <select
                          name="status"
                          className="form-select kiselect"
                          value={list.status}
                          onChange={(e) => handleStatusChange(e)}
                          aria-label="Default select example"
                          required
                        >
                          <option value="">Status</option>
                          <option value="Active">Active</option>
                          <option value="Inactive">InActive</option>
                        </select>
                      </div>
                    </div>
                    <div className="form-group row kimargin m-b-10">
                      <label
                        for="inputPassword"
                        className="col-sm-2 col-form-label"
                      >
                        &nbsp;
                      </label>
                      <div className="col-sm-10">
                        <button type="submit" className="btn btn-primary">
                          {requesting ? "Please wait ..." : "Update"}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
      <Footer />
    </Wrapper>
  );
}
