import React, { useEffect, useState } from "react";
import axios from "axios";
import jwt_decode from "jwt-decode";
import Navbar from "./../../components/Navbar";
import { useParams } from "react-router-dom";
import TopBar from "./../../components/topbar";
import Footer from "./../../layout/footer";
import constant from '../../_config/constant';



export default function editList(props) {
  var [name, setName] = useState("");
  var [status, setStatus] = useState("");
  const handleStatusChange = (e) => {
    console.clear();
    console.log(e.target.value);
    status = setStatus(e.target.value.toString());

    console.log(e.target.value);
  };

  const [list, setList] = useState([]);
  console.log(list);

  const onChangeName = (e) => {
    if (e.target.value != "") {
      list.name = setName(e.target.value);
    }
  };

  const params = useParams();

  var data = localStorage.getItem(constant.DATA_KEY);
  const decoded  = data ? JSON.parse(data): {};

  useEffect(() => {
    axios.get("/api/crewtype/" + params.id).then((todo) => setList(todo.data));
  }, []);

  function onEditPost(e) {
    if (list.name !== undefined) {
      name = list.name;
    }

    e.preventDefault();
    const postData = {
      name,
      status,
    };

    axios.patch(`/api/crewtype/${params.id}`, postData).then((response) => {
      // props.onPostAdded();
      props.history.push("/crewtype");
    });
  }

  return (
    <div>
      <TopBar user={decoded} title="Edit Crew type"/>

        <div className="deznav">
          <div className="deznav-scroll">
            <Navbar />
          </div>
        </div>

        <div className="content-body">
          <div className="container-fluid">
            <div className="row">
              <div className="col-xl-12">
                <div className="tab-content">
                  <div className="tab-pane active show" id="All">
                    <form onSubmit={onEditPost} enctype="multipart/form-data">
                      <div className="form-group row kimargin">
                        <label
                          // for="staticEmail"
                          className="col-sm-2 col-form-label"
                        >
                          Crew Type
                        </label>
                        <div className="col-sm-10">
                          <input
                            type="text"
                            name="name"
                            value={list.name}
                            onChange={onChangeName}
                            className="form-control"
                            placeholder="Crew Type"
                            required
                          />
                        </div>
                      </div>

                      <div className="form-group row kimargin">
                        <label
                          for="staticEmail"
                          className="col-sm-2 col-form-label"
                        >
                          Status {list.status}
                        </label>
                        <div className="col-sm-10">
                          <select
                            name="status"
                            className="form-select kiselect"
                            onChange={(e) => handleStatusChange(e)}
                            aria-label="Default select example"
                            required
                          >
                            <option value="">Status</option>
                            <option value="Active">Active</option>
                            <option value="InActive">InActive</option>
                          </select>
                        </div>
                      </div>

                      <div className="form-group row kimargin">
                        <label
                          for="inputPassword"
                          className="col-sm-2 col-form-label"
                        >
                          &nbsp;
                        </label>
                        <div className="col-sm-10">
                          <button type="submit" className="btn btn-primary">
                            Edit
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
  );
}
