import React, { useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
import Navbar from "./../../components/Navbar";
import { useParams } from "react-router-dom";
import TopBar from "./../../components/topbar";
import { cancellationService, userService } from "../../_services";
import constant from '../../_config/constant';
import Footer from "./../../layout/footer";


export default function edit(props) {
  var [name, setName] = useState("");
  var [description, setDescription] = useState("");

  const [list, setList] = useState({});
  const [requesting, setRequest] = useState(false);


  const onChangeName = (e) => {
    if (e.target.value != "") {
      list.name = setName(e.target.value);
    }
  };
  const onChangeDescription = (e) => {
    if (e.target.value != "") {
      list.description = setDescription(e.target.value);
    }
  };

  const params = useParams();
  var data = localStorage.getItem(constant.DATA_KEY);
  const decoded  = data ? JSON.parse(data): {};

  useEffect(() => {
    cancellationService.getDetail(params.id).then(
      objS => {
        if (objS.status) {
          setList({ name: objS.data.name, description: objS.data.description });
          setName(objS.data.name);
          setDescription(objS.data.description);
        }
      },
      error => { console.log(error) }
    );
  }, []);

  function onEditPost(e) {
    const postData = { name , description, cancellationTypeId : params.id };
    e.preventDefault();
    setRequest(true);
    cancellationService.update(postData).then(
      objS => { props.history.push("/cancellationtype"); },
      error => { console.log(error) }
    );
  }


  return (
    <div>
      <TopBar user={decoded} showSearch={false} title="Edit Cancellation type" />

        <div className="deznav">
          <div className="deznav-scroll">
            <Navbar />
          </div>
        </div>

        <div className="content-body">
          <div className="container-fluid">
            <div className="row">
              <div className="col-xl-12">
                <div className="tab-content">
                  <div className="tab-pane active show" id="All">
                    <form onSubmit={onEditPost} enctype="multipart/form-data">
                      <div className="form-group row kimargin">
                        <label
                          // for="staticEmail"
                          className="col-sm-2 col-form-label"
                        >
                          Name
                        </label>
                        <div className="col-sm-10">
                          <input
                            type="text"
                            name="name"
                            value={list.name}
                            onChange={onChangeName}
                            className="form-control"
                            placeholder="Name"
                            required
                          />
                        </div>
                      </div>

                      <div className="form-group row kimargin">
                        <label
                          className="col-sm-2 col-form-label"
                        >
                          Description
                        </label>
                        <div className="col-sm-10">
                          <input
                            type="text"
                            name="description"
                            value={list.description}
                            onChange={onChangeDescription}
                            className="form-control"
                            placeholder="Description"
                            required
                          />
                        </div>
                      </div>

                    

                      {/* <div className="form-group row kimargin">
                        <label
                          for="staticEmail"
                          className="col-sm-2 col-form-label"
                        >
                          Status {list.status}
                        </label>
                        <div className="col-sm-10">
                          <select
                            name="status"
                            className="form-select kiselect"
                            // onChange={(e) => handleStatusChange(e)}
                            aria-label="Default select example"
                            required
                          >
                            <option value="">Status</option>
                            <option value="Active">Active</option>
                            <option value="InActive">InActive</option>
                          </select>
                        </div>
                      </div> */}
                      <div className="form-group row kimargin">
                        <label
                          for="inputPassword"
                          className="col-sm-2 col-form-label"
                        >
                          &nbsp;
                        </label>
                        <div className="col-sm-10">
                          <button type="submit" className="btn btn-primary">
                          {requesting ? "Please wait ..." : "Update"}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
  );
}
