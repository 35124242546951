import React, { useRef } from "react";
// import { Editor } from "@tinymce/tinymce-react";

import Navbar from "../Navbar";
import TopBar from "../topbar";

import "react-confirm-alert/src/react-confirm-alert.css";
import Footer from "../../layout/footer";
import constant from "../../_config/constant";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// importing the plugin js.
// import "tinymce/plugins/image";

export default function TermsAndCondition() {
  const editorRef = useRef();
  var data = localStorage.getItem(constant.DATA_KEY);
  const decoded = data ? JSON.parse(data) : {};

  const handleEditorLog = async () => {
    console.log(editorRef.current.getContent());
  };
  return (
    <div id="main-wrapper">
      <ToastContainer />
      <TopBar user={decoded} showSearch={false} title="Terms and Condition" />

      <div className="deznav">
        <div className="deznav-scroll">
          <Navbar />
        </div>
      </div>

      <div className="content-body">
        <div className="container-fluid">
          <div className="row">
            {/* <Editor
              onInit={(evt, editor) => (editorRef.current = editor)}
              initialValue="<p>This is the initial content of the editor.</p>"
              init={{
                height: 500,
                plugins:
                  "autolink lists link image charmap preview anchor searchreplace visualblocks code fullscreen insertdatetime media table wordcount",
                toolbar:
                  "undo redo | formatselect | " +
                  "bold italic backcolor | alignleft aligncenter " +
                  "alignright alignjustify | bullist numlist outdent indent | " +
                  "removeformat | help",
                content_style:
                  "body { font-family:Helvetica,Arial,sans-serif; font-size:16px }",
              }}
            /> */}
            <div className="tw-flex tw-my-4">
              <button
                className="btn btn-secondary tw-mr-4"
                onClick={handleEditorLog}
              >
                Save as draft
              </button>
              <button className="btn btn-primary" onClick={handleEditorLog}>
                Publish
              </button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
