import React, { useEffect, useState } from "react";
import axios from "axios";
import jwt_decode from "jwt-decode";
import Navbar from "./../../components/Navbar";
import { useParams } from "react-router-dom";
import TopBar from "./../../components/topbar";
import Footer from "./../../layout/footer";
import constant from '../../_config/constant';



export default function editPropertytype(props) {
  var [hostname, setHostName] = useState("");
  var [guestname, setGuestName] = useState("");
  var [listingname, setListingName] = useState("");
  var [roomname, setRoomName] = useState("");
  var [bednumber, setBedNumber] = useState("");
  var [status, setStatus] = useState("");
  const handleStatusChange = (e) => {
    console.clear();
    console.log(e.target.value);
    status = setStatus(e.target.value.toString());

    console.log(e.target.value);
  };

  const [list, setList] = useState([]);
  console.log(list);

  const onChangeHostName = (e) => {
    if (e.target.value != "") {
      list.hostname = setHostName(e.target.value.toString());
    }
  };

  const onChangeGuestName = (e) => {
    if (e.target.value != "") {
      list.guestname = setGuestName(e.target.value.toString());
    }
  };

  const onChangeListingName = (e) => {
    if (e.target.value != "") {
      list.listingname = setListingName(e.target.value.toString());
    }
  };

  const onChangeRoomName = (e) => {
    if (e.target.value != "") {
      list.roomname = setRoomName(e.target.value.toString());
    }
  };

  const onChangeBedNumber = (e) => {
    if (e.target.value != "") {
      list.bednumber = setBedNumber(e.target.value.toString());
    }
  };

  const params = useParams();

  var data = localStorage.getItem(constant.DATA_KEY);
  const decoded  = data ? JSON.parse(data): {};

  useEffect(() => {
    axios.get("/api/penalty/" + params.id).then((todo) => setList(todo.data));
  }, []);

  function onEditPost(e) {
    if (list.hostname !== undefined) {
      hostname = list.hostname;
    }

    if (list.guestname !== undefined) {
      guestname = list.guestname;
    }

    if (list.listingname !== undefined) {
      listingname = list.listingname;
    }

    if (list.roomname !== undefined) {
      roomname = list.roomname;
    }

    if (list.bednumber !== undefined) {
      bednumber = list.bednumber;
    }

    e.preventDefault();
    const postData = {
      hostname,
      guestname,
      listingname,
      roomname,
      bednumber,
      status,
    };

    axios.patch(`/api/penalty/${params.id}`, postData).then((response) => {
      // props.onPostAdded();
      props.history.push("/penalty");
    });
  }

  return (
    <div>
      <TopBar user={decoded} title="Edit"/>

        <div className="deznav">
          <div className="deznav-scroll">
            <Navbar />
          </div>
        </div>

        <div className="content-body">
          <div className="container-fluid">
            <div className="row">
              <div className="col-xl-12">
                <div className="tab-content">
                  <div className="tab-pane active show" id="All">
                    <form onSubmit={onEditPost} enctype="multipart/form-data">
                      <div className="form-group row kimargin">
                        <label
                          // for="staticEmail"
                          className="col-sm-2 col-form-label"
                        >
                          Host Name
                        </label>
                        <div className="col-sm-10">
                          <input
                            type="text"
                            name="hostname"
                            value={list.hostname}
                            onChange={onChangeHostName}
                            className="form-control"
                            placeholder="Host Name"
                            required
                          />
                        </div>
                      </div>

                      <div className="form-group row kimargin">
                        <label
                          // for="staticEmail"
                          className="col-sm-2 col-form-label"
                        >
                          Guest Name
                        </label>
                        <div className="col-sm-10">
                          <input
                            type="text"
                            name="guestname"
                            value={list.guestname}
                            onChange={onChangeGuestName}
                            className="form-control"
                            placeholder="Guest Name"
                            required
                          />
                        </div>
                      </div>

                      <div className="form-group row kimargin">
                        <label
                          // for="staticEmail"
                          className="col-sm-2 col-form-label"
                        >
                          Listing Name
                        </label>
                        <div className="col-sm-10">
                          <input
                            type="text"
                            name="listingname"
                            value={list.listingname}
                            onChange={onChangeListingName}
                            className="form-control"
                            placeholder="Listing Name"
                            required
                          />
                        </div>
                      </div>

                      <div className="form-group row kimargin">
                        <label
                          // for="staticEmail"
                          className="col-sm-2 col-form-label"
                        >
                          Room Name
                        </label>
                        <div className="col-sm-10">
                          <input
                            type="text"
                            name="roomname"
                            value={list.roomname}
                            onChange={onChangeRoomName}
                            className="form-control"
                            placeholder="Room Name"
                            required
                          />
                        </div>
                      </div>

                      <div className="form-group row kimargin">
                        <label
                          // for="staticEmail"
                          className="col-sm-2 col-form-label"
                        >
                          Bed Number
                        </label>
                        <div className="col-sm-10">
                          <input
                            type="text"
                            name="bednumber"
                            value={list.bednumber}
                            onChange={onChangeBedNumber}
                            className="form-control"
                            placeholder="Bed Number"
                            required
                          />
                        </div>
                      </div>

                      <div className="form-group row kimargin">
                        <label
                          for="staticEmail"
                          className="col-sm-2 col-form-label"
                        >
                          Status
                        </label>
                        <div className="col-sm-10">
                          <select
                            name="status"
                            className="form-select kiselect"
                            onChange={(e) => handleStatusChange(e)}
                            aria-label="Default select example"
                            required
                          >
                            <option value="">Status</option>
                            <option value="Complete">Collected</option>
                            <option value="Pending">
                              In process of trying to collect
                            </option>
                            <option value="Closed">Host penalty waived</option>
                          </select>
                        </div>
                      </div>

                      <div className="form-group row kimargin">
                        <label
                          for="inputPassword"
                          className="col-sm-2 col-form-label"
                        >
                          &nbsp;
                        </label>
                        <div className="col-sm-10">
                          <button type="submit" className="btn btn-primary">
                            Edit
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
  );
}
