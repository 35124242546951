import React, { Component } from 'react'
import { userService } from '../_services'
import GoogleLogin from 'react-google-login'
import FacebookLogin from 'react-facebook-login'
import { history } from '../_helpers/history'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import constant from '../_config/constant'
import Footer from '../layout/footer'

class Login extends Component {
    constructor() {
        super()
        this.state = {
            email: '',
            password: '',
            errors: {},
            isRequesting: false,
        }

        this.onChange = this.onChange.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value })
    }
    onSubmit(e) {
        e.preventDefault()

        const user = {
            email: this.state.email,
            password: this.state.password,
        }
        this.setState({ isRequesting: true })
        userService.login(user.email, user.password).then(
            objS => {
                if (objS.status) {
                    localStorage.setItem(constant.TOKEN_KEY, objS.data.token)
                    localStorage.setItem(constant.DATA_KEY, JSON.stringify(objS.data.user))
                    this.props.history.push(`/profile`)
                } else {
                    toast.error('Invalid credential.')
                    this.setState({ isRequesting: false })
                }
            },
            error => {
                toast('Something went wrong')
                this.setState({ isRequesting: false })
            }
        )
    }

    componentDidMount() {
        // Temp fix to hard reaload the app
        const checkTempRef = localStorage.getItem(constant.TEMP_REF)
        if (checkTempRef === 'false' || checkTempRef == null) {
            setTimeout(() => {
                localStorage.setItem(constant.TEMP_REF, 'true')
                window.location.reload()
            }, 1000)
        }
    }

    render() {
        const responseGoogle = response => {
            // console.log(response);
        }

        const responseFacebook = response => {
            // console.log(response);
        }

        return (
            <div className="App">
                <div className=" h-100">
                    <div className="container h-100">
                        <div className="row justify-content-center h-100 align-items-center">
                            <div className="col-md-6">
                                <div className="authincation-content">
                                    <div className="row no-gutters">
                                        <div className="col-xl-12">
                                            <div className="auth-form">
                                                <div className="text-center mb-3">
                                                    <a href="index.html">
                                                        <img src="images/logo-full.png" alt="" />
                                                    </a>
                                                </div>
                                                <h4 className="text-center mb-4">Sign in your account</h4>
                                                <form noValidate onSubmit={this.onSubmit}>
                                                    <div className="mb-3">
                                                        <label className="mb-1">
                                                            <strong>Email</strong>
                                                        </label>
                                                        <input
                                                            type="email"
                                                            name="email"
                                                            className="form-control"
                                                            value={this.state.email}
                                                            onChange={this.onChange}
                                                        />
                                                    </div>
                                                    <div className="mb-3">
                                                        <label className="mb-1">
                                                            <strong>Password</strong>
                                                        </label>
                                                        <input
                                                            type="password"
                                                            name="password"
                                                            className="form-control"
                                                            value={this.state.password}
                                                            onChange={this.onChange}
                                                        />
                                                    </div>
                                                    <div className="row d-flex justify-content-between mt-4 mb-2">
                                                        <div className="mb-3">
                                                            <div className="form-check custom-checkbox ms-1">
                                                                <input type="checkbox" className="form-check-input" id="basic_checkbox_1" />
                                                                <label className="form-check-label" htmlFor="basic_checkbox_1">
                                                                    Remember my preference
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="mb-3">
                                                            <a href="page-forgot-password.html">Forgot Password?</a>
                                                        </div>
                                                    </div>
                                                    <div className="text-center">
                                                        <button type="submit" className="btn btn-primary btn-block">
                                                            {this.state.isRequesting ? 'Please wait ...' : 'Sign Me In'}
                                                        </button>
                                                    </div>
                                                </form>

                                                <div className="new-account mt-3">
                                                    {/* <GoogleLogin
                            clientId="430739116464-s9ah175c79npqi1685fmfpq5en12tb3j.apps.googleusercontent.com"
                            buttonText="Login"
                            onSuccess={responseGoogle}
                            onFailure={responseGoogle}
                            cookiePolicy={"single_host_origin"}
                          /> */}
                                                </div>

                                                <div className="new-account mt-3">
                                                    {/* <FacebookLogin
                            appId="3399777543447910"
                            autoLoad={true}
                            fields="name,email,picture"
                            callback={responseFacebook}
                            cssClass="my-facebook-button-className"
                            icon="fa-facebook"
                          /> */}
                                                </div>
                                                {/* <div className="new-account mt-3">
                          <p>
                            Don't have an account?{" "}
                            <a className="text-primary" href="./register">
                              Sign up
                            </a>
                          </p>
                        </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
                <ToastContainer />
            </div>
        )
    }
}

export default Login
