import constant from "../_config/constant";
import { authHeader } from "../_helpers";
import { handleResponse } from "../_helpers/utils";

export const ContactusService = {
  //   logout,
  listWithPagination,
  exportContacts,
};

async function exportContacts(query) {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  try {
    const response = await fetch(
      `${constant.apiUrl}/contactus/exports?${new URLSearchParams(
        query
      ).toString()}`,
      requestOptions
    );
    if (!response.ok) {
      throw new Error("Failed to export contacts");
    }
    const blob = await response.blob();
    const anchor = document.createElement("a");
    anchor.href = window.URL.createObjectURL(blob);
    anchor.download = "contacts.csv";
    anchor.click();

    console.log("CSV file download initiated");
  } catch (error) {
    console.error("Failed to export contacts", error);
  }
}

function listWithPagination(data) {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  return fetch(
    `${constant.apiUrl}/contactus/list?search=${data.search}&page=${data.page}`,
    requestOptions
  )
    .then(handleResponse)
    .then((user) => {
      return user;
    });
}

// function logout() {
//   // remove user from local storage to log user out
//   localStorage.removeItem(constant.TOKEN_KEY);
//   localStorage.removeItem(constant.DATA_KEY);
// }

// function handleResponse(response) {
//   return response.text().then((text) => {
//     const data = text && JSON.parse(text);
//     if (data.statusCode == 401 || data.statusCode == 403) {
//       logout();
//       window.location.reload();
//     }
//     if (data.statusCode != 200) {
//       const error = (data && data.userMessage) || response.statusText;
//       return Promise.reject(error);
//     }
//     return data;
//   });
// }
