// import React, { useEffect } from "react";
// import ReactDOM  from "react-dom";
// import { IoIosClose as CloseIcon } from "react-icons/io";

// export default function ReceiptModal({title,onClose,children,className,...props}) {

//   return ReactDOM.createPortal(
// <div className="z-high fixed top-0 bottom-0 left-0 bg-gray-800 bg-opacity-80 right-0 flex-center "> commented previously
//     <div className="react-modal tw-bg-opacity-50 tw-fixed tw-top-0 tw-bottom-0 tw-right-0 tw-overflow-scroll  tw-left-0 tw-flex tw-justify-center tw-items-center tw-min-h-screen">

//     <div className=" tw-rounded-2xl tw-w-full lg:tw-w-8/12 tw-mx-auto">
//         {" "}
//         {children}
//     </div>

//     </div>,
//     document.getElementById("modal")
//   )

// }

import React, { useState, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { IoIosClose as CloseIcon } from 'react-icons/io';

export default function ReceiptModal({
  title,
  onClose,
  children,
  className,
  ...props
}) {
  return createPortal(
    // <div className="react-modal tw-bg-opacity-50 tw-fixed tw-top-0 tw-bottom-0 tw-right-0 tw-overflow-scroll  tw-left-0 tw-flex tw-justify-center tw-items-center">
      <div className="react-modal tw-bg-opacity-50 tw-z-high tw-fixed tw-my-7 tw-left-0 tw-bg-gray-800 tw-bg-opacity-80 tw-right-0 tw-flex-center ">
        <div className='flex-shrink-0 receipt-view tw-leading-tight' {...props}>
          {children}
        {/* </div> */}
      </div>
    </div>,
    document.getElementById('modal')
  );
}
