import constant from '../_config/constant'
import { authHeader } from '../_helpers'
import { history } from '../_helpers/history'
import { handleResponse } from '../_helpers/utils'

export const userService = {
    login,
    logout,
    hostList,
    userList,
    changePassword,
    userListWithPagination,
    updateStatus,
    searchUser,
    verifyAirlineEMail,
    exportUsersData,
}
async function exportUsersData(type, search) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
    }

    try {
        const response = await fetch(`${constant.apiUrl}/export/users?type=${type}&search=${search}`, requestOptions)
        if (!response.ok) {
            throw new Error('Failed to export users')
        }
        const blob = await response.blob()
        const anchor = document.createElement('a')
        anchor.href = window.URL.createObjectURL(blob)
        anchor.download = 'users.csv'
        anchor.click()

        console.log('CSV file download initiated')
    } catch (error) {
        console.error('Failed to export users:', error)
    }
}

function searchUser(data) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        // body: JSON.stringify(data),
    }
    return fetch(`${constant.apiUrl}/user/search?type=${data.type}&search=${data.search}`, requestOptions)
        .then(handleResponse)
        .then(user => {
            return user
        })
}

function getBaisAuthHeader() {
    return {
        Authorization: 'Basic ' + btoa(`${constant.BASIC_AUTH.USERNAME}:${constant.BASIC_AUTH.PASSWORD}`),
    }
}

function login(emailId, password) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', ...getBaisAuthHeader() },
        body: JSON.stringify({ emailId, password, type: 'Admin' }),
    }
    return fetch(`${constant.apiUrl}/login`, requestOptions)
        .then(handleResponse)
        .then(user => {
            return user
        })
}

function hostList(data) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
    }
    return fetch(`${constant.apiUrl}/hostList`, requestOptions)
        .then(handleResponse)
        .then(user => {
            return user
        })
}

function userList(data) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
    }
    return fetch(`${constant.apiUrl}/users?type=${data.type}`, requestOptions)
        .then(handleResponse)
        .then(user => {
            return user
        })
}
function userListWithPagination(data) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
    }
    return fetch(`${constant.apiUrl}/users/pagination?type=${data.type}&page=${data.page}&search=${data.search}&payout_verification=${data.payout_verification}`, requestOptions)
        .then(handleResponse)
        .then(user => {
            return user
        })
}
function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem(constant.TOKEN_KEY)
    localStorage.removeItem(constant.DATA_KEY)
    localStorage.removeItem(constant.TEMP_REF)
}

function changePassword(data) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
    }
    return fetch(`${constant.apiUrl}/changePassword`, requestOptions)
        .then(handleResponse)
        .then(user => {
            return user
        })
}

function updateStatus(data) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
    }
    return fetch(`${constant.apiUrl}/updateStatus`, requestOptions)
        .then(handleResponse)
        .then(user => {
            return user
        })
}

// function handleResponse(response) {
//   return response.text().then((text) => {
//     const data = text && JSON.parse(text);
//     if (data.statusCode == 401 || data.statusCode == 403) {
//       logout();
//       window.location.reload();
//     }
//     if (data.statusCode != 200) {
//       const error = (data && data.userMessage) || response.statusText;
//       return Promise.reject(error);
//     }
//     return data;
//   });
// }

function verifyAirlineEMail(data) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
    }
    return fetch(`${constant.apiUrl}/verifyAirlineEmaill`, requestOptions)
        .then(handleResponse)
        .then(user => {
            return user
        })
}
