import React, { Component } from "react";
import Navbar from "./../components/Navbar";
import TopBar from "../components/topbar";
import Footer from "./../layout/footer";
import constant from '../_config/constant';
import { userService } from "../_services";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Wrapper } from "./Dashboard/Wrapper";


class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      decoded: {},
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
      isUpdating: false
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    const decoded = localStorage.getItem(constant.DATA_KEY);
    this.setState({ decoded: decoded ? JSON.parse(decoded) : {} });
  }

  onChange(e) {
    console.log(e.target)
    this.setState({ [e.target.name]: e.target.value });
  }

  onSubmit(e) {
    e.preventDefault();

    if (this.state.newPassword.length < 8) {
      toast.info("New password must have at least 8 characters.");
      return;
    }
    if (this.state.newPassword != this.state.confirmPassword) {
      toast.info("New password and confirm password must be same.");
      return;
    }
    this.setState({ isUpdating: true });
    userService.changePassword({ oldPassword: this.state.oldPassword, newPassword: this.state.newPassword, confirmPassword: this.state.confirmPassword }).then(
      (objS) => {
        if (objS.status) {
          toast.success(objS.message);
        } else {
          toast.error(objS.message);
        }
        this.setState({ isUpdating: false });
      },
      (error) => {
        this.setState({ isUpdating: false });
        toast.error(error.message);
      }
    );
  }

  render() {
    return (
      <Wrapper >
      <ToastContainer />
            <div className="row page-titles">
              <ol className="breadcrumb">
                <li className="breadcrumb-item active">
                  <a href="javascript:void(0)">App</a>
                </li>
                <li className="breadcrumb-item">
                  <a href="javascript:void(0)">Profile</a>
                </li>
              </ol>
            </div>
            <div className="row">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body">
                    <div className="profile-tab">
                      <div className="custom-tab-1">
                        <ul className="nav nav-tabs new-gap-link">
                          <li className="nav-item">
                            <a
                              href="#about-me"
                              data-bs-toggle="tab"
                              className="nav-link active show change-pass"
                            >
                              Details
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              href="#profile-settings"
                              data-bs-toggle="tab"
                              className="nav-link change-pass"
                            >
                              Change Password
                            </a>
                          </li>
                        </ul>
                        <div className="tab-content">
                          <div id="about-me" className="tab-pane fade active show">
                            <div className="profile-personal-info">
                              <h4 className="text-primary mb-4">
                              </h4>
                              <div className="row mb-2">
                                <div className="col-sm-3 col-5">
                                  <h5 className="f-w-500">
                                    Name <span className="pull-end">:</span>
                                  </h5>
                                </div>
                                <div className="col-sm-9 col-7">
                                  <span>{this.state.decoded.name}</span>
                                </div>
                              </div>
                              <div className="row mb-2">
                                <div className="col-sm-3 col-5">
                                  <h5 className="f-w-500">
                                    Email <span className="pull-end">:</span>
                                  </h5>
                                </div>
                                <div className="col-sm-9 col-7">
                                  <span>{this.state.decoded.emailId}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div id="profile-settings" className="tab-pane fade">
                            <div className="pt-3">
                              <div className="settings-form">
                                <h4 className="text-primary"></h4>
                                <form onSubmit={this.onSubmit}>
                                  <div className="row">
                                    <div className="mb-3 col-md-6">
                                      <label className="form-label">Old Password</label>
                                      <input
                                        type="password"
                                        className="form-control"
                                        value={this.state.oldPassword}
                                        name="oldPassword"
                                        onChange={this.onChange}
                                        required
                                      />
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="mb-3 col-md-6">
                                      <label className="form-label">New Password</label>
                                      <input
                                        type="password"
                                        className="form-control"
                                        value={this.state.newPassword}
                                        name="newPassword"
                                        onChange={this.onChange}
                                        required
                                      />
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="mb-3 col-md-6">
                                      <label className="form-label">Confirm Password</label>
                                      <input
                                        type="password"
                                        className="form-control"
                                        value={this.state.confirmPassword}
                                        name="confirmPassword"
                                        onChange={this.onChange}
                                        required
                                      />
                                    </div>
                                  </div>

                                  <button
                                    className="btn btn-primary"
                                    type="submit"
                                    disabled={this.state.isUpdating}
                                  >
                                    {this.state.isUpdating ? 'Please wait ...' : 'Change Password'}
                                  </button>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        <Footer />

      </Wrapper>
    );
  }
}

export default Profile;
