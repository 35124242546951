import React, { useEffect, useRef } from "react";
import Navbar from "../Navbar";
import TopBar from "../topbar";
import constant from "../../_config/constant";
import { NavLink, Route, useParams, useHistory } from "react-router-dom";
import CustomModal from "../ReactModal";
import { AiOutlinePlus } from "react-icons/ai";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { disputeService } from "../../_services";
import { useState } from "react";

import ReceiptModal from "../ReceiptModal";
import { IoMdClose } from "react-icons/io";
// import moment from "moment";
import * as moment from 'moment-timezone';

function DisputeDetails() {
  const reasonRef = useRef();
  const [requesting, setRequesting] = useState(false);
  const [refreshKey, setRefreshkey] = useState(0);
  const [editDisputeModal, setEditDisputeModal] = useState(false);
  const [isRequestingSettlement, setIsRequestingSettlement] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [acceptingDisputeApi, setAcceptingDisputeApi] = useState(false);
  const [reply, setReply] = useState("");
  const [disputeData, setDisputeData] = useState({
    amount: 0,
    bookingId: "",
    conversation: [],
    createdAt: "",
    hostId: "",
    images: [],
    isPaid: false,
    message: "",
    securityDeposite: 0,
    status: "",
    updatedAt: "",
    userId: "",
    isAdminInvolved: false,
    __v: 0,
    _id: "",
  });
  const [lastUserMessageId, setLastUserMessageId] = useState(null);
  const [disputeId, setDisputeId] = useState(null);
  const params = useParams();
  const history = useHistory();

  useEffect(() => {
    setFetching(true);
    const fetchDetails = () => {
      disputeService
        .details(params.id)
        .then((res) => {
          setDisputeData(res.data);
          let convo = res.data.conversation;
          for (let i = convo.length - 1; i >= 0; i--) {
            if (convo[i].type === "Guest") {
              setLastUserMessageId(convo[i].id);
              break;
            }
          }

          setFetching(false);
        })
        .catch((err) => {
          toast.error("Something went wrong!!");
          history.push("/dispute/list");
          setFetching(false);
        });
    };
    fetchDetails();
  }, [params, refreshKey]);

  var data = localStorage.getItem(constant.DATA_KEY);
  const decoded = data ? JSON.parse(data) : {};

  function toggleEditDisputeModal() {
    setEditDisputeModal((e) => !e);
  }

  async function sendReply() {
    if (reply) {
      setRequesting((e) => !e);
      const res = await disputeService.reply(disputeData._id, reply);
      console.log(res);
      setRefreshkey((e) => e + 1);
      setRequesting((e) => !e);
    }
  }

  const ViewDisputeReceipt = ({ onClose, disputeId }) => {
    console.log(
      "Inside view dispute receipt function call in host website------------------------------",
      disputeData
    );
    return (
      <>
        <div className="tw-bg-white p-4 tw-rounded-md tw-relative">
          <span
            onClick={onClose}
            className="tw-right-6 tw-top-6 tw-cursor-pointer align-right"
          >
            <IoMdClose size={32} />
          </span>

          <h2 className="text-3xl font-medium">Dispute receipt</h2>
          <h3 className="text-2xl font-medium my-4">Your Dispute Receipt from Crewmates</h3>
          
          {/* <p>Receipt ID: {disputeData.bookingId}</p>
           */}
          <p className="tw-text-gray-500 tw-mt-2">
          Receipt ID: {disputeData.receiptId?disputeData.receiptId:disputeData.bookingId}{" "}
          {disputeData.updatedAt &&
            `- ${moment(disputeData.updatedAt).tz(disputeData.timezone).format("MMMM DD, YYYY")}`}
        </p>
        <p className="tw-text-gray-500 tw-mt-2">
          Booking ID: {disputeData.originalBookingId}{" "}
        </p>
        <br />
        {/* <br /> */}
        <h3 className="text-2xl font-medium tw-mb-3">Dispute details</h3>
        {/* <br /> */}
          <p className="tw-mb-1">Dispute ID: {disputeData._id}</p>
          <p className="tw-mb-1">Amount Charged: ${parseFloat(disputeData.amount)
                  .toFixed(2)
                  .toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })}</p>

          <hr className="tw-col-span-full" />
          <div className="tw-grid tw-grid-cols-2 mt-3 d-flex align-items-center justify-content-between">
            <div className="flex-col flex">
              <h4 className="text-xl font-medium mb-3">Payment</h4>
              <p className="tw-text-gray-500">{disputeData.cardDetail}</p>
              <p className="tw-text-gray-500">
                {moment(disputeData.createdAt).tz(disputeData.timezone).format(
                  "MMMM DD, YYYY h:mm:ss zz"
                )}{" "}
                {/* EST */}
              </p>
            </div>
            <p className="tw-text-right tw-text-gray-500">
              {`$${parseFloat(disputeData.amount)
                  .toFixed(2)
                  .toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })}`}
            </p>
          </div>
          <hr className="tw-col-span-full" />
          <div className="tw-grid tw-grid-cols-2 mt-3 d-flex align-items-center justify-content-between">
            
          <p className="font-medium text-black">Amount Paid</p>
          
            <p className="font-medium tw-text-right text-black">
            {`$${disputeData.amount &&
                                parseFloat(disputeData.amount).toFixed(2).toLocaleString('en-US', {
                                    style: 'currency',
                                    currency: 'USD',
                                })}`}
            </p>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div id="main-wrapper">
        <ToastContainer />
        <TopBar user={decoded} showSearch={false} title="Dispute Details" />

        <div className="deznav">
          <div className="deznav-scroll">
            <Navbar />
          </div>
        </div>

        <div className="content-body">
          <div className="container-fluid">
            <div>
              {fetching && (
                <div className="tw-my-10 tw-flex tw-items-center tw-justify-center">
                  <div className="bg-white tw-w-max rounded shadow-md p-4">
                    <div className="loader"></div>
                  </div>
                </div>
              )}
            </div>
            {!fetching && (
              <div>
                <div className="tw-grid tw-grid-cols-2 boking-space lg:tw-grid-cols-3 tw-text-sm py-4">
                  <div>
                    <label className="tw-text-gray-800 tw-mb-1 tw-font-medium">
                      Dispute Id
                    </label>
                    <p className="tw-text-700">{disputeData._id}</p>
                  </div>
                  <div>
                    <label className="tw-text-gray-800 tw-mb-1 tw-font-medium">
                      Reservation Id
                    </label>
                    <p className="tw-text-700">{disputeData.originalBookingId}</p>
                  </div>
                  <div>
                    <label className="tw-text-gray-800 tw-mb-1 tw-font-medium">
                      User Name
                    </label>
                    <p className="tw-text-700">{disputeData.userId.name}</p>
                  </div>
                  <div>
                    <label className="tw-text-gray-800 tw-mb-1 tw-font-medium">
                      Security Deposit
                    </label>
                    <p className="tw-text-700">
                      {disputeData.securityDeposite.toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                      })}
                    </p>
                  </div>
                  <div>
                    <label className="tw-text-gray-800 tw-mb-1 tw-font-medium">
                      Payment Status
                    </label>
                    <p className="tw-text-700">
                      <span className="tw-p-2 tw-py-1 tw-text-xs tw-rounded tw-bg-primary tw-text-white">
                        {disputeData.isPaid ? "Paid" : "Not Paid"}
                      </span>
                    </p>
                  </div>
                  <div>
                    <label className="tw-text-gray-800 tw-mb-1 tw-font-medium">
                      Status
                    </label>
                    <p className="tw-text-700">
                      <span className="tw-p-2 tw-py-1 tw-text-xs tw-rounded tw-bg-primary tw-text-white">
                        {disputeData.status}
                      </span>
                    </p>
                  </div>

                  {disputeData.status === "Closed" && <div>
                    <label className="tw-text-gray-800 tw-mb-1 tw-font-medium">
                      Action
                    </label>
                    <p className="tw-text-700"
                    onClick={() => { setDisputeId(disputeData._id); } }
                    >
                      <span className="tw-pr-4 tw-cursor-pointer tw-text-primary">
                        View Dispute Receipt
                      </span>
                    </p>
                  </div>}
                </div>


                <div>
                  <div className="tw-text-xs">
                    <p className="tw-text-700 tw-bg-gray-100 tw-p-2">
                      <span className="tw-font-medium tw-block tw-text-gray-700 ">
                        {disputeData.hostId.name} (Host)
                        <span className="tw-text-gray-500 tw-font-normal ">
                          {/* {moment(disputeData.createdAt).fromNow()} */}
                        </span>
                      </span>

                      <span>
                        <span className="tw-text-gray-600 ">Amount:</span>{" "}
                        {parseFloat(disputeData.securityDeposite).toLocaleString(
                          "en-US",
                          {
                            style: "currency",
                            currency: "USD",
                          }
                        )}
                      </span>
                      <span className="tw-block">
                        <span className="tw-text-gray-600 ">Reason:</span>{" "}
                        {disputeData.message}
                      </span>
                    </p>

                    {disputeData.conversation.map((curr, id) => {
                      return (
                        <div
                          key={id}
                          className="tw-text-700 tw-bg-gray-100 tw-p-2 tw-mb-2"
                        >
                          <span className="tw-font-medium tw-block tw-text-gray-700 ">
                            {curr.type === "Host"
                              ? `${disputeData.hostId.name} (Host)`
                              : curr.type === "Guest"
                                ? `${disputeData.userId.name} (Guest)`
                                : "You"}{" "}
                            <span className="tw-text-gray-500 tw-font-normal ">
                              {/* {moment(curr.createdAt).fromNow()} */}
                            </span>
                          </span>
                          {curr.type !== "Admin" && (
                            <span>
                              <span className="tw-text-gray-600 ">Amount:</span>{" "}
                              {parseFloat(curr.amount).toLocaleString("en-US", {
                                style: "currency",
                                currency: "USD",
                              })}
                            </span>
                          )}
                          <span className="tw-block">
                            <span className="tw-text-gray-600 ">
                              {curr.type === "Admin" ? "Message:" : "Reason:"}{" "}
                            </span>
                            {curr.message}
                          </span>
                        </div>
                      );
                    })}
                  </div>
                  {disputeData.isAdminInvolved &&
                    disputeData.status === "Active" && (
                      <div>
                        <textarea
                          type="text"
                          placeholder="Reply"
                          className="form-control"
                          value={reply}
                          onChange={(e) => setReply(e.target.value)}
                        />
                        <button
                          disabled={requesting}
                          onClick={sendReply}
                          className="tw-mt-2 tw-ml-auto tw-block btn btn-sm btn-primary"
                        >
                          {requesting ? "Sending..." : "Send"}
                        </button>
                      </div>
                    )}
                  {disputeData.status === "Rejected" && (
                    <p className="text-sm tw-font-medium tw-text-gray-700">
                      *Dispute has been Cancelled at{" "}
                      {disputeData.amount.toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                      })}
                    </p>
                  )}
                  {disputeData.status === "Accepted" && (
                    <p className="text-sm tw-font-medium tw-text-gray-700">
                      *Dispute has been Accepted at{" "}
                      {disputeData.amount.toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                      })}
                    </p>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="pb-4">
        {disputeId && (
          <ReceiptModal className="w-full  " onClose={() => setDisputeId(null)}>
            {disputeData ? (

              <ViewDisputeReceipt
                disputeId={disputeId}
                onClose={() => setDisputeId(null)}
              />
            )
              :
              null
            }
          </ReceiptModal>
        )}
      </div>

    </>

  );
}

export default DisputeDetails;
