const APP_ENV = process.env.REACT_APP_APP_ENV || 'dev'

let config

if (APP_ENV === 'dev') {
    config = {
        apiUrl: 'http://localhost:4500/api/v1/admin',
        apiHostUrl: 'http://localhost:4500/api/v1/user',
        USER_WEB_URL: 'http://localhost:3000',
        mediaUrl: 'http://localhost:4500/media/',
        s3Endpoint: 'https://crewmate-media-local.s3.us-west-2.amazonaws.com/',
        googleAPI_KEY: 'AIzaSyBB_rVhDxVEMbjEp5LkCB8m8_JwRY8N5is',
        GOOGLE_CLIENTID: '640276684298-k3h73qel57b2i14sebenr1klcekd4f63.apps.googleusercontent.com',
    }
} else if (APP_ENV === 'production') {
    console.log = () => {}
    config = {
        apiUrl: 'https://nodejs-6421992307235124.kloudbeansite.com/api/v1/admin',
        apiHostUrl: 'https://nodejs-6421992307235124.kloudbeansite.com/api/v1/user',
        USER_WEB_URL: 'https://crewmatesapp.com',
        mediaUrl: 'https://nodejs-6421992307235124.kloudbeansite.com//media/',
        s3Endpoint: 'https://crewmate-production.s3.us-west-2.amazonaws.com/',
        googleAPI_KEY: 'AIzaSyCyeyo0XBjhWKaKgxqv-jK-9yOGzyTUvDo',
    }
} else if (APP_ENV === 'staging') {
    // console.log = () => {};
    config = {
        apiUrl: 'https://devapi.crewmatesapp.com/api/v1/admin',
        apiHostUrl: 'https://devapi.crewmatesapp.com/api/v1/user',
        USER_WEB_URL: 'https://dev.crewmatesapp.com',
        mediaUrl: 'https://devapi.crewmatesapp.com/media/',
        s3Endpoint: 'https://crewmates-dev.s3.us-west-2.amazonaws.com/',
        googleAPI_KEY: 'AIzaSyCyeyo0XBjhWKaKgxqv-jK-9yOGzyTUvDo',
        googleAPI_KEY: 'AIzaSyBB_rVhDxVEMbjEp5LkCB8m8_JwRY8N5is',
        GOOGLE_CLIENTID: '640276684298-k3h73qel57b2i14sebenr1klcekd4f63.apps.googleusercontent.com',
    }
}
// else if (APP_ENV === "staging") {
//   config = {
//     apiUrl: "https://devapi.crewmatesapp.com/api/v1/admin",
//     apiHostUrl: "https://devhost.crewmatesapp.com/api/v1/user",
//     USER_WEB_URL: "https://dev.crewmatesapp.com",
//     mediaUrl: "https://devapi.crewmatesapp.com/media/",
//     s3Endpoint: "https://crewmates-dev.s3.us-west-2.amazonaws.com/",
//     googleAPI_KEY: "AIzaSyBB_rVhDxVEMbjEp5LkCB8m8_JwRY8N5is",
//     GOOGLE_CLIENTID:
//       "640276684298-k3h73qel57b2i14sebenr1klcekd4f63.apps.googleusercontent.com",
//   };
// }
// else if (APP_ENV === "production") {
//   console.log = () => {};
//   config = {
//     apiUrl: "https://api.crewmatesapp.com/api/v1/admin",
//     apiHostUrl: "https://api.crewmatesapp.com/api/v1/user",
//     USER_WEB_URL: "https://crewmatesapp.com",
//     mediaUrl: "https://api.crewmatesapp.com/media/",
//     s3Endpoint: "https://crewmate-production.s3.us-west-2.amazonaws.com/",
//     googleAPI_KEY: "AIzaSyCyeyo0XBjhWKaKgxqv-jK-9yOGzyTUvDo",
//   };
// }

export const AppConfig = config
