import React, { useEffect, useState, Component } from "react";
import Navbar from "./../../components/Navbar";
import TopBar from "./../../components/topbar";
import {
  faqService,
  subCategoryService,
  categoryService,
} from "../../_services";
import constant from "../../_config/constant";
import Footer from "./../../layout/footer";
import { Wrapper } from "../Dashboard/Wrapper";

export default function addCategory(props) {
  const [requesting, setRequest] = useState(false);
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [categoryId, setCategory] = useState("");
  // const [subCategoryId, setSubCategory] = useState("");
  const [categoryList, setCategoryList] = useState([]);
  const [filterCategoryList, setFilterCategoryList] = useState([]);

  const [subcategoryList, setSubCategoryList] = useState([]);
  const [userType, setUserType] = useState("");

  function onCreatePost(e) {
    const postData = { question, answer, categoryId, userType };
    e.preventDefault();
    setRequest(true);
    faqService.add(postData).then(
      (objS) => {
        props.history.push("/faq");
      },
      (error) => {
        console.log(error);
      }
    );
  }

  function setUserTypeAndGetCategory(userType) {
    if(userType){
      setUserType(userType);
      var categories = categoryList.filter((c) => c.userType == userType );
      setFilterCategoryList(categories)
    }else{
      setFilterCategoryList([]);
      setCategory('');
    }
  }

  function setCategoryValue(categoryId) {
    if (categoryId) {
      setCategory(categoryId);
      // setSubCategoryList([]);
      // fetchSubCategoryList(categoryId)
    } else {
      // setSubCategory("");
      setCategory("");
    }
  }

  function fetchSubCategoryList(id) {
    subCategoryService.list({ categoryId: id }).then(
      (objS) => {
        setSubCategoryList(objS.data);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  useEffect(() => {
    categoryService.list({}).then(
      (objS) => {
        setCategoryList(objS.data);
      },
      (error) => {
        console.log(error);
      }
    );
  }, []);

  return (
    <Wrapper title="Add Faq" >
          <div className="row">
            <div className="col-xl-12">
              <div className="tab-content">
                <div className="tab-pane active show" id="All">
                  <form onSubmit={onCreatePost}>
                    <div className="form-group row kimargin tw-mb-2">
                      <label
                        for="staticEmail"
                        className="col-sm-2 col-form-label"
                      >
                        Question
                      </label>
                      <div className="col-sm-10">
                        <textarea
                          type="text"
                          name="question"
                          value={question}
                          onChange={(e) => setQuestion(e.target.value)}
                          className="form-control"
                          placeholder="Question"
                          required
                        ></textarea>
                      </div>
                    </div>

                    <div className="form-group row kimargin tw-mb-2">
                      <label
                        for="staticEmail"
                        className="col-sm-2 col-form-label"
                      >
                        Answer
                      </label>
                      <div className="col-sm-10">
                        <textarea
                          type="text"
                          name="answer"
                          value={answer}
                          onChange={(e) => setAnswer(e.target.value)}
                          className="form-control"
                          placeholder="Answer"
                          required
                        ></textarea>
                      </div>
                    </div>

                    <div className="form-group row kimargin tw-mb-2">
                      <label
                        for="staticEmail"
                        className="col-sm-2 col-form-label"
                      >
                        For User
                      </label>
                      <div className="col-sm-10">
                        <select
                          value={userType}
                          className="form-control"
                          onChange={(e) => setUserTypeAndGetCategory(e.target.value)}
                          required
                        >
                          <option value="">Select</option>
                          <option value="Host">Host</option>
                          <option value="Crewmember">Crewmember</option>
                        </select>
                      </div>
                    </div>

                    <div className="form-group row kimargin tw-mb-2">
                      <label
                        for="staticEmail"
                        className="col-sm-2 col-form-label"
                      >
                        Category
                      </label>
                      <div className="col-sm-10">
                        <select
                          value={categoryId}
                          className="form-control"
                          onChange={(e) => setCategoryValue(e.target.value)}
                          required
                        >
                          <option value="">Select</option>
                          {filterCategoryList.map((option) => (
                            <option value={option.id}>{option.name}</option>
                          ))}
                        </select>
                      </div>
                    </div>

                    {/* <div className="form-group row kimargin">
                        <label
                          for="staticEmail"
                          className="col-sm-2 col-form-label"
                        >
                          Sub Category
                        </label>
                        <div className="col-sm-10">
                          <select
                            value={subCategoryId}
                            className="form-control"
                            onChange={(e) => setSubCategory(e.target.value)}
                            required>
                            <option value="">Select</option>
                            {subcategoryList.map((option) => (
                              <option value={option.id}>{option.name}</option>
                            ))}
                          </select>
                        </div>
                      </div> */}

                    

                    <div className="form-group row kimargin">
                      <label
                        for="inputPassword"
                        className="col-sm-2 col-form-label"
                      >
                        &nbsp;
                      </label>
                      <div className="col-sm-10">
                        <button type="submit" className="btn btn-primary">
                          {requesting ? "Please wait ..." : "Add"}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
      <Footer />
    </Wrapper>
  );
}
