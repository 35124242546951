import React, { useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
import Navbar from "./../../components/Navbar";
import { useParams } from "react-router-dom";
import TopBar from "./../../components/topbar";
import { categoryService, userService } from "../../_services";
import constant from '../../_config/constant';
import Footer from "./../../layout/footer";
import { Wrapper } from "../Dashboard/Wrapper";
export default function editCategory(props) {
  var [name, setName] = useState("");
  var [description, setDescription] = useState("");
  const [userType, setUserType] = useState("");


  const [list, setList] = useState({});
  const [requesting, setRequest] = useState(false);


  const onChangeName = (e) => {
    if (e.target.value != "") {
      list.name = setName(e.target.value);
    }
  };
  const onChangeDescription = (e) => {
    if (e.target.value != "") {
      list.description = setDescription(e.target.value);
    }
  };

  const params = useParams();

  useEffect(() => {
    categoryService.getDetail(params.id).then(
      objS => {
        if (objS.status){
          setName(objS.data.name);
          setDescription(objS.data.description);
          setUserType(objS.data.userType);
          setList({ name: objS.data.name, description: objS.data.description, userType: objS.data.userType })
        }

      },
      error => { console.log(error) }
    );
  }, []);

  function onEditPost(e) {
    const postData = { name, description , categoryId : params.id, userType };
    e.preventDefault();
    setRequest(true);
    categoryService.update(postData).then(
      objS => { props.history.push("/faqcategory"); },
      error => { console.log(error) }
    );
  }


  return (
    <Wrapper showSearch={false} title="Edit Category">
            <div className="row">
              <div className="col-xl-12">
                <div className="tab-content">
                  <div className="tab-pane active show" id="All">
                    <form onSubmit={onEditPost} enctype="multipart/form-data">
                      <div className="form-group row kimargin m-b-10">
                        <label
                          // for="staticEmail"
                          className="col-sm-2 col-form-label"
                        >
                          Name
                        </label>
                        <div className="col-sm-10">
                          <input
                            type="text"
                            name="name"
                            value={list.name}
                            onChange={onChangeName}
                            className="form-control"
                            placeholder="Name"
                            required
                          />
                        </div>
                      </div>

                      <div className="form-group row kimargin m-b-10">
                        <label
                          className="col-sm-2 col-form-label"
                        >
                          Description
                        </label>
                        <div className="col-sm-10">
                          <input
                            type="text"
                            name="description"
                            value={list.description}
                            onChange={onChangeDescription}
                            className="form-control"
                            placeholder="Description"
                            required
                          />
                        </div>
                      </div>

                      <div className="form-group row kimargin tw-mb-2">
                      <label
                        for="staticEmail"
                        className="col-sm-2 col-form-label"
                      >
                        For User
                      </label>
                      <div className="col-sm-10">
                        <select
                          value={userType}
                          className="form-control"
                          onChange={(e) => setUserType(e.target.value)}
                          required
                        >
                          <option value="">Select</option>
                          <option value="Host">Host</option>
                          <option value="Crewmember">Crewmember</option>
                        </select>
                      </div>
                    </div>

                    

                      {/* <div className="form-group row kimargin">
                        <label
                          for="staticEmail"
                          className="col-sm-2 col-form-label"
                        >
                          Status {list.status}
                        </label>
                        <div className="col-sm-10">
                          <select
                            name="status"
                            className="form-select kiselect"
                            // onChange={(e) => handleStatusChange(e)}
                            aria-label="Default select example"
                            required
                          >
                            <option value="">Status</option>
                            <option value="Active">Active</option>
                            <option value="InActive">InActive</option>
                          </select>
                        </div>
                      </div> */}
                      <div className="form-group row kimargin m-b-10">
                        <label
                          for="inputPassword"
                          className="col-sm-2 col-form-label"
                        >
                          &nbsp;
                        </label>
                        <div className="col-sm-10">
                          <button type="submit" className="btn btn-primary">
                          {requesting ? "Please wait ..." : "Edit Category"}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
        <Footer />
      </Wrapper>
  );
}
