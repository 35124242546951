import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import jwt_decode from "jwt-decode";
import Navbar from "./../../components/Navbar";
import TopBar from "./../../components/topbar";
import { subCategoryService } from "../../_services";
import constant from '../../_config/constant';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Footer from "./../../layout/footer";
import Pagination from "react-js-pagination";
import Tippy from "@tippy.js/react";
import { BiDotsHorizontalRounded } from "react-icons/bi";




export default function categoryList(props) {
  const [list, setList] = useState([]);
  const [search, setSearch] = useState('');
  const [refreshKey, setRefreshKey] = useState(0);
  var data = localStorage.getItem(constant.DATA_KEY);
  const decoded = data ? JSON.parse(data) : {};
  var limit = 10;
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);

  const handleDelete = (e) => {
    var id = e.target.id;
    confirmAlert({
      title: 'Confirm to delete',
      message: 'Are you sure to delete.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            subCategoryService.updateStatus({ subCategoryId: id, status: "Inactive" }).then(
              objS => { setRefreshKey(oldKey => oldKey + 1) },
              error => { console.log(error) }
            );
          }
        },
        {
          label: 'No',
          onClick: () => console.log('Click No')
        }
      ]
    });
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
    setRefreshKey(oldKey => oldKey + 1)
  }

  const pageChange = (page) => {
    setPage(page);
    setRefreshKey((oldKey) => oldKey + 1);
  };

  useEffect(() => {
    subCategoryService.subcategoryListWithPagination({ search, page }).then(
      objS => {
        setList(objS.data.docs);
        setTotal(objS.data.total);
      },
      error => { console.log(error) }
    );

  }, [refreshKey]);
  return (
    <div>
      <TopBar user={decoded} showSearch={true} handleSearch={handleSearch}
        search={search} title="Sub Category List" />

      <div className="deznav">
        <div className="deznav-scroll">
          <Navbar />
        </div>
      </div>

      <div className="content-body">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-12">
              <div className="tab-content">
                <div className="tab-pane active show" id="All">
                  <div className="table-responsive">
                    <table
                      className="table card-table  display mb-4 dataTablesCard booking-table room-list-tbl table-responsive-lg "
                      id="guestTable-all"
                    >
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Category</th>
                          <th>Description</th>
                          <th>Status</th>
                          <th>Action</th>
                          <th className="bg-none"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {list.map((data) => (
                          <tr key={data.id}>
                            <td>
                              <span className="fs-16">{data.name}</span>
                            </td>
                            <td>
                              <span className="fs-16">{data.description}</span>
                            </td>
                            <td>
                              <span className="fs-16">{data.categoryId.name}</span>
                            </td>
                            <td>
                              <span className="fs-16">{data.status}</span>
                            </td>
                            <td>
                              <div>
                                <Tippy
                                  interactive
                                  theme="light"
                                  className="tw-bg-white "
                                  arrow={false}
                                  trigger="mouseenter"
                                  content={
                                    <div className="">
                                      <ul class=" tw-bg-white tw-shadow-md tw-p-2 tw-m-4">
                                        <li class="tw-text-sm tw-text-gray-700">
                                          <Link
                                            className="tw-block tw-p-2"
                                            to={"/faqcategory/edit/" + data.id}
                                          >
                                            Edit
                                          </Link>
                                        </li>
                                        <li class="tw-text-sm tw-text-gray-700">
                                          <a
                                            className="tw-block tw-p-2"
                                            id={data.id}
                                            key={data.id}
                                            onClick={handleDelete}
                                          >
                                            Delete
                                          </a>
                                        </li>
                                      </ul>
                                    </div>
                                  }
                                >
                                  <div>
                                    <BiDotsHorizontalRounded
                                      className="tw-text-gray-500 tw-cursor-pointer"
                                      size={24}
                                    />
                                  </div>
                                </Tippy>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Pagination
            activePage={page}
            itemsCountPerPage={limit}
            totalItemsCount={total}
            pageRangeDisplayed={10}
            onChange={pageChange}
          />
        </div>
      </div>

      <Footer />
    </div>
  );
}
