import { BiUser } from 'react-icons/bi'
import { FiLogOut } from 'react-icons/fi'
import Tippy from '@tippy.js/react'
import { useHistory } from 'react-router-dom'
import logo from '../images/logo.png'
import { Link, Redirect } from 'react-router-dom'
import React, { useEffect, useState, Component } from 'react'
import { userService } from '../_services'
import constant from '../_config/constant'
import DownloadButton from './Download-btn.svg'
const usaStates = [
    'All',
    'Alabama',
    'Alaska',
    'Arizona',
    'Arkansas',
    'California',
    'Colorado',
    'Connecticut',
    'Delaware',
    'Florida',
    'Georgia',
    'Hawaii',
    'Idaho',
    'Illinois',
    'Indiana',
    'Iowa',
    'Kansas',
    'Kentucky',
    'Louisiana',
    'Maine',
    'Maryland',
    'Massachusetts',
    'Michigan',
    'Minnesota',
    'Mississippi',
    'Missouri',
    'Montana',
    'Nebraska',
    'Nevada',
    'New Hampshire',
    'New Jersey',
    'New Mexico',
    'New York',
    'North Carolina',
    'North Dakota',
    'Ohio',
    'Oklahoma',
    'Oregon',
    'Pennsylvania',
    'Rhode Island',
    'South Carolina',
    'South Dakota',
    'Tennessee',
    'Texas',
    'Utah',
    'Vermont',
    'Virginia',
    'Washington',
    'West Virginia',
    'Wisconsin',
    'Wyoming',
]

function TopBar(props) {
    const history = useHistory()

    var data = localStorage.getItem(constant.DATA_KEY)
    const decoded = data ? JSON.parse(data) : {}

    const logout = () => {
        userService.logout()
        window.location.href = '/login'
    }

    return (
        <React.Fragment>
            <div className="hidden-navigation">
                <div className="nav-header">
                    <a className="brand-logo">
                        <img className="logo-abbr tw-h-10" src="/logo-icon.png" alt="" />
                        <img className="brand-title tw-mr-2" src="/logo-title.png" alt="" />
                    </a>
                    <div className="nav-control">
                        <div className="hamburger">
                            <span className="line"></span>
                            <span className="line"></span>
                            <span className="line"></span>
                        </div>
                    </div>
                </div>

                <div className="chatbox">
                    <div className="chatbox-close"></div>
                    <div className="custom-tab-1">
                        <div className="tab-content"></div>
                    </div>
                </div>

                <div className="header">
                    <div className="header-content">
                        <nav className="navbar navbar-expand">
                            <div className="collapse navbar-collapse justify-content-between">
                                <div className="header-left">
                                    <div className="dashboard_bar">{props.title}</div>
                                </div>
                                <ul className="navbar-nav header-right">
                                    <li className="nav-item tw-flex tw-gap-2 tw-items-center">
                                        {props.showDownload ? (
                                            <div
                                                onClick={() => {
                                                    if (props.exportUsersData) {
                                                        props.exportUsersData()
                                                    }
                                                    if (props.exportProperties) {
                                                        props.exportProperties()
                                                    }

                                                    if (props.exportReservations) {
                                                        props.exportReservations()
                                                    }

                                                    if (props.exportContacts) {
                                                        props.exportContacts()
                                                    }
                                                }}
                                                className="tw-flex tw-items-center tw-cursor-pointer tw-gap-2"
                                            >
                                                <img src={DownloadButton} className="tw-w-4 tw-h-4" alt="Download icon" />
                                                <p>Download</p>
                                            </div>
                                        ) : null}
                                        {props.showUserFilter ? (
                                            <div className="input-group">
                                                <select
                                                    name="status"
                                                    value={props.type}
                                                    className="form-control"
                                                    onChange={e => props.handleSelect(e)}
                                                    aria-label="Default select example"
                                                    required
                                                >
                                                    <option value="All">All</option>
                                                    <option value="Host">Host </option>
                                                    <option value="Crewmember">Crewmember</option>
                                                    <option value="Both">Both</option>
                                                </select>
                                            </div>
                                        ) : null}

                                        {
                                            props.handlePayoutVerificationStatus && <div className="input-group">
                                                <select
                                                    name="handlePayoutVerificationStatus"
                                                    className="form-control"
                                                    onChange={e => props.handlePayoutVerificationStatus(e.target.value)}
                                                    aria-label="Default select example"
                                                >
                                                    <option value="all">Payout Verification</option>
                                                    <option value="verified">Verified</option>
                                                    <option value="not_verified">Not Verified</option>
                                                    <option value="in_review">In Review</option>
                                                </select>
                                            </div>
                                        }

                                        {props.handleSearchByStatus && (
                                            <div className="input-group mx-2">
                                                <select
                                                    name="status"
                                                    className="form-control"
                                                    onChange={e => {
                                                        console.log(e.target.value)
                                                        props.handleSearchByStatus(e.target.value)
                                                    }}
                                                    // aria-label="Default select example"
                                                    required
                                                >
                                                    {['Status', 'Listed', 'Unlisted', 'Pending', 'Draft', 'Rejected'].map(item => (
                                                        <option value={item} key={item}>
                                                            {item}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        )}

                                        {props.showFilterPropertyByState ? (
                                            <div className="input-group mx-2">
                                                <select
                                                    name="state"
                                                    className="form-control"
                                                    onChange={e => {
                                                        console.log(e.target.value)
                                                        props.handleSearchByState(e.target.value)
                                                    }}
                                                    aria-label="Default select example"
                                                    required
                                                >
                                                    {usaStates.map(item => (
                                                        <option value={item} key={item}>
                                                            {item}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        ) : null}
                                        {props.showSearch ? (
                                            <div className="input-group search-area">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Search here"
                                                    value={props.search}
                                                    onChange={e => props.handleSearch(e)}
                                                />
                                                <span className="input-group-text">
                                                    <a href="javascript:void(0)">
                                                        <i className="flaticon-381-search-2"></i>
                                                    </a>
                                                </span>
                                            </div>
                                        ) : null}
                                    </li>

                                    <li className="nav-item dropdown header-profile">
                                        <Tippy
                                            interactive
                                            theme="light"
                                            className="tw-bg-white "
                                            arrow={false}
                                            trigger="mouseenter"
                                            content={
                                                <div className="tw-m-2 tw-bg-white tw-shadow-md tw-rounded-lg tw-overflow-hidden">
                                                    <Link
                                                        to="/profile"
                                                        className="nav-link profile-btn dropdown-item ai-icon tw-flex tw-items-center"
                                                    >
                                                        <BiUser className="tw-text-primary tw-mr-2" size={24} />
                                                        <span className=" tw-text-primary tw-font-light tw-text-base tw-p-4 tw-py-2 tw-block">
                                                            Profile{' '}
                                                        </span>
                                                    </Link>

                                                    <a
                                                        style={{ cursor: 'pointer' }}
                                                        className="dropdown-item ai-icon tw-flex tw-items-center"
                                                        onClick={logout}
                                                    >
                                                        <FiLogOut className="tw-text-primary tw-mr-2" size={24} />
                                                        <span className=" tw-text-primary tw-font-light tw-text-base tw-p-4 tw-py-2 tw-block">
                                                            Logout{' '}
                                                        </span>
                                                    </a>
                                                </div>
                                            }
                                        >
                                            <a className="nav-link">
                                                <img src="/images/user.png" alt="" />
                                            </a>
                                        </Tippy>
                                    </li>
                                </ul>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
            <div className="tw-flex tw-items-center mobile-nav tw-justify-between">
                <div className="tw-flex tw-items-center tw-gap-4">
                    <a>
                        <img className="logo-abbr md:tw-h-10 mobile-nav-logo" src="/logo-icon.png" alt="" />
                    </a>
                    <div className="nav-control mobile-nav-control">
                        <div className="tw-flex tw-flex-col  hamburger mobile-menu">
                            <span className="line mobile-line"></span>
                            <span className="line mobile-line"></span>
                            <span className="line mobile-line"></span>
                        </div>
                    </div>
                </div>
                <div>
                    <nav className="navbar navbar-expand">
                        <ul className="">
                            <li className="nav-item dropdown header-profile">
                                <Tippy
                                    interactive
                                    theme="light"
                                    className="tw-bg-white "
                                    arrow={false}
                                    trigger="mouseenter"
                                    content={
                                        <div className="tw-m-2 tw-bg-white tw-shadow-md tw-rounded-lg">
                                            <Link to="/profile" className="dropdown-item ai-icon tw-flex  tw-items-center">
                                                <BiUser className="tw-text-primary tw-mr-2" size={24} />
                                                <span className=" tw-text-primary tw-font-light tw-text-base tw-p-4 tw-py-2 tw-block">Profile </span>
                                            </Link>

                                            <a style={{ cursor: 'pointer' }} className="dropdown-item ai-icon tw-flex tw-items-center">
                                                <FiLogOut className="tw-text-primary tw-mr-2" size={24} />
                                                <span className=" tw-text-primary tw-font-light tw-text-base tw-p-4 tw-py-2 tw-block">Logout </span>
                                            </a>
                                        </div>
                                    }
                                >
                                    <a>
                                        <img src="/images/user.png" alt="" width={38} height={38} />
                                    </a>
                                </Tippy>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </React.Fragment>
    )
}

export default TopBar
