import React, { useEffect, useState, Component } from "react";
import axios from "axios";
import jwt_decode from "jwt-decode";
import Navbar from "./../../components/Navbar";
import TopBar from "./../../components/topbar";
import { bedService } from "../../_services";
import constant from '../../_config/constant';
import Footer from "./../../layout/footer";



export default function add(props) {
  const [fileData, setFileData] = useState("");
  const [requesting, setRequest] = useState(false);
  const getFile = (e) => { setFileData(e.target.files[0]); };
  const [title, setTitle] = useState("");
  const [status, setStatus] = useState("");
  const handleStatusChange = (e) => {
    console.clear();
    setStatus(e.target.value.toString());
  };

  var data = localStorage.getItem(constant.DATA_KEY);
  const decoded  = data ? JSON.parse(data): {};

  function onCreatePost(e) {
    const postData = { name: title, file : fileData, status };
    e.preventDefault();
    setRequest(true);
    bedService.add(postData).then(
      objS => { props.history.push("/bedtype"); },
      error => { console.log(error) }
    );
  }

  return (
    <div>
      <TopBar user={decoded}/>

        <div className="deznav">
          <div className="deznav-scroll">
            <Navbar title="Add Amentiy" />
          </div>
        </div>

        <div className="content-body">
          <div className="container-fluid">
            <div className="row">
              <div className="col-xl-12">
                <div className="tab-content">
                  <div className="tab-pane active show" id="All">
                    <form onSubmit={onCreatePost} enctype="multipart/form-data">
                      <div className="form-group row kimargin">
                        <label
                          for="staticEmail"
                          className="col-sm-2 col-form-label"
                        >
                          Title
                        </label>
                        <div className="col-sm-10">
                          <input
                            type="text"
                            name="title"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            className="form-control"
                            placeholder="Title"
                            required
                          />
                        </div>
                      </div>

                      <div className="form-group row kimargin">
                        <label
                          for="staticEmail"
                          className="col-sm-2 col-form-label"
                        >
                          Image
                        </label>
                        <div className="col-sm-10">
                          <input
                            type="file"
                            name="images"
                            onChange={getFile}
                            required
                          />
                        </div>
                      </div>

                    
                      <div className="form-group row kimargin">
                        <label
                          for="inputPassword"
                          className="col-sm-2 col-form-label"
                        >
                          &nbsp;
                        </label>
                        <div className="col-sm-10">
                          <button type="submit" className="btn btn-primary">
                            {requesting ? "Please wait ..." : "Add"}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
  );
}
