import { AppConfig } from "../Config";
import constant from "../_config/constant";

export function processMediaUrl(inputString) {
  const urlRegex = /^(?:https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;
  if (urlRegex.test(inputString)) {
    return inputString; // It's already a URL, return as is
  } else {
    const resultUrl = `${AppConfig.s3Endpoint}media/${inputString}`;
    return resultUrl;
  }
}

export function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (data.statusCode == 401 || data.statusCode == 403) {
      // remove user from local storage to log user out
      localStorage.removeItem(constant.TOKEN_KEY);
      localStorage.removeItem(constant.DATA_KEY);
      window.location.replace("/");
    }
    if (data.statusCode != 200) {
      const error = (data && data.userMessage) || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}
