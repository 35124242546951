import React, { Component } from 'react'
import constant from '../_config/constant'
class Register extends Component {
    constructor() {
        super()
        this.state = {
            first_name: '',
            last_name: '',
            email: '',
            password: '',
            errors: {},
        }

        this.onChange = this.onChange.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value })
    }
    onSubmit(e) {
        e.preventDefault()
    }

    componentDidMount() {
        // Temp fix to hard reaload the app
        const checkTempRef = localStorage.getItem(constant.TEMP_REF)
        if (checkTempRef === 'false' || checkTempRef == null) {
            setTimeout(() => {
                localStorage.setItem(constant.TEMP_REF, 'true')
                window.location.reload()
            }, 1000)
        }
    }

    render() {
        return (
            <div className="App">
                <div className=" h-100">
                    <div className="container h-100">
                        <div className="row justify-content-center h-100 align-items-center">
                            <div className="col-md-6">
                                <div className="authincation-content">
                                    <div className="row no-gutters">
                                        <div className="col-xl-12">
                                            <div className="auth-form">
                                                <div className="text-center mb-3">
                                                    <a href="index.html">
                                                        <img src="images/logo-full.png" alt="" />
                                                    </a>
                                                </div>
                                                <h4 className="text-center mb-4">Sign up your account</h4>
                                                <form noValidate onSubmit={this.onSubmit}>
                                                    <div className="mb-3">
                                                        <label className="mb-1">
                                                            <strong>First name</strong>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="first_name"
                                                            placeholder="Enter your first name"
                                                            value={this.state.first_name}
                                                            onChange={this.onChange}
                                                        />
                                                    </div>
                                                    <div className="mb-3">
                                                        <label className="mb-1">
                                                            <strong>Last name</strong>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="last_name"
                                                            placeholder="Enter your last name"
                                                            value={this.state.last_name}
                                                            onChange={this.onChange}
                                                        />
                                                    </div>
                                                    <div className="mb-3">
                                                        <label className="mb-1">
                                                            <strong>Email</strong>
                                                        </label>
                                                        <input
                                                            type="email"
                                                            className="form-control"
                                                            name="email"
                                                            placeholder="Enter email"
                                                            value={this.state.email}
                                                            onChange={this.onChange}
                                                        />
                                                    </div>
                                                    <div className="mb-3">
                                                        <label className="mb-1">
                                                            <strong>Password</strong>
                                                        </label>
                                                        <input
                                                            type="password"
                                                            className="form-control"
                                                            name="password"
                                                            placeholder="Password"
                                                            value={this.state.password}
                                                            onChange={this.onChange}
                                                        />
                                                    </div>
                                                    <div className="text-center mt-4">
                                                        <button type="submit" className="btn btn-primary btn-block">
                                                            Sign me up
                                                        </button>
                                                    </div>
                                                </form>
                                                <div className="new-account mt-3">
                                                    <p>
                                                        Already have an account?{' '}
                                                        <a className="text-primary" href="/">
                                                            Sign in
                                                        </a>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Register
