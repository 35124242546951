import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";

import Navbar from "./../../components/Navbar";
import TopBar from "./../../components/topbar";
import { BsPlusLg, BsTrash, BsPencilSquare, BsCheck2All } from "react-icons/bs";
import {
  Map,
  GoogleApiWrapper,
  Marker,
  InfoWindow,
  Circle,
} from "google-maps-react";

import PlacesAutocomplete from "react-places-autocomplete";
import {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from "react-places-autocomplete";
import { getAddressByLatLng } from "./../../_helpers/GeoCoder";

import {
  AiOutlineInfoCircle,
  AiFillDelete,
  AiOutlinePlus,
  AiOutlineMinus,
} from "react-icons/ai";
import { FaCheckCircle } from "react-icons/fa";
import { BsPlusCircleFill } from "react-icons/bs";
import { nanoid } from "nanoid";
import $ from "jquery";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Footer from "./../../layout/footer";
import {
  amenityService,
  safetyFeatureService,
  propertyService,
  userService,
  bankService,
} from "../../_services";
import constant from "../../_config/constant";
import { processMediaUrl } from "../../_helpers/utils";
var Modal = require("react-bootstrap-modal");

const Wrapper = ({ children }) => {
  return (
    <div id="main-wrapper">
      <TopBar title="Edit Property" />

      <div className="deznav">
        {/* <div className="deznav-scroll"> */}
        <Navbar />
        {/* </div> */}
      </div>

      <div className="content-body">
        <div className="container-fluid">{children}</div>
      </div>
    </div>
  );
};
const EditShouldBeAllowedForStatuses = ["Pending", "Rejected"];

const BasicDetailsForm = GoogleApiWrapper({ apiKey: constant.googleAPI_KEY })(
  ({
    onNext,
    onPrev,
    formData,
    setFormData,
    className,
    google,
    save,
    updating,
  }) => {
    const [stepForm, setStepForm] = useState({
      rentalType: formData["rentalType"],
      accomodationCapacity: formData["accomodationCapacity"],
      propertyType: formData["propertyType"],
      bookingType: formData["bookingType"],
      reservedDays: formData["reservedDays"],
      lineholderDays: formData["lineholderDays"],
      covidVerified: formData["covidVerified"],
      address: formData["address"],
      apartmentNumber: formData["apartmentNumber"],
      location: formData["location"],
    });

    const [coords, setCoords] = useState(formData["location"]);
    const [mapCenter, setMapCenter] = useState(formData["location"]);
    const [latLng, setLatLng] = useState(formData["location"]);

    const handleStep = (tag, value) => {
      setStepForm({ ...stepForm, [tag]: value });
    };

    const days = [
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "10",
      "11",
      "12",
      "13",
      "14",
      "15",
      "16",
      "17",
      "18",
      "19",
      "20",
      "21",
      "22",
      "23",
      "24",
      "25",
      "26",
      "27",
      "28",
      "29",
      "30",
    ];

    const handleNextStep = (e) => {
      e.preventDefault();
      // setFormData((el) => {
      //   return { ...stepForm };
      // });
      if (!stepForm.address) {
        toast.info("Address is required.");
        return;
      }
      if (!stepForm.location) {
        toast.info("Unable to found location of selected address.");
        return;
      }
      if (stepForm.accomodationCapacity < 1) {
        toast.info("Accommodates must be greater than 0.");
        return;
      }
      save(stepForm);
      onNext();
    };

    const handleSelect = (address) => {
      geocodeByAddress(address)
        .then((results) => getLatLng(results[0]))
        .then((latLng) => {
          setLatLng(latLng);
          setCoords({ ...latLng });
          setMapCenter(new google.maps.LatLng({ ...latLng }));
          setStepForm({ ...stepForm, location: latLng, address: address });
        })
        .catch((error) => console.error("Error", error));
    };

    const selectLocation = async (location) => {
      setLatLng({ lat: location.lat, lng: location.lng });
      setStepForm({ ...stepForm, location: location });
      var newAddress = await getAddressByLatLng(location);
      setStepForm({ ...stepForm, address: newAddress });
    };

    return (
      <form onSubmit={handleNextStep} className={className}>
        <div className={`tw-min-h-[60vh]`}>
          <div className={`row`}>
            <h4 className="mb-5 tw-flex tw-justify-start tw-items-center">
              <span className="tw-bg-[#23426e] tw-mr-2 tw-flex tw-justify-center tw-items-center  tw-h-10 tw-w-10 tw-text-white  tw-rounded-full">
                1
              </span>{" "}
              Basics
            </h4>
            <div className="col-lg-6 mb-2 ">
              <div className="mb-3">
                <label className="form-label">Rental Type</label>
                <select
                  value={stepForm["rentalType"]}
                  onChange={(e) => {
                    handleStep("rentalType", e.target.value);
                  }}
                  className="form-control  wide"
                  name="rentalType"
                  required
                  // disabled
                  disabled={
                    !EditShouldBeAllowedForStatuses.includes(formData.status)
                  }
                >
                  <option value="">Select</option>
                  <option value="Crashpad">Crashpad</option>
                  <option value="Entire Place">Entire Place</option>
                </select>
                {stepForm["rentalType"] === "Crashpad" && (
                  <p className="tw-text-sm tw-text-gray-400 tw-my-2">
                    ⓘ Guest reserves a bed in a private or shared room with
                    common space
                  </p>
                )}
                {stepForm["rentalType"] === "Entire Place" && (
                  <p className="tw-text-sm tw-text-gray-400 tw-my-2">
                    ⓘ Guest reserves an entire property or their exclusive hotel
                    room
                  </p>
                )}
              </div>
            </div>
            <div className="col-lg-6 mb-2">
              <div className="mb-3">
                <label className="form-label">Accommodates</label>
                <input
                  onChange={(e) =>
                    handleStep(
                      "accomodationCapacity",
                      Math.abs(e.target.value) || ""
                    )
                  }
                  value={stepForm["accomodationCapacity"]}
                  type="number"
                  className="form-control"
                  name="accomodationCapacity"
                  placeholder="Capacity"
                  required
                />
                {stepForm["rentalType"] === "Crashpad" && (
                  <p className="tw-text-sm tw-text-gray-400 tw-my-2">
                    ⓘ Total number of guests at the crashpad when full including
                    yourself and anyone else that sleeps there
                  </p>
                )}
                {stepForm["rentalType"] === "Entire Place" && (
                  <p className="tw-text-sm tw-text-gray-400 tw-my-2">
                    ⓘ Total number of guests allowed at the property
                  </p>
                )}
              </div>
            </div>
            <div className="col-lg-6 mb-2">
              <div className="mb-3">
                <label className="form-label">Property Type</label>
                <select
                  name="propertyType"
                  onChange={(e) => {
                    handleStep("propertyType", e.target.value);
                  }}
                  value={stepForm["propertyType"]}
                  className=" form-control"
                  required
                  // disabled
                  disabled={
                    !EditShouldBeAllowedForStatuses.includes(formData.status)
                  }
                >
                  <option value="">Select</option>
                  <option value="house">House</option>
                  <option value="hotel">Hotel</option>
                  <option value="apartment">Apartment</option>
                </select>
              </div>
            </div>
            <div className="col-lg-6 mb-2">
              <div className="mb-3">
                <label className="form-label">Booking Type</label>
                <select
                  name="bookingType"
                  onChange={(e) => handleStep("bookingType", e.target.value)}
                  value={stepForm["bookingType"]}
                  className="  form-control wide"
                  required
                  // disabled
                  disabled={
                    !EditShouldBeAllowedForStatuses.includes(formData.status)
                  }
                >
                  <option value="">Select</option>
                  <option value="Hot bed">Hot Bed</option>
                  <option value="Cold bed">Cold Bed</option>
                  <option value="Flexible">Flexible</option>
                </select>
                {stepForm["bookingType"] === "Hot bed" && (
                  <p className="tw-text-sm tw-text-gray-400 tw-my-2">
                    ⓘ Listing offers nightly rates
                  </p>
                )}
                {stepForm["bookingType"] === "Cold bed" && (
                  <p className="tw-text-sm tw-text-gray-400 tw-my-2">
                    ⓘ Listing offers monthly rates
                  </p>
                )}
                {stepForm["bookingType"] === "Flexible" && (
                  <p className="tw-text-sm tw-text-gray-400 tw-my-2">
                    ⓘ Listing offers nightly and monthly rates
                  </p>
                )}
              </div>
            </div>
            {/* {(stepForm.bookingType === "Cold bed" ||
            stepForm.bookingType === "Flexible") && (
              <div className="col-lg-6 mb-2">
                <div className="mb-3">
                  <label className="form-label">Stay Limit</label>
                  <select
                    name="stayLimit"
                    onChange={(e) => handleStep("stayLimit", e.target.value)}
                    value={stepForm["stayLimit"]}
                    className="  form-control wide"
                    required
                  >
                    <option value="">Select</option>
                    <option value="Reserved">
                      {" "}
                      Reserve Stay Limit
                    </option>
                    <option value="Lineholder">
                      Lineholder Stay Limit
                    </option>
                  </select>
                </div>
              </div>
            )} */}
            {(stepForm.bookingType === "Cold bed" ||
              stepForm.bookingType === "Flexible") && (
              <div className="col-lg-6 mb-2">
                <div className="mb-3">
                  <label className="form-label">Reserve Stay Limit</label>
                  <select
                    name="reservedDays"
                    onChange={(e) => handleStep("reservedDays", e.target.value)}
                    value={stepForm["reservedDays"]}
                    className="  form-control wide"
                    required
                  >
                    <option value="">Select</option>
                    {days.map((day) => (
                      <option value={day}>{day}</option>
                    ))}
                  </select>
                  <p className="tw-text-sm tw-text-gray-400 tw-my-2">
                    ⓘ Total number of nights a guest can stay in a month
                  </p>
                </div>
              </div>
            )}
            {(stepForm.bookingType === "Cold bed" ||
              stepForm.bookingType === "Flexible") && (
              <div className="col-lg-6 mb-2">
                <div className="mb-3">
                  <label className="form-label">Lineholder Stay Limit</label>
                  <select
                    name="lineholderDays"
                    onChange={(e) =>
                      handleStep("lineholderDays", e.target.value)
                    }
                    value={stepForm["lineholderDays"]}
                    className="  form-control wide"
                    required
                  >
                    <option value="">Select</option>
                    {days.map((day) => (
                      <option value={day}>{day}</option>
                    ))}
                  </select>
                  <p className="tw-text-sm tw-text-gray-400 tw-my-2">
                    ⓘ Total number of nights a guest can stay in a month
                  </p>
                </div>
              </div>
            )}
            <div className="col-lg-6 mb-2">
              <div className="mb-3">
                <label className="form-label">
                  Is Covid vaccination required to book at property?
                </label>
                <select
                  name="covidVerified"
                  onChange={(e) => handleStep("covidVerified", e.target.value)}
                  value={stepForm["covidVerified"]}
                  className="  form-control wide"
                  required
                >
                  <option value="">Select</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select>
              </div>
            </div>
            <div className="col-lg-6 mb-3">
              <div className="mb-3">
                <label className="text-label form-label">Address</label>

                <PlacesAutocomplete
                  searchOptions={{ componentRestrictions: { country: "us" } }}
                  value={stepForm["address"]}
                  onChange={(e) => handleStep("address", e)}
                  // onSelect={handleSelect}
                  className="form-control"
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                  }) => (
                    <div className="tw-relative">
                      <input
                        {...getInputProps({
                          placeholder: "Search Places ...",
                          className: "form-control",
                        })}
                      />
                      <div className="autocomplete-dropdown-container tw-absolute tw-left-0 tw-right-0 tw-top-14 tw-z-[4000]">
                        {loading && <div className="tw-p-4 ">Loading...</div>}
                        {suggestions.map((suggestion) => {
                          const className = suggestion.active
                            ? "suggestion-item--active"
                            : "suggestion-item";
                          // inline style for demonstration purpose
                          const style = suggestion.active
                            ? { backgroundColor: "#fafafa", cursor: "pointer" }
                            : { backgroundColor: "#ffffff", cursor: "pointer" };
                          return (
                            <div
                              {...getSuggestionItemProps(suggestion, {
                                className,
                                style,
                              })}
                            >
                              <span
                                onClick={() =>
                                  handleSelect(suggestion.description)
                                }
                                className="tw-p-4 tw-block"
                              >
                                {suggestion.description}
                              </span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </PlacesAutocomplete>
              </div>
            </div>
            <div className="col-lg-6 mb-2">
              <div className="mb-3">
                <label className="form-label">
                  Apt, suite, etc. (Optional)
                </label>
                <input
                  onChange={(e) =>
                    handleStep("apartmentNumber", e.target.value)
                  }
                  value={stepForm["apartmentNumber"]}
                  type="text"
                  className="form-control"
                  name="apartmentNumber"
                  placeholder="e.g. Apt #8"
                />
              </div>
            </div>
            <div className="col-lg-6 mb-4 tw-h-64  tw-w-full ">
              <div className="tw-h-64 tw-h-64 tw-relative tw-rounded-md tw-overflow-hidden ">
                <Map
                  google={google}
                  disableDefaultUI
                  zoom={14}
                  style={{ height: "100%", width: "100%" }}
                  // onClick={handleMapClick}
                  center={mapCenter}
                  initialCenter={latLng}
                >
                  <Marker title={stepForm.address} position={coords}>
                    <InfoWindow visible={true}>
                      <div className="p-4 bg-white text-sm">
                        <p>
                          Click on the map or drag the marker to select location
                          where the incident occurred
                        </p>
                      </div>
                    </InfoWindow>
                  </Marker>
                  <Circle
                    radius={100}
                    center={coords}
                    strokeColor="transparent"
                    strokeOpacity={0}
                    strokeWeight={5}
                    fillColor="#1362fc"
                    fillOpacity={0.5}
                  />
                </Map>
              </div>
            </div>
          </div>
        </div>
        <div className="tw-mb-4">
          <button onClick={onPrev} className="btn btn-secondary mx-2 " disabled>
            Prev
          </button>

          <button
            disabled={updating}
            type="submit"
            className="btn btn-secondary"
          >
            {updating ? "Updating ..." : "Save & Next"}
          </button>
        </div>
      </form>
    );
  }
);

const Details = ({
  onNext,
  onPrev,
  className,
  formData,
  setFormData,
  save,
  updating,
}) => {
  const [stepForm, setStepForm] = useState({
    listingName: formData.listingName,
    aboutListing: formData.aboutListing,
    transportation: formData.transportation,
    parking: formData.parking,
  });

  const getFile = (e) => {
    setStepForm({ ...stepForm, file: e.target.files[0] });
  };

  const handleStep = (tag, value) => {
    setStepForm({ ...stepForm, [tag]: value });
  };

  const handleNextStep = (e) => {
    e.preventDefault();
    // setFormData((el) => {
    //   return { ...el, ...stepForm };
    // });
    save(stepForm);
    onNext();
  };

  return (
    <form onSubmit={handleNextStep} className={className}>
      <div className={`tw-min-h-[60vh]`}>
        <div className={`row`}>
          <h4 className="mb-5 tw-flex tw-justify-start tw-items-center">
            <span className="tw-bg-[#23426e] tw-mr-2 tw-flex tw-justify-center tw-items-center  tw-h-10 tw-w-10 tw-text-white  tw-rounded-full">
              2
            </span>{" "}
            Details
          </h4>
          <div className="col-lg-6 mb-2">
            <div className="mb-3">
              <label className="text-label form-label">Listing Name</label>
              <input
                type="text"
                value={stepForm["listingName"]}
                onChange={(e) => handleStep("listingName", e.target.value)}
                name="listingName"
                className="form-control"
                placeholder="Listing Name"
                required
              />
            </div>
          </div>
          <div className="col-lg-6 mb-2">
            <div className="mb-3">
              <label className="text-label form-label">Summary</label>

              <textarea
                value={stepForm["aboutListing"]}
                onChange={(e) => handleStep("aboutListing", e.target.value)}
                type="aboutListing"
                className="form-control"
                placeholder="Summary"
                required
              ></textarea>
            </div>
          </div>
          <div className="col-lg-6 mb-2">
            <div className="mb-3">
              <label className="text-label form-label">Transportation</label>
              <input
                value={stepForm["transportation"]}
                onChange={(e) => handleStep("transportation", e.target.value)}
                type="text"
                name="transportation"
                className="form-control"
                placeholder="Enter Something"
                required
              />
            </div>
          </div>
          <div className="col-lg-6 mb-2">
            <div className="mb-3">
              <label className="text-label form-label">Parking</label>
              <input
                value={stepForm["parking"]}
                onChange={(e) => handleStep("parking", e.target.value)}
                type="text"
                name="parking"
                className="form-control"
                placeholder="Enter Something"
                required
              />
            </div>
          </div>
          {/* <div className="col-lg-6 mb-2">
            <div className="mb-3">
              <label className="text-label form-label">Bathrooms</label>
              <input
                value={stepForm["bathrooms"]}
                onChange={(e) => handleStep("bathrooms", e.target.value)}
                type="text"
                name="bathrooms"
                className="form-control"
                placeholder="Enter Something"
                required
              />
            </div>
          </div> */}
          {/* <div className="col-lg-12 mb-2">
            <div className="mb-3">
              <label className="text-label form-label">Add Photos</label>
              <input
                type="file"
                name="transportation"
                className="form-control"
                placeholder="Enter Something"
                onChange={getFile}
                required
              />
            </div>
          </div> */}
        </div>
      </div>
      <div>
        <button
          onClick={onPrev}
          type="button"
          className="btn btn-secondary mx-2 "
        >
          Prev
        </button>

        <button type="submit" disabled={updating} className="btn btn-secondary">
          {updating ? "Updating ..." : "Save & Next"}
        </button>
      </div>
    </form>
  );
};

function HouseRules({
  onNext,
  onPrev,
  formData,
  setFormData,
  className,
  save,
  updating,
}) {
  const [houseRules, setHouseRules] = useState(formData["houseRules"] || []);
  const [rule, setRule] = useState("");

  const handleRules = (curr) => {
    if (curr) {
      setHouseRules([...houseRules, curr]);
      setRule("");
    }
  };

  const deleteRule = (id) => {
    const ruleArr = [...houseRules];
    ruleArr.splice(id, 1);
    setHouseRules([...ruleArr]);
  };

  const handleNextStep = () => {
    // setFormData((el) => {
    //   return { ...el, houseRules: houseRules };
    // });
    if (houseRules.length) {
      save({ houseRules });
      onNext();
    }
  };

  return (
    <div className={className}>
      <div className=" tw-min-h-[60vh] ">
        <div className="row gx-5">
          <h4 className="mb-5 h-min tw-flex tw-justify-start tw-items-center">
            <span className="tw-bg-[#23426e] tw-mr-2 tw-flex tw-justify-center tw-items-center  tw-h-10 tw-w-10 tw-text-white  tw-rounded-full">
              3
            </span>{" "}
            House Rules
          </h4>
          <div className="col-lg-6">
            {Boolean(houseRules.length) ? (
              <div className="mb-2">
                {houseRules.map((curr, index) => {
                  return (
                    <div
                      key={index}
                      className="tw-flex tw-items-start tw-justify-between"
                    >
                      <p className="tw-text-gray-700 tw-text-lg">
                        {index + 1}. {curr}
                      </p>
                      <AiFillDelete
                        size={24}
                        onClick={() => deleteRule(index)}
                        className="tw-cursor-pointer"
                      />
                    </div>
                  );
                })}
              </div>
            ) : (
              <p className="tw-mb-4 tw-text-center tw-text-gray-600 tw-text-xl ">
                No rules added
              </p>
            )}
            <div className="mb-2 tw-flex tw-items-center">
              <input
                onChange={(e) => {
                  setRule(e.target.value);
                }}
                value={rule}
                type="text"
                name="houseRules"
                className="form-control"
                placeholder="House Rules"
                required
              />

              <button
                onClick={() => handleRules(rule)}
                type="button"
                className="btn btn-sm btn-primary m-2 "
              >
                Add
              </button>
            </div>
          </div>
          <div className="col-lg-6 ">
            <h4>House Rules (Examples)</h4>
            <p className="tw-text-gray-800 tw-text-base tw-mb-2">
              1. Be courteous and clean up after yourself
            </p>
            <p className="tw-text-gray-800 tw-text-base tw-mb-2">
              2. Quiet hours are from 10pm-8am
            </p>
            <p className="tw-text-gray-800 tw-text-base tw-mb-2">
              3. Provide your own sheets, towels, and toiletries
            </p>
            <p className="tw-text-gray-800 tw-text-base tw-mb-2">
              4. Overnight guests are not allowed{" "}
            </p>
          </div>
        </div>
      </div>
      <div>
        <button
          onClick={onPrev}
          type="button"
          className="btn btn-secondary mx-2 "
        >
          Prev
        </button>
        <button
          onClick={handleNextStep}
          type="submit"
          className="btn btn-secondary mx-2"
          disabled={updating}
        >
          {updating ? "Updating ..." : "Save & Next"}
        </button>
      </div>
    </div>
  );
}

function AddPhotos({
  onNext,
  onPrev,
  formData,
  setFormData,
  className,
  savePhoto,
  updating,
}) {
  const [photos, setPhotos] = useState(
    formData["images"].map((e, i) => {
      return {
        type: "url",
        order: i + 1,
        url: e,
        id: nanoid(),
      };
    }) || []
  );
  const [dragId, setDragId] = useState();
  const [isDragging, setIsDragging] = useState(false)
  const [draggOverId, setDraggOverId] = useState(false)

  const handleDrag = ev => {
    setIsDragging(true)
    setDragId(ev.currentTarget.id)
}

const handleDrop = ev => {
  const dragBox = photos.find(photo => photo.id === dragId)
  const dropBox = photos.find(photo => photo.id === ev.currentTarget.id)

  const dragBoxOrder = dragBox.order
  const dropBoxOrder = dropBox.order

  const newPhotoState = photos.map(photo => {
      if (photo.id === dragId) {
          photo.order = dropBoxOrder
      }
      if (photo.id === ev.currentTarget.id) {
          photo.order = dragBoxOrder
      }
      return photo
  })
  setIsDragging(false)
  setDragId(null)
  setPhotos(newPhotoState)
  setDraggOverId(false)
}
  const getImage = (file) => {
    let src = new FileReader();
    const url = URL.createObjectURL(file.current);
    return url;
  };

  const handleDelete = (id) => {
    const arr = photos.filter((curr) => curr.id !== id);
    setPhotos([...arr]);
  };

  const handleNextStep = () => {
    if (photos.length > 25) {
        toast.error('You can add up to 25 images while listing the property')
        return
    }

    if (photos.length) {
        savePhoto({ photos })
        onNext()
    }
}

useEffect(() => {
  const images = []
  formData['images'].map((e, i) =>
      images.push({
          type: 'url',
          order: i + 1,
          url: e,
          id: nanoid(),
      })
  )
  setPhotos(images)
}, [formData])


  return (
    <div className={className}>
      <div className=" tw-min-h-[60vh]">
        <h4 className="mb-5 tw-flex tw-justify-start tw-items-center">
          <span className="tw-bg-[#23426e] tw-mr-2 tw-flex tw-justify-center tw-items-center  tw-h-10 tw-w-10 tw-text-white  tw-rounded-full">
            4
          </span>{" "}
          Add Photos
        </h4>
        <div className="row gx-5">
          <div className="tw-flex tw-items-start tw-flex-wrap tw-mb-4">
            {photos
              .sort((a, b) => a.order - b.order)
              .map((curr, i) => {
                return (
                  <div
                  key={`${i}-${curr.id}`}
                  id={curr.id}
                  draggable
                  onDragOver={ev => {
                      ev.preventDefault()
                      setDraggOverId(ev.target.id)
                  }}

                  onDragEnd={()=>{
                    setDraggOverId(false)  
                  }}
                                        
                  onDragStart={handleDrag}
                  onDrop={handleDrop}
                  className={`tw-w-44 tw-flex-shrink-0 tw-grow-0 tw-relative tw-cursor-move tw-h-44 tw-m-2 tw-object-cover tw-rounded-md tw-overflow-hidden ${
                      (draggOverId  === curr.id && isDragging) ? 'tw-bg-gray-400' : 'tw-bg-gray-300'
                  }`}>

                  {i === 0 && (
                    <div
                        className=" tw-text-white tw-p-1"
                        style={{
                            width: '70px',
                            textAlign: 'center',
                            fontSize: '13px',
                            borderRadius: '3px',
                            position: 'absolute',
                            top: 0,
                            backgroundColor:'#23426e'
                        }}
                    >
                        Featured
                    </div>
                  )}
                    <span
                      onClick={() => handleDelete(curr.id)}
                      className="tw-block tw-absolute tw-right-4 tw-top-4 tw-h-8 tw-w-8 tw-flex tw-items-center tw-justify-center tw-cursor-pointer tw-rounded-full tw-bg-gray-500 tw-bg-opacity-50"
                    >
                      <AiOutlinePlus
                        size={22}
                        className="tw-transform tw-rotate-45 tw-text-white"
                      />
                    </span>
                    <img
                      id={curr.id}
                      className="tw-w-full tw-h-full tw-object-cover"
                      src={curr.type==='url'?   processMediaUrl(curr.url) : getImage(curr)}
                      style={{
                          opacity: isDragging && draggOverId === curr.id ? '0' : '1',
                      }}
                      />
                  </div>
                );
              })}
          </div>
          {Boolean(photos.length) && (
            <p className="tw-text-sm tw-text-gray-400 tw-my-2">
              ⓘ Drag images to rearrange
            </p>
          )}

          <div className="col-lg-6 mb-2">
            <div className="mb-3">
              <label className="text-label form-label">Add photos</label>
              <div className="tw-flex tw-items-center">
                <label
                  htmlFor="add_photos"
                  className="btn btn-secondary btn-sm"
                >
                  choose
                </label>
                <p className="tw-text-base tw-ml-4">
                  {!photos.length
                    ? "No file choosen"
                    : `${photos.length} File choosen`}
                </p>
              </div>
              <input
                id="add_photos"
                type="file"
                multiple
                className="form-control tw-hidden"
                required
                accept="image/*"
                onChange={(e) => {
                  const curr = Array.from(e.target.files).map((file, i) => {
                    return {
                      current: file,
                      id: nanoid(),
                      order: photos.length + i + 1,
                    };
                  });
                  setPhotos([...photos, ...curr]);
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div>
        <button
          onClick={onPrev}
          type="button"
          className="btn btn-secondary mx-2 "
        >
          Prev
        </button>
        <button
          onClick={handleNextStep}
          type="button"
          className="btn btn-secondary mx-2"
        >
          {updating ? "Updating ..." : "Save & Next"}
        </button>
      </div>
    </div>
  );
}
const Amenities = ({
  onNext,
  onPrev,
  formData,
  setFormData,
  className,
  amenityList,
  save,
  updating,
}) => {
  const [amenities, setAminities] = useState(formData["amenities"] || []);
  const handleStep = (val) => {
    const ind = amenities.indexOf(val);
    const updatedAminities = [...amenities];
    if (ind > -1) {
      updatedAminities.splice(ind, 1);
    } else {
      updatedAminities.push(val);
    }
    setAminities(updatedAminities);
  };

  const handleNextStep = () => {
    // setFormData((el) => {
    //   return { ...el, amenities: [...amenities] };
    // });
    if (amenities.length) {
      save({ amenities });
      onNext();
    }
  };
  return (
    <div className={className}>
      <div className="tw-min-h-[60vh]">
        <h4 className="mb-2 tw-flex tw-justify-start tw-items-center">
          <span className="tw-bg-[#23426e] tw-mr-2 tw-flex tw-justify-center tw-items-center  tw-h-10 tw-w-10 tw-text-white  tw-rounded-full">
            5
          </span>
          Amenities
        </h4>
        <div className="my-3 row ">
          {amenityList.map((data) => (
            <div
              key={data.id}
              className="form-check form-check-inline col-lg-3"
            >
              <label className="form-check-label">
                <input
                  onChange={(e) => handleStep(e.target.value)}
                  checked={amenities.includes(data.id)}
                  type="checkbox"
                  className="form-check-input"
                  value={data.id}
                />
                {data.name}
              </label>
            </div>
          ))}
        </div>
      </div>
      <div>
        <button
          onClick={onPrev}
          type="button"
          className="btn btn-secondary mx-2 "
        >
          Prev
        </button>

        <button
          disabled={updating}
          onClick={handleNextStep}
          className="btn btn-secondary"
        >
          {updating ? "Updating ..." : "Save & Next"}
        </button>
      </div>
    </div>
  );
};

const SafetyFeatures = ({
  onNext,
  onPrev,
  formData,
  setFormData,
  className,
  safetyFeatureList,
  save,
  updating,
}) => {
  window.scrollTo({ top: 0, behavior: "smooth" });

  const [safety, setSafety] = useState(formData["safety"] || []);
  const handleStep = (val) => {
    const ind = safety.indexOf(val);
    const updatedAminities = [...safety];
    if (ind > -1) {
      updatedAminities.splice(ind, 1);
    } else {
      updatedAminities.push(val);
    }
    setSafety(updatedAminities);
  };

  const handleNextStep = () => {
    // setFormData((el) => {
    //   return { ...el, safety: [...safety] };
    // });
    if (safety.length) {
      save({ safety });
      onNext();
    }
  };
  return (
    <div className={className}>
      <div className=" tw-min-h-[60vh]">
        <h4 className="mb-2 tw-flex tw-justify-start tw-items-center">
          <span className="tw-bg-[#23426e] tw-mr-2 tw-flex tw-justify-center tw-items-center  tw-h-10 tw-w-10 tw-text-white  tw-rounded-full">
            6
          </span>{" "}
          Safety Features
        </h4>
        <div className=" row my-3">
          {safetyFeatureList.map((data) => (
            <div
              key={data.id}
              className="form-check form-check-inline col-lg-3"
            >
              <label className="form-check-label">
                <input
                  type="checkbox"
                  onChange={(e) => handleStep(e.target.value)}
                  checked={safety.includes(data.id)}
                  className="form-check-input"
                  value={data.id}
                />
                {data.name}
              </label>
            </div>
          ))}
        </div>
      </div>
      <div>
        <button
          onClick={onPrev}
          type="button"
          className="btn btn-secondary mx-2 "
        >
          Prev
        </button>

        <button
          disabled={updating}
          onClick={handleNextStep}
          className="btn btn-secondary"
        >
          {updating ? "Updating ..." : "Save & Next"}
        </button>
      </div>
    </div>
  );
};

const SleepingArrangements = ({
  onNext,
  onPrev,
  formData,
  setFormData,
  className,
  save,
  updating,
}) => {
  const [roomList, setRoomList] = useState(
    formData["sleepingArrangements"] || []
  );
  const [stepForm, setStepForm] = useState({});
  const [beds, setBeds] = useState([]);

  const [bedNumber, setBedNumber] = useState("");
  const [perNightPrice, setNightPrice] = useState("");
  const [perMonthPrice, setMonthPrice] = useState("");
  const [bedType, setBedType] = useState("");

  const [editBedNumber, setEditBedNumber] = useState("");
  const [editPerNightPrice, setEditNightPrice] = useState("");
  const [editPerMonthPrice, setEditMonthPrice] = useState("");
  const [editBedType, setEditBedType] = useState("");

  const [isEdit, setEdit] = useState(false);
  const [isEditBed, setEditBed] = useState(false);

  const [index, setIndex] = useState(0);
  const [editBedIndex, setEditBedIndex] = useState("");

  const [numberOfBathroom, setBathroom] = useState(0);

  const handleBed = () => {
    if (!stepForm.numberOfBeds) return;
    if (beds.length == stepForm.numberOfBeds) return;

    var setObj = { bedNumber, bedType };
    if (formData.rentalType === "Crashpad") {
      if (!bedNumber) return;
      if (beds.length) {
        if (beds.some((bed) => bed.bedNumber === bedNumber)) {
          toast.info("Bed number must be unique.");
          return;
        }
      }
    }
    if (perNightPrice) setObj["perNightPrice"] = perNightPrice;
    if (perMonthPrice) setObj["perMonthPrice"] = perMonthPrice;

    if (
      formData.rentalType === "Crashpad" &&
      (formData.bookingType === "Hot bed" ||
        formData.bookingType === "Flexible") &&
      !perNightPrice
    ) {
      return;
    }

    if (
      formData.rentalType === "Crashpad" &&
      (formData.bookingType === "Cold bed" ||
        formData.bookingType === "Flexible") &&
      !perMonthPrice
    ) {
      return;
    }

    if (!bedType) {
      return;
    }

    setBeds([...beds, setObj]);
    setBedNumber("");
    setBedType("");
    setNightPrice("");
    setMonthPrice("");
  };

  const removeBed = (index) => {
    var b = beds.filter((value, idx) => idx != index);
    setBeds(b);
  };

  const editBed = (index, bed) => {
    setEditBed(true);
    setEditBedIndex(index);
    var cloneBed = Object.assign({}, bed);
    cloneBed.bedNumber && setEditBedNumber(cloneBed.bedNumber);
    cloneBed.bedType && setEditBedType(cloneBed.bedType);
    cloneBed.perNightPrice && setEditNightPrice(cloneBed.perNightPrice);
    cloneBed.perMonthPrice && setEditMonthPrice(cloneBed.perMonthPrice);
  };

  const updatedBed = () => {
    if (
      formData.rentalType === "Crashpad" &&
      (formData.bookingType === "Hot bed" ||
        formData.bookingType === "Flexible") &&
      !editPerNightPrice
    ) {
      return;
    }

    if (
      formData.rentalType === "Crashpad" &&
      (formData.bookingType === "Cold bed" ||
        formData.bookingType === "Flexible") &&
      !editPerMonthPrice
    ) {
      return;
    }

    if (!editBedType) {
      return;
    }

    if (formData.rentalType === "Crashpad" && !editBedNumber) {
      return;
    }
    var updatedBeds = [];
    var remainingBeds = beds.filter((value, idx) => idx != editBedIndex);
    if (remainingBeds.length && formData.rentalType === "Crashpad") {
      if (remainingBeds.some((bed) => bed.bedNumber === editBedNumber)) {
        toast.info("Bed number must be unique.");
        return;
      }
    }

    beds.forEach((bed, idx) => {
      if (idx == editBedIndex) {
        var setObj = { bedNumber: editBedNumber, bedType: editBedType };
        if (editPerNightPrice) setObj["perNightPrice"] = editPerNightPrice;
        if (editPerMonthPrice) setObj["perMonthPrice"] = editPerMonthPrice;
        updatedBeds.push(setObj);
      } else updatedBeds.push(bed);
    });
    setBeds(updatedBeds);
    setEditBedNumber("");
    setEditBedType("");
    setEditNightPrice("");
    setEditMonthPrice("");
    setEditBed(false);
    setEditBedIndex("");
  };

  const handleRoom = (e) => {
    e.preventDefault();
    if (beds.length == 0) return;
    // if (numberOfBathroom == 0) return;
    if (beds.length != stepForm.numberOfBeds) {
      toast.info("Number of beds and added beds must be same.");
      return;
    }

    if (isEdit) {
      var updatedRoomList = [];
      roomList.forEach((room, idx) => {
        if (idx == index)
          updatedRoomList.push({
            ...stepForm,
            numberOfBathroom: numberOfBathroom,
            beds,
          });
        else updatedRoomList.push(room);
      });
      setRoomList(updatedRoomList);
    } else {
      setRoomList([
        ...roomList,
        { ...stepForm, numberOfBathroom: numberOfBathroom, beds },
      ]);
    }
    setBeds([]);
    setBedNumber("");
    setBedType("");
    setNightPrice("");
    setMonthPrice("");
    setStepForm({});
    window.$("#addRoomModal").modal("hide");
    window.$(".modal-backdrop").remove();
  };

  const editSleepingArrangement = (room, index) => {
    setEdit(true);
    setIndex(index);
    var cloneRoom = Object.assign({}, room);
    setBeds(cloneRoom.beds);
    delete cloneRoom.beds;
    setStepForm(cloneRoom);
    setBathroom(cloneRoom.numberOfBathroom);
    window.$("#addRoomModal").modal("show");
  };
  const deleteSleepingArrangement = async (room, index) => {
    try {
      const updatedRoomList = roomList.filter((item) => item._id !== room._id);
      setRoomList(updatedRoomList);
    } catch (error) {
      console.log(error);
    }
  };
  const addSleepingArrangement = () => {
    setEdit(false);
    setBeds([]);
    setStepForm({});
    setBathroom(0);
    window.$("#addRoomModal").modal("show");
  };

  const renderBedlist = () => {
    return beds.map((curr, ind) => {
      if (ind !== editBedIndex) {
        return (
          <tr key={ind}>
            {formData.rentalType === "Crashpad" && <td>{curr.bedNumber}</td>}
            <td>{curr.bedType}</td>
            {formData.rentalType === "Crashpad" &&
              (formData.bookingType === "Hot bed" ||
                formData.bookingType === "Flexible") && (
                <td>{curr.perNightPrice}</td>
              )}
            {formData.rentalType === "Crashpad" &&
              (formData.bookingType === "Cold bed" ||
                formData.bookingType === "Flexible") && (
                <td>{curr.perMonthPrice}</td>
              )}
            <td>
              <div>
                {editBedIndex !== ind && (
                  <div className="tw-grid-cols-2 tw-gap-2">
                    <button
                      onClick={() => editBed(ind, curr)}
                      type="button"
                      className=" tw-h-12 tw-w-12 tw-bg-[#c8c8c8] tw-rounded-lg tw-outline-none tw-text-[#343a40] tw-border-none mx-2"
                    >
                      <BsPencilSquare size={15} />
                    </button>
                    &nbsp; &nbsp; &nbsp;
                    <button
                      onClick={() => removeBed(ind)}
                      type="button"
                      className=" tw-h-12 tw-w-12 tw-bg-[#c8c8c8] tw-rounded-lg tw-outline-none tw-text-[#343a40] tw-border-none"
                    >
                      <BsTrash size={15} />
                    </button>
                  </div>
                )}
              </div>
            </td>
          </tr>
        );
      } else {
        return (
          <tr>
            {formData.rentalType === "Crashpad" && (
              <th>
                <div>
                  <input
                    onChange={(e) => {
                      setEditBedNumber(e.target.value);
                    }}
                    value={editBedNumber}
                    type="text"
                    className="form-control"
                    placeholder="Bed Number"
                    required
                  />
                </div>
              </th>
            )}
            <th>
              <div>
                <select
                  name="bed_type"
                  onChange={(e) => setEditBedType(e.target.value)}
                  value={editBedType}
                  className="form-control wide"
                  required
                >
                  <option value="">Select</option>
                  <option value="King">King</option>
                  <option value="Queen">Queen</option>
                  <option value="Twin">Twin</option>
                  <option value="Couch">Couch</option>
                  <option value="Top bunk">Top bunk</option>
                  <option value="Bottom bunk">Bottom bunk</option>
                  <option value="Other">Other</option>
                </select>
              </div>
            </th>

            {formData.rentalType === "Crashpad" &&
              (formData.bookingType === "Hot bed" ||
                formData.bookingType === "Flexible") && (
                <th>
                  <div>
                    <input
                      onChange={(e) => {
                        if (
                          parseInt(e.target.value) >= 1 ||
                          e.target.value == ""
                        ) {
                          setEditNightPrice(
                            e.target.value
                              ? Math.floor(parseInt(e.target.value))
                              : ""
                          );
                        }
                      }}
                      value={editPerNightPrice}
                      placeholder="Per night price"
                      maxLength={9}
                      min={1}
                      max={999999999}
                      className="form-control"
                      type="text"
                      required
                    />
                  </div>
                </th>
              )}
            {formData.rentalType === "Crashpad" &&
              (formData.bookingType === "Cold bed" ||
                formData.bookingType === "Flexible") && (
                <th>
                  <div>
                    <input
                      onChange={(e) => {
                        if (
                          parseInt(e.target.value) >= 1 ||
                          e.target.value == ""
                        ) {
                          setEditMonthPrice(
                            e.target.value
                              ? Math.floor(parseInt(e.target.value))
                              : ""
                          );
                        }
                      }}
                      value={editPerMonthPrice}
                      type="number"
                      className="form-control"
                      placeholder="Per month price"
                      maxLength={9}
                      min={1}
                      max={999999999}
                      required
                    />
                  </div>
                </th>
              )}

            <th>
              <div>
                <button
                  onClick={updatedBed}
                  type="button"
                  className=" tw-h-12 tw-w-12 tw-bg-[#c8c8c8] tw-rounded-lg tw-outline-none flex-center tw-text-[#343a40] tw-border-none"
                >
                  <BsCheck2All size={20} />
                </button>
              </div>
            </th>
          </tr>
        );
      }
    });
  };

  const handleStep = (tag, value) => {
    if (tag === "roomType") {
      setStepForm({ ...stepForm, [tag]: value, numberOfBeds: "" });
      // setBeds([]);
    } else {
      if (tag === "numberOfBeds") {
        if (value) {
          value = Math.floor(parseInt(value));
        }
        // setBeds([]);
      }
      setStepForm({ ...stepForm, [tag]: value });
    }
  };

  const handleNextStep = (e) => {
    e.preventDefault();
    // setFormData((el) => {
    //   return {
    //     ...el,
    //     sleepingArrangements: roomList,
    //   };
    // });
    setBeds([]);
    if (roomList.length) {
      save({ sleepingArrangements: roomList });
      onNext();
    }
  };

  const handleBathroom = ({ type }) => {
    if (type === "minus") {
      if (numberOfBathroom === 0) {
        return;
      } else setBathroom(numberOfBathroom - 0.5);
    } else {
      setBathroom(numberOfBathroom + 0.5);
    }
  };

  return (
    <div className={className}>
      <div className=" tw-min-h-[60vh]">
        <h4 className="mb-5 tw-flex tw-justify-start tw-items-center">
          <span className="tw-bg-[#23426e] tw-mr-2 tw-flex tw-justify-center tw-items-center  tw-h-10 tw-w-10 tw-text-white  tw-rounded-full">
            7
          </span>{" "}
          Sleeping Arrangements
        </h4>
        <div className=" row col-lg-12">
          <div className="card">
            <div className="card-body">
              {/* <p className="tw-text-sm tw-text-gray-400 tw-my-2">
                ⓘ Add all rooms and beds where guests sleep including those that
                are currently occupied
              </p> */}
              <div className="table-responsive">
                <table className="table header-border table-responsive-sm">
                  <thead>
                    <tr>
                      <td className="tw-text-lg tw-font-medium">Room No.</td>
                      <td className="tw-text-lg tw-font-medium">Room Name</td>
                      {formData.rentalType === "Crashpad" && (
                        <td className="tw-text-lg tw-font-medium">Room Type</td>
                      )}
                      {formData.rentalType === "Crashpad" && (
                        <td className="tw-text-lg tw-font-medium">Gender</td>
                      )}
                      {formData.rentalType === "Crashpad" && (
                        <td className="tw-text-lg tw-font-medium">Crew Type</td>
                      )}
                      <td className="tw-text-lg tw-font-medium">
                        No. of Bathrooms{" "}
                      </td>
                      <td className="tw-text-lg tw-font-medium">
                        No. of Beds{" "}
                      </td>
                      <td className="tw-text-lg tw-font-medium">Action</td>
                    </tr>
                  </thead>
                  <tbody>
                    {roomList.map((curr, ind) => {
                      return (
                        <tr key={ind}>
                          <td>{curr.roomNumber}</td>
                          <td>{curr.roomName}</td>
                          {formData.rentalType === "Crashpad" && (
                            <td>{curr.roomType}</td>
                          )}
                          {formData.rentalType === "Crashpad" && (
                            <td>{curr.genderType}</td>
                          )}
                          {formData.rentalType === "Crashpad" && (
                            <td>{curr.crewType}</td>
                          )}
                          <td>{curr.numberOfBathroom}</td>
                          <td>{curr.beds.length}</td>
                          <td>
                            <button
                              onClick={() => editSleepingArrangement(curr, ind)}
                              type="button"
                              className=" tw-h-12 tw-w-12 tw-bg-[#c8c8c8] tw-rounded-lg tw-outline-none tw-text-[#343a40] tw-border-none"
                            >
                              <BsPencilSquare size={17} />
                            </button>
                            <button
                              // disabled={curr.isBooked}
                              onClick={() =>
                                deleteSleepingArrangement(curr, ind)
                              }
                              type="button"
                              className={`mx-1 tw-h-12 tw-w-12 tw-bg-[#c8c8c8] tw-rounded-lg tw-outline-none tw-text-[#343a40] tw-border-none`}
                            >
                              <BsTrash size={17} />
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>

                <div className="tw-p-5">
                  <h4 className="tw-text-center">
                    {roomList.length > 0
                      ? "Add More Rooms"
                      : "No Room Added yet"}
                  </h4>
                  <button
                    type="button"
                    onClick={() => addSleepingArrangement()}
                    className="tw-mx-auto tw-h-16 tw-w-16 tw-bg-[#c8c8c8] tw-rounded-lg tw-outline-none flex-center tw-text-[#343a40] tw-border-none"
                    // data-bs-toggle="modal"
                    // data-bs-target=".bd-example-modal-lg"
                  >
                    <BsPlusLg size={22} />
                  </button>
                </div>
              </div>
              <div
                className="modal  bd-example-modal-lg"
                tabindex="-1"
                role="dialog"
                aria-hidden="true"
                id="addRoomModal"
              >
                <div className="modal-dialog modal-lg">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">Add Room</h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                      ></button>
                    </div>
                    <form onSubmit={handleRoom}>
                      <div className="modal-body">
                        <div className="row">
                          <div className="col-lg-6 mb-2">
                            <div className="mb-3">
                              <label className="text-label form-label">
                                Room Number
                              </label>
                              <input
                                // disabled={isEdit?roomList?roomList[index].isBooked:false:false}

                                onChange={(e) =>
                                  handleStep("roomNumber", e.target.value)
                                }
                                value={
                                  stepForm["roomNumber"]
                                    ? stepForm["roomNumber"]
                                    : ""
                                }
                                type="text"
                                name="roomNumber"
                                className="form-control"
                                placeholder="Room Number"
                                required
                              />
                            </div>
                          </div>
                          <div className="col-lg-6 mb-2">
                            <div className="mb-3">
                              <label className="text-label form-label">
                                Room Name
                              </label>
                              <input
                                // disabled={isEdit?roomList?roomList[index].isBooked:false:false}
                                onChange={(e) =>
                                  handleStep("roomName", e.target.value)
                                }
                                value={
                                  stepForm["roomName"]
                                    ? stepForm["roomName"]
                                    : ""
                                }
                                type="text"
                                name="roomName"
                                className="form-control"
                                placeholder="Room Name"
                                required
                              />
                            </div>
                          </div>

                          {formData.rentalType === "Crashpad" && (
                            <div className="col-lg-6 mb-2">
                              <div className="mb-3">
                                <label className="form-label">Room Type</label>
                                <select
                                  // disabled={isEdit?roomList?roomList[index].isBooked:false:false}
                                  onChange={(e) =>
                                    handleStep("roomType", e.target.value)
                                  }
                                  value={
                                    stepForm["roomType"]
                                      ? stepForm["roomType"]
                                      : ""
                                  }
                                  name="roomType"
                                  className="  form-control wide"
                                  required
                                >
                                  <option value="">Select</option>
                                  <option value="Shared">Shared</option>
                                  <option value="Private">Private</option>
                                </select>
                              </div>
                            </div>
                          )}
                          {formData.rentalType === "Crashpad" && (
                            <div className="col-lg-6 mb-2">
                              <div className="mb-3">
                                <label className="form-label">
                                  Gender Type
                                </label>
                                <select
                                  // disabled={isEdit?roomList?roomList[index].isBooked:false:false}
                                  onChange={(e) =>
                                    handleStep("genderType", e.target.value)
                                  }
                                  value={
                                    stepForm["genderType"]
                                      ? stepForm["genderType"]
                                      : ""
                                  }
                                  className="form-control wide"
                                  required
                                >
                                  <option value="">Select</option>
                                  <option value="Male">Male</option>
                                  <option value="Female">Female</option>
                                  <option value="Co-Ed">Co-Ed</option>
                                </select>
                              </div>
                            </div>
                          )}
                          {formData.rentalType === "Crashpad" && (
                            <div className="col-lg-6 mb-2">
                              <div className="mb-3">
                                <label className="form-label"> Crew Type</label>
                                <select
                                  // disabled={isEdit?roomList?roomList[index].isBooked:false:false}
                                  onChange={(e) =>
                                    handleStep("crewType", e.target.value)
                                  }
                                  value={
                                    stepForm["crewType"]
                                      ? stepForm["crewType"]
                                      : ""
                                  }
                                  className="form-control wide"
                                  required
                                >
                                  <option value="">Select</option>
                                  <option value="Flight Attendant Only">
                                    Flight Attendant Only
                                  </option>
                                  <option value="Pilot Only">Pilot Only</option>
                                  <option value="Flight Attendant & Pilot Only">
                                    Flight Attendant & Pilot Only
                                  </option>
                                  <option value="All Airline Professionals">
                                    All Airline Professionals
                                  </option>
                                </select>
                              </div>
                            </div>
                          )}

                          {formData.rentalType === "Crashpad" && (
                            <div className="col-lg-6 mb-2">
                              <div className="mb-3">
                                <label className="text-label form-label">
                                  Number of Beds
                                </label>
                                <input
                                  onChange={(e) => {
                                    if (
                                      (stepForm["roomType"] === "Shared" &&
                                        parseInt(e.target.value) <= 50 &&
                                        parseInt(e.target.value) >= 1) ||
                                      e.target.value == ""
                                    ) {
                                      handleStep(
                                        "numberOfBeds",
                                        e.target.value
                                      );
                                    } else if (
                                      (stepForm["roomType"] === "Private" &&
                                        parseInt(e.target.value) <= 1 &&
                                        parseInt(e.target.value) >= 1) ||
                                      e.target.value == ""
                                    ) {
                                      handleStep(
                                        "numberOfBeds",
                                        e.target.value
                                      );
                                    }
                                  }}
                                  value={
                                    stepForm["numberOfBeds"]
                                      ? stepForm["numberOfBeds"]
                                      : ""
                                  }
                                  maxLength={2}
                                  min={1}
                                  max={50}
                                  name="bedNumbers"
                                  className="form-control"
                                  placeholder="Number of beds"
                                  required
                                  disabled={!stepForm["roomType"]}
                                  // disabled={!stepForm["roomType"]?!stepForm["roomType"]:isEdit?roomList?roomList[index].isBooked:false:false}
                                  type="text"
                                  pattern="[0-9]*"
                                />
                              </div>
                            </div>
                          )}

                          {formData.rentalType === "Entire Place" && (
                            <div className="col-lg-6 mb-2">
                              <div className="mb-3">
                                <label className="text-label form-label">
                                  Number of Beds
                                </label>
                                <input
                                  // disabled={isEdit?roomList?roomList[index].isBooked:false:false}
                                  onChange={(e) => {
                                    if (
                                      parseInt(e.target.value) >= 1 ||
                                      e.target.value == ""
                                    ) {
                                      handleStep(
                                        "numberOfBeds",
                                        e.target.value
                                      );
                                    }
                                  }}
                                  value={
                                    stepForm["numberOfBeds"]
                                      ? stepForm["numberOfBeds"]
                                      : ""
                                  }
                                  min={1}
                                  name="bedNumbers"
                                  className="form-control"
                                  placeholder="Number of beds"
                                  required
                                  type="text"
                                  pattern="[0-9]*"
                                />
                              </div>
                            </div>
                          )}
                          <div className="col-lg-6 mb-2">
                            <div className="mb-3">
                              <label className="text-label form-label">
                                Number of Bathrooms
                              </label>
                              <div className="tw-flex tw-space-x-2 tw-items-center">
                                <span
                                  onClick={() => {
                                    // const isDisabled = isEdit?roomList?roomList[index].isBooked:false:false;
                                    // if(!isDisabled){
                                    handleBathroom({ type: "minus" });
                                    // }
                                  }}
                                  className="tw-cursor-pointer tw-flex-shrink-0 tw-block tw-h-10 tw-w-10 tw-rounded-full tw-bg-gray-400 tw-border tw-flex tw-items-center tw-justify-center"
                                >
                                  <AiOutlineMinus
                                    size={18}
                                    className="tw-text-gray-700"
                                  />
                                </span>
                                <div className="tw-w-44 tw-flex-grow">
                                  <input
                                    readOnly
                                    value={numberOfBathroom}
                                    type="text"
                                    name="numberOfBathroom"
                                    className="form-control"
                                    placeholder="Number of Bathroom"
                                    required
                                  />
                                </div>
                                <span
                                  onClick={() => {
                                    // const isDisabled = isEdit?roomList?roomList[index].isBooked:false:false;
                                    // if(!isDisabled){
                                    handleBathroom({ type: "plus" });
                                    // }
                                  }}
                                  className="tw-cursor-pointer tw-flex-shrink-0 tw-block tw-h-10 tw-w-10 tw-rounded-full tw-bg-gray-400 tw-border tw-flex tw-items-center tw-justify-center"
                                >
                                  <AiOutlinePlus
                                    size={18}
                                    className="tw-text-gray-700"
                                  />
                                </span>
                              </div>
                            </div>
                          </div>
                          {/* <div className="col-lg-6 mb-2">
                              <div>
                                <label className="form-label">
                                  {" "}
                                  numberOfBathroom Type
                                </label>
                                <select
                                  onChange={(e) =>
                                    handleStep("bathroom_type", e.target.value)
                                  }
                                  value={stepForm["bathroom_type"]}
                                  className=" form-control wide"
                                >
                                  <option value="">Select</option>
                                  <option value="fully"> Fully</option>
                                  <option value="half">Half</option>
                                  <option value="No numberOfBathroom">
                                    No numberOfBathroom
                                  </option>
                                </select>
                              </div>
                            </div> */}
                        </div>
                        <h5 className="modal-title tw-my-4">Add Bed</h5>

                        <div className="table-responsive">
                          <table className="table table-responsive-sm">
                            <thead>
                              <tr>
                                {formData.rentalType === "Crashpad" && (
                                  <th>Bed Number</th>
                                )}
                                <th>Bed Type</th>
                                {formData.rentalType === "Crashpad" &&
                                  (formData.bookingType === "Hot bed" ||
                                    formData.bookingType === "Flexible") && (
                                    <th>Nightly Price</th>
                                  )}
                                {formData.rentalType === "Crashpad" &&
                                  (formData.bookingType === "Cold bed" ||
                                    formData.bookingType === "Flexible") && (
                                    <th>Reserve Monthly Price</th>
                                  )}
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {renderBedlist()}

                              {beds.length < stepForm.numberOfBeds && (
                                <tr>
                                  {formData.rentalType === "Crashpad" && (
                                    <th>
                                      <div>
                                        <input
                                          onChange={(e) => {
                                            setBedNumber(e.target.value);
                                          }}
                                          value={bedNumber}
                                          type="text"
                                          className="form-control"
                                          placeholder="Bed Number"
                                          required
                                        />
                                      </div>
                                    </th>
                                  )}
                                  <th>
                                    <div>
                                      <select
                                        name="bed_type"
                                        onChange={(e) =>
                                          setBedType(e.target.value)
                                        }
                                        value={bedType}
                                        className="form-control wide"
                                        required
                                      >
                                        <option value="">Select</option>
                                        <option value="King">King</option>
                                        <option value="Queen">Queen</option>
                                        <option value="Twin">Twin</option>
                                        <option value="Couch">Couch</option>
                                        <option value="Top bunk">
                                          Top bunk
                                        </option>
                                        <option value="Bottom bunk">
                                          Bottom bunk
                                        </option>
                                        <option value="Other">Other</option>
                                      </select>
                                    </div>
                                  </th>

                                  {formData.rentalType === "Crashpad" &&
                                    (formData.bookingType === "Hot bed" ||
                                      formData.bookingType === "Flexible") && (
                                      <th>
                                        <div>
                                          <input
                                            onChange={(e) => {
                                              if (
                                                parseInt(e.target.value) >= 1 ||
                                                e.target.value == ""
                                              ) {
                                                setNightPrice(
                                                  e.target.value
                                                    ? Math.floor(
                                                        parseInt(e.target.value)
                                                      )
                                                    : ""
                                                );
                                              }
                                            }}
                                            value={perNightPrice}
                                            placeholder="Per night price"
                                            maxLength={9}
                                            min={1}
                                            max={999999999}
                                            className="form-control"
                                            type="text"
                                            required
                                          />
                                        </div>
                                      </th>
                                    )}
                                  {formData.rentalType === "Crashpad" &&
                                    (formData.bookingType === "Cold bed" ||
                                      formData.bookingType === "Flexible") && (
                                      <th>
                                        <div>
                                          <input
                                            onChange={(e) => {
                                              if (
                                                parseInt(e.target.value) >= 1 ||
                                                e.target.value == ""
                                              ) {
                                                setMonthPrice(
                                                  e.target.value
                                                    ? Math.floor(
                                                        parseInt(e.target.value)
                                                      )
                                                    : ""
                                                );
                                              }
                                            }}
                                            value={perMonthPrice}
                                            type="number"
                                            className="form-control"
                                            placeholder="Per month price"
                                            maxLength={9}
                                            min={1}
                                            max={999999999}
                                            required
                                          />
                                        </div>
                                      </th>
                                    )}

                                  <th>
                                    <div>
                                      <button
                                        onClick={handleBed}
                                        type="button"
                                        className=" tw-h-12 tw-w-12 tw-bg-[#c8c8c8] tw-rounded-lg tw-outline-none flex-center tw-text-[#343a40] tw-border-none"
                                      >
                                        <BsPlusLg size={20} />
                                      </button>
                                    </div>
                                  </th>
                                </tr>
                              )}

                              {/* {isEditBed && (
                                    <tr>
                                      {formData.rentalType === "Crashpad" && (
                                        <th>
                                          <div>
                                            <input
                                              onChange={(e) => {
                                                setBedNumber(e.target.value);
                                              }}
                                              value={bedNumber}
                                              type="text"
                                              className="form-control"
                                              placeholder="Bed Number"
                                              required
                                            />
                                          </div>
                                        </th>
                                      )}
                                      <th>
                                        <div>
                                          <select
                                            name="bed_type"
                                            onChange={(e) =>
                                              setBedType(e.target.value)
                                            }
                                            value={bedType}
                                            className="form-control wide"
                                            required
                                          >
                                            <option value="">Select</option>
                                            <option value="King">King</option>
                                            <option value="Queen">Queen</option>
                                            <option value="Top bunk">
                                              Top bunk
                                            </option>
                                            <option value="Bottom bunk">
                                              Bottom bunk
                                            </option>
                                          </select>
                                        </div>
                                      </th>

                                      {formData.rentalType === "Crashpad" &&
                                        (formData.bookingType === "Hot bed" ||
                                          formData.bookingType ===
                                          "Flexible") && (
                                          <th>
                                            <div>
                                              <input
                                                onChange={(e) => {
                                                  if (
                                                    parseInt(e.target.value) >=
                                                    1 ||
                                                    e.target.value == ""
                                                  ) {
                                                    setNightPrice(
                                                      e.target.value
                                                        ? Math.floor(
                                                          parseInt(
                                                            e.target.value
                                                          )
                                                        )
                                                        : ""
                                                    );
                                                  }
                                                }}
                                                value={perNightPrice}
                                                placeholder="Per night price"
                                                maxLength={9}
                                                min={1}
                                                max={999999999}
                                                className="form-control"
                                                type="text"
                                                required
                                              />
                                            </div>
                                          </th>
                                        )}
                                      {formData.rentalType === "Crashpad" &&
                                        (formData.bookingType === "Cold bed" ||
                                          formData.bookingType ===
                                          "Flexible") && (
                                          <th>
                                            <div>
                                              <input
                                                onChange={(e) => {
                                                  if (
                                                    parseInt(e.target.value) >=
                                                    1 ||
                                                    e.target.value == ""
                                                  ) {
                                                    setMonthPrice(
                                                      e.target.value
                                                        ? Math.floor(
                                                          parseInt(
                                                            e.target.value
                                                          )
                                                        )
                                                        : ""
                                                    );
                                                  }
                                                }}
                                                value={perMonthPrice}
                                                type="number"
                                                className="form-control"
                                                placeholder="Per month price"
                                                maxLength={9}
                                                min={1}
                                                max={999999999}
                                                required
                                              />
                                            </div>
                                          </th>
                                        )}

                                      <th>
                                        <div>
                                          <button
                                            onClick={updatedBed}
                                            type="button"
                                            className=" tw-h-12 tw-w-12 tw-bg-[#c8c8c8] tw-rounded-lg tw-outline-none flex-center tw-text-[#343a40] tw-border-none"
                                          >
                                            <BsCheck2All size={20} />
                                          </button>
                                        </div>
                                      </th>
                                    </tr>
                                  )} */}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-danger light"
                          data-bs-dismiss="modal"
                        >
                          Close
                        </button>
                        <button type="submit" className="btn btn-secondary">
                          Save changes
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <button
          onClick={onPrev}
          type="button"
          className="btn btn-secondary mx-2 "
        >
          Prev
        </button>

        <button
          disabled={updating}
          onClick={handleNextStep}
          className="btn btn-secondary"
        >
          {updating ? "Updating ..." : "Save & Next"}
        </button>
      </div>
    </div>
  );
};

const PricingAndFee = ({
  onNext,
  onPrev,
  formData,
  setFormData,
  className,
  save,
  updating,
}) => {
  const [stepForm, setStepForm] = useState({
    perNightPrice: formData.perNightPrice,
    extraChargePerGuest: formData.extraChargePerGuest,
    perMonthPrice: formData.perMonthPrice,
    cleaningFee: formData.cleaningFee,
    securityDeposite: formData.securityDeposite,
    lineholderDiscountType: formData.lineholderDiscountType,
    lineholderDiscount: formData.lineholderDiscount,
  });
  const handleStep = (tag, value) => {
    setStepForm({ ...stepForm, [tag]: value });
  };

  const handleNextStep = (e) => {
    e.preventDefault();
    // setFormData((el) => {
    //   return { ...el, ...stepForm };
    // });
    save(stepForm);
    onNext();
  };
  return (
    <form onSubmit={handleNextStep}>
      <div className={className}>
        <div>
          <h4 className="mb-5 tw-flex tw-justify-start tw-items-center">
            <span className="tw-bg-[#23426e] tw-mr-2 tw-flex tw-justify-center tw-items-center  tw-h-10 tw-w-10 tw-text-white  tw-rounded-full">
              8
            </span>{" "}
            Pricing and Fees
          </h4>
          <div className="row tw-min-h-[60vh]">
            {formData.rentalType === "Entire Place" &&
              (formData.bookingType === "Hot bed" ||
                formData.bookingType === "Flexible") && (
                <div className="col-lg-6 mb-2">
                  <div>
                    <label className="form-label"> Per Night Price</label>
                    <input
                      onChange={(e) => {
                        if (
                          parseInt(e.target.value) >= 1 ||
                          e.target.value == ""
                        ) {
                          handleStep(
                            "perNightPrice",
                            e.target.value
                              ? Math.floor(parseInt(e.target.value))
                              : ""
                          );
                        } else {
                          handleStep("perNightPrice", "");
                        }
                      }}
                      value={stepForm["perNightPrice"]}
                      type="text"
                      name="perNightPrice"
                      className="form-control"
                      placeholder="Per Night Price"
                      required
                    />
                    {formData.rentalType !== "Entire Place" &&
                      formData.bookingType !== "Cold bed" && (
                        <p className="tw-mt-2 tw-flex tw-items-center tw-justify-start">
                          <AiOutlineInfoCircle
                            size={18}
                            className="tw-mx-2 tw-gray-500"
                          />
                          There is no monthly for hot bed and it does not align
                          with the functionality of prompt
                        </p>
                      )}
                  </div>
                </div>
              )}

            {formData.rentalType === "Entire Place" &&
              (formData.bookingType === "Cold bed" ||
                formData.bookingType === "Flexible") && (
                <div className="col-lg-6 mb-2">
                  <div>
                    <label className="form-label"> Per Month Price</label>
                    <input
                      onChange={(e) => {
                        if (
                          parseInt(e.target.value) >= 1 ||
                          e.target.value == ""
                        ) {
                          handleStep(
                            "perMonthPrice",
                            e.target.value
                              ? Math.floor(parseInt(e.target.value))
                              : ""
                          );
                        } else {
                          handleStep("perMonthPrice", "");
                        }
                      }}
                      value={stepForm["perMonthPrice"]}
                      type="text"
                      name="perMonthPrice"
                      className="form-control"
                      placeholder="Per Month Price"
                      required
                    />
                    <p className="tw-mt-2 tw-flex tw-items-center tw-justify-start">
                      ⓘ Prorated if guest checks in after the 1st of the month
                    </p>
                  </div>
                </div>
              )}
            {formData.rentalType === "Entire Place" && (
              <div className="col-lg-6 mb-2">
                <div>
                  <label className="form-label">
                    {" "}
                    Extra Charge Per Guest ($)
                  </label>
                  <input
                    onChange={(e) => {
                      if (
                        parseInt(e.target.value) >= 1 ||
                        e.target.value == ""
                      ) {
                        handleStep(
                          "extraChargePerGuest",
                          e.target.value
                            ? Math.floor(parseInt(e.target.value))
                            : ""
                        );
                      } else {
                        handleStep("extraChargePerGuest", "");
                      }
                    }}
                    value={stepForm["extraChargePerGuest"]}
                    type="number"
                    name="extraChargePerGuest"
                    className="form-control"
                    placeholder="Extra charge per guest"
                    required
                  />
                </div>
              </div>
            )}
            <div className="col-lg-6 mb-2">
              <div>
                <label className="form-label"> Cleaning Fee</label>
                <input
                  onChange={(e) => {
                    if (parseInt(e.target.value) > 0 || e.target.value == "") {
                      handleStep(
                        "cleaningFee",
                        e.target.value
                          ? Math.floor(parseInt(e.target.value))
                          : ""
                      );
                    } else {
                      handleStep("cleaningFee", "");
                    }
                  }}
                  value={stepForm["cleaningFee"]}
                  type="text"
                  name="cleaningFee"
                  className="form-control"
                  placeholder="Cleaning Fee"
                  required
                />
                {formData.rentalType === "Entire Place" &&
                  formData.bookingType === "Cold bed" && (
                    // <p className="tw-mt-2 tw-flex tw-items-center tw-justify-start">
                    //   <AiOutlineInfoCircle
                    //     size={18}
                    //     className="tw-mx-2 tw-gray-500"
                    //   />
                    //   Charged monthly for guests on autopay or one time for all other bookings
                    // </p>
                    <p className="tw-mt-2 tw-flex tw-items-center tw-justify-start">
                      ⓘ Charged monthly for guests on autopay or one time for
                      all other bookings
                    </p>
                  )}
                {formData.rentalType !== "Entire Place" &&
                  formData.bookingType === "Cold bed" && (
                    <p className="tw-mt-2 tw-flex tw-items-center tw-justify-start">
                      ⓘ Charged monthly for guests on autopay
                    </p>
                  )}

                {formData.bookingType === "Flexible" && (
                  <p className="tw-mt-2 tw-flex tw-items-center tw-justify-start">
                    ⓘ Charged monthly for guests on autopay or one time for all
                    other bookings
                  </p>
                )}
              </div>
            </div>
            <div className="col-lg-6 mb-2">
              <div>
                <label className="form-label">Security Deposit </label>
                <input
                  onChange={(e) => {
                    if (parseInt(e.target.value) >= 1 || e.target.value == "") {
                      handleStep(
                        "securityDeposite",
                        e.target.value
                          ? Math.floor(parseInt(e.target.value))
                          : ""
                      );
                    } else {
                      handleStep("securityDeposite", "");
                    }
                  }}
                  value={stepForm["securityDeposite"]}
                  type="text"
                  name="securityDeposite"
                  className="form-control"
                  placeholder="Security Deposit"
                  required
                />
                <p className="tw-mt-2 tw-flex tw-items-center tw-justify-start">
                  ⓘ Any guest who confirms a reservation at your listing will be
                  responsible for damages up to this amount. No charges or
                  authorizations will be made unless you make a claim within 48
                  hours after check out
                </p>
              </div>
            </div>

            {(formData.bookingType === "Cold bed" ||
              formData.bookingType === "Flexible") && (
              <div className="col-lg-6 mb-2">
                <div>
                  <label className="form-label">Lineholder Discount Type</label>
                  <select
                    onChange={(e) =>
                      handleStep("lineholderDiscountType", e.target.value)
                    }
                    value={stepForm["lineholderDiscountType"]}
                    name="lineholderDiscountType"
                    className="form-control wide"
                    required
                  >
                    <option value="">Select</option>
                    <option value="none">None</option>
                    <option value="Percentage">Percentage</option>
                    <option value="Fixed">Fixed</option>
                  </select>
                </div>
              </div>
            )}
            {(stepForm.lineholderDiscountType === "Percentage" ||
              stepForm.lineholderDiscountType === "Fixed") && (
              <div className="col-lg-6 mb-2">
                <div>
                  <label className="form-label">
                    Lineholder Discount{" "}
                    {stepForm.lineholderDiscountType === "Percentage" && "(%)"}
                  </label>
                  <input
                    onChange={(e) => {
                      if (e.target.value) {
                        if (
                          stepForm.lineholderDiscountType === "Percentage" &&
                          parseInt(e.target.value) >= 0 &&
                          parseInt(e.target.value) <= 99
                        ) {
                          handleStep(
                            "lineholderDiscount",
                            e.target.value
                              ? Math.floor(parseInt(e.target.value))
                              : ""
                          );
                        } else if (
                          stepForm.lineholderDiscountType === "Fixed" &&
                          parseInt(e.target.value) >= 0
                        ) {
                          handleStep(
                            "lineholderDiscount",
                            e.target.value
                              ? Math.floor(parseInt(e.target.value))
                              : ""
                          );
                        } else {
                          handleStep("lineholderDiscount", "");
                        }
                      } else {
                        handleStep("lineholderDiscount", "");
                      }
                    }}
                    value={stepForm["lineholderDiscount"]}
                    type="text"
                    name="lineholderDiscount"
                    className="form-control"
                    placeholder="Discount"
                    required
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="tw-my-4">
          <button
            onClick={onPrev}
            type="button"
            className="btn btn-secondary mx-2 "
          >
            Prev
          </button>

          <button
            type="submit"
            disabled={updating}
            className="btn btn-secondary"
          >
            {updating ? "Updating ..." : "Save & Next"}
          </button>
        </div>
      </div>
    </form>
  );
};

const Terms = ({
  onNext,
  onPrev,
  formData,
  setFormData,
  className,
  save,
  updating,
}) => {
  const [stepForm, setStepForm] = useState({
    cancelationPolicy: formData.cancelationPolicy,
  });
  const handleStep = (tag, value) => {
    setStepForm({ ...stepForm, [tag]: value });
  };
  const handleNextStep = (e) => {
    e.preventDefault();
    // setFormData((el) => {
    //   return { ...el, ...stepForm };
    // });
    save(stepForm);
    onNext();
  };

  return (
    <form onSubmit={handleNextStep}>
      <div className={className}>
        <div className=" my-3 tw-min-h-[60vh]">
          <h4 className="mb-5 tw-flex tw-justify-start tw-items-center">
            <span className="tw-bg-[#23426e] tw-mr-2 tw-flex tw-justify-center tw-items-center  tw-h-10 tw-w-10 tw-text-white  tw-rounded-full">
              9
            </span>{" "}
            Terms
          </h4>
          <div className="row col-lg-6 mb-2">
            <div className="mb-3">
              <label className="form-label">Select Cancellation Policy</label>
              <select
                onChange={(e) =>
                  handleStep("cancelationPolicy", e.target.value)
                }
                value={stepForm["cancelationPolicy"]}
                className="form-control wide"
                required
              >
                <option value="">Select</option>
                <option value="Relaxed">Relaxed</option>
                <option value="Moderate">Moderate</option>
                <option value="Strict">Strict</option>
              </select>
              {stepForm["cancelationPolicy"] === "Relaxed" && (
                <p className="tw-text-sm tw-text-gray-400 tw-my-2">
                  ⓘ Guests can receive a full refund up until 24 hours before
                  check-in
                </p>
              )}
              {stepForm["cancelationPolicy"] === "Moderate" && (
                <p className="tw-text-sm tw-text-gray-400 tw-my-2">
                  ⓘ Guests can receive a full refund up until 5 days before
                  check-in
                </p>
              )}
              {stepForm["cancelationPolicy"] === "Strict" && (
                <p className="tw-text-sm tw-text-gray-400 tw-my-2">
                  ⓘ No refunds
                </p>
              )}
            </div>
          </div>
        </div>
        <div>
          <button
            onClick={onPrev}
            type="button"
            className="btn btn-secondary mx-2 "
          >
            Prev
          </button>

          <button
            type="submit"
            disabled={updating}
            className="btn btn-secondary"
          >
            {updating ? "Updating ..." : "Save & Next"}
          </button>
        </div>
      </div>
    </form>
  );
};

const SecurityCheckPoints = ({
  onNext,
  onPrev,
  formData,
  setFormData,
  className,
  requesting,
  hostList,
  save,
  updating,
}) => {
  const [stepForm, setStepForm] = useState({
    hostId: formData.hostId,
  });
  const [hostDetail, setHostDetail] = useState(formData.hostId);
  const [accountForm, setAccountForm] = useState({});
  const [bankAdding, SetBankAdding] = useState(false);

  const handleSave = async () => {
    save(stepForm);
  };

  const handleStep = (tag, value) => {
    if (value) {
      var host = hostList.filter((e) => e.id == value);
      setHostDetail(host[0]);
    } else {
      setHostDetail("");
    }
    setStepForm({ [tag]: value });
    // setFormData((el) => {
    //   return { [tag]: value };
    // });
  };

  const setValue = (tag, value) => {
    setAccountForm({ ...accountForm, [tag]: value });
  };

  const saveBankAccount = (e) => {
    e.preventDefault();
    if (accountForm.accountNumber != accountForm.confirmAccountNumber) {
      toast.info("Account number and confirm account number must be same.");
      return;
    }

    SetBankAdding(true);
    var postObj = { hostId: hostDetail.id, ...accountForm };
    bankService.addBank(postObj).then(
      (objS) => {
        if (objS.status) {
          window.$("#payoutmethod").modal("hide");
          window.$(".modal-backdrop").remove();
          hostList.forEach((h) => {
            if (h.id == hostDetail.id) {
              h.payoutVerification = true;
            }
            hostDetail.payoutVerification = true;
          });
          setHostDetail(hostDetail);
          toast.success("Bank account added successfully");
          setAccountForm({});
        } else {
          toast.error(objS.message);
        }
        SetBankAdding(false);
      },
      (error) => {
        window.$("#payoutmethod").modal("hide");
        window.$(".modal-backdrop").remove();
        SetBankAdding(false);
      }
    );
  };

  return (
    <div className={className}>
      <div className=" tw-min-h-[60vh]">
        <h4 className="mb-5 tw-flex tw-justify-start tw-items-center">
          <span className="tw-bg-[#23426e] tw-mr-2 tw-flex tw-justify-center tw-items-center  tw-h-10 tw-w-10 tw-text-white  tw-rounded-full">
            10
          </span>{" "}
          Security Checkpoint
        </h4>
        <div className="row">
          {/* <div className="col-lg-6 mb-2">
            <div className="mb-3">
              <label className="form-label">Select Host</label>
              <select
                name="hostId"
                onChange={(e) => handleStep("hostId", e.target.value)}
                value={stepForm["hostId"]}
                className="form-control wide"
                required
                disabled
              >
                <option value="">Select</option>
                {hostList.map((data) => (
                  <option value={data.id}>{data.name}</option>
                ))}
              </select>
            </div>
          </div> */}
          {hostDetail ? (
            <div>
              <div className="tw-flex tw-mb-4 tw-justify-between tw-items-center">
                <h5>
                  1. Email
                  {hostDetail.personalEmailVerification == 1
                    ? " Verified "
                    : " Not Verified "}
                  {hostDetail.personalEmailVerification == 1 ? (
                    <FaCheckCircle className="tw-text-green-500" size={24} />
                  ) : (
                    <BsPlusCircleFill
                      className="tw-transform tw-rotate-45 tw-text-red-500"
                      size={26}
                    />
                  )}
                </h5>
                {/* <button className="btn btn-secondary">Verify Email</button> */}
              </div>
              <div className="tw-flex tw-mb-4 tw-justify-between tw-items-center">
                <h5>
                  2. Phone
                  {hostDetail.phoneVerification == 1
                    ? " Verified "
                    : " Not Verified "}
                  {hostDetail.phoneVerification == 1 ? (
                    <FaCheckCircle className="tw-text-green-500" size={24} />
                  ) : (
                    <BsPlusCircleFill
                      className="tw-transform tw-rotate-45 tw-text-red-500"
                      size={26}
                    />
                  )}
                </h5>
                {/* <button className="btn btn-secondary">Verify Phone</button> */}
              </div>
              <div className="tw-flex tw-mb-4 tw-justify-between tw-items-center">
                <h5>
                  3. Selfie & ID
                  {hostDetail.idVerificationStatus == 1
                    ? " Verified "
                    : " Not Verified "}
                  {hostDetail.idVerificationStatus == 1 ? (
                    <FaCheckCircle className="tw-text-green-500" size={24} />
                  ) : (
                    <BsPlusCircleFill
                      className="tw-transform tw-rotate-45 tw-text-red-500"
                      size={26}
                    />
                  )}
                </h5>
                {/* <button
                  className="btn btn-secondary"
                  data-bs-toggle="modal"
                  data-bs-target="#payoutmethod"
                >
                  Connect Now
                </button> */}
              </div>
              <div className="tw-flex tw-mb-4 tw-justify-between tw-items-center">
                <h5>
                  4. Payout
                  {hostDetail.payoutVerification == 1
                    ? " Verified "
                    : " Not Verified "}
                  {hostDetail.payoutVerification == 1 ? (
                    <FaCheckCircle className="tw-text-green-500" size={24} />
                  ) : (
                    <BsPlusCircleFill
                      className="tw-transform tw-rotate-45 tw-text-red-500"
                      size={26}
                    />
                  )}
                </h5>
                {/* {hostDetail.payoutVerification != 1 && (
                  <button
                    className="btn btn-secondary"
                    data-bs-toggle="modal"
                    data-bs-target="#payoutmethod"
                  >
                    Connect Now
                  </button>
                )} */}
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
        <div
          className="modal  bd-example-modal-lg"
          tabindex="-1"
          role="dialog"
          aria-hidden="true"
          id="payoutmethod"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Add Your Bank Account</h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                ></button>
              </div>
              <form onSubmit={saveBankAccount}>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-lg-6 mb-2">
                      <div className="mb-3">
                        <label className="text-label form-label">
                          Currency
                        </label>
                        <select
                          className="form-control wide"
                          onChange={(e) => setValue("currency", e.target.value)}
                          value={accountForm["currency"]}
                          required
                        >
                          <option value="">Select</option>
                          <option value="USD">USD</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-6 mb-2">
                      <div className="mb-3">
                        <label className="text-label form-label">Country</label>
                        <select
                          name="country"
                          className="form-control wide"
                          onChange={(e) => setValue("country", e.target.value)}
                          value={accountForm["country"]}
                          required
                        >
                          <option value="">Select</option>
                          <option value="US">USA</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-6 mb-2">
                      <div className="mb-3">
                        <label className="text-label form-label">
                          Account Holder Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Account Holder Name"
                          required
                          name="accountHolderType"
                          onChange={(e) =>
                            setValue("accountHolderName", e.target.value)
                          }
                          value={accountForm["accountHolderName"]}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 mb-2">
                      <div className="mb-3">
                        <label className="text-label form-label">
                          Account Holder Type
                        </label>
                        <select
                          className="form-control wide"
                          required
                          name="accountHolderType"
                          onChange={(e) =>
                            setValue("accountHolderType", e.target.value)
                          }
                          value={accountForm["accountHolderType"]}
                        >
                          <option value="">Select</option>
                          <option value="individual">Individual</option>
                          <option value="company">Company</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-6 mb-2">
                      <div className="mb-3">
                        <label className="text-label form-label">
                          Routing Number
                        </label>
                        <input
                          type="text"
                          name="routingNumber"
                          className="form-control"
                          placeholder="Routing Number"
                          required
                          onChange={(e) =>
                            setValue("routingNumber", e.target.value)
                          }
                          value={accountForm["routingNumber"]}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 mb-2">
                      <div className="mb-3">
                        <label className="text-label form-label">
                          Account Number
                        </label>
                        <input
                          type="text"
                          name="accountNumber"
                          className="form-control"
                          placeholder="Account Number"
                          required
                          onChange={(e) =>
                            setValue("accountNumber", e.target.value)
                          }
                          value={accountForm["accountNumber"]}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 mb-2">
                      <div className="mb-3">
                        <label className="text-label form-label">
                          Confirm Account Number
                        </label>
                        <input
                          type="text"
                          name="confirmAccountNumber"
                          className="form-control"
                          placeholder="Confirm Account Number"
                          required
                          onChange={(e) =>
                            setValue("confirmAccountNumber", e.target.value)
                          }
                          value={accountForm["confirmAccountNumber"]}
                        />
                      </div>
                    </div>
                    <button
                      disabled={bankAdding}
                      type="submit"
                      className="btn btn-secondary mb-3"
                    >
                      {bankAdding ? "Please wait ..." : "Submit"}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div>
        <button
          onClick={onPrev}
          type="button"
          className="btn btn-secondary mx-2 "
        >
          Prev
        </button>

        {hostDetail &&
          hostDetail.idVerificationStatus == 1 &&
          hostDetail.personalEmailVerification == 1 &&
          hostDetail.phoneVerification == 1 && (
            <button
              disabled={updating}
              onClick={handleSave}
              className="btn btn-secondary"
            >
              {updating ? "Updating ..." : "Update"}
            </button>
          )}
      </div>
    </div>
  );
};

function EditProperty(props) {
  window.scrollTo({ top: 0, behavior: "smooth" });

  const [formStep, setFormStep] = useState(0);
  const [formData, setFormData] = useState({});
  const [amenityList, setAmenityList] = useState([]);
  const [hostList, setHostList] = useState([]);
  const [apiCalled, setApiCalled] = useState(false);

  const [safetyFeatureList, setSafetyFeatureList] = useState([]);
  const [requesting, setRequest] = useState(false);
  const [updating, setUpdating] = useState(false);

  const params = useParams();

  const handleNextStep = (step) => {
    if (step < 9) {
      setFormStep((step) => step + 1);
    }
  };

  const handlePrevStep = (step) => {
    if (step > 0) {
      setFormStep((step) => step - 1);
    }
  };

  const save = (data) => {
    setUpdating(true);
    var reqData = Object.assign({}, data);
    reqData["propertyId"] = params.id;
    reqData["step"] = formStep;
    if (reqData.step == 0) {
      reqData.location = Object.values(data.location);
    }
    propertyService._update(reqData).then(
      (objS) => {
        if (objS.status) {
          toast.success("Details updated.");
          if (formStep == 9) {
            props.history.push("/properties");
          }
        } else {
          toast.error("Something went wrong.");
        }
        setUpdating(false);
      },
      (error) => {
        console.log(error);
        setUpdating(false);
      }
    );
  };

  const savePhoto = (data) => {
    setUpdating(true);
    data["propertyId"] = params.id;
    propertyService.updatePhoto(data).then(
      (objS) => {
        if (objS.status) {
          toast.success("Details updated.");
        } else {
          toast.error("Something went wrong.");
        }
        setUpdating(false);
      },
      (error) => {
        console.log(error);
        setUpdating(false);
      }
    );
  };

  const handleFormSubmission = () => {
    setRequest(true);
    formData.propertyId = params.id;
    propertyService.update(formData).then(
      (objS) => {
        props.history.push("/properties");
      },
      (error) => {
        console.log(error);
      }
    );
  };

  useEffect(() => {
    const prefetch = () => {
      amenityService.List({}).then(
        (objS) => {
          if (objS.status) setAmenityList(objS.data);
        },
        (error) => {
          console.log(error);
        }
      );
      safetyFeatureService.List({}).then(
        (objS) => {
          if (objS.status) setSafetyFeatureList(objS.data);
        },
        (error) => {
          console.log(error);
        }
      );
      userService.hostList({}).then(
        (objS) => {
          if (objS.status) setHostList(objS.data);
        },
        (error) => {
          console.log(error);
        }
      );
      propertyService.getDetail(params.id).then(
        (objS) => {
          if (objS.status) {
            objS.data.location =
              objS.data.location &&
              objS.data.location[0] &&
              objS.data.location[1]
                ? { lat: objS.data.location[1], lng: objS.data.location[0] }
                : { lat: constant.lat, lng: constant.lng };
            setFormData({ ...objS.data });
          }
          setApiCalled(true);
        },
        (error) => {
          setApiCalled(true);
          console.log(error);
        }
      );
    };

    prefetch();
  }, []);

  const handleFormStep = (i) => {
    setFormStep(i);
  };

  return (
    <Wrapper>
      <ToastContainer />
      {apiCalled && (
        <div className="row">
          {/* <form onSubmit={handleFormSubmission} enctype="multipart/form-data"> */}
          <div className="tw-py-4 tw-flex tw-flex-nowrap tw-overflow-x-auto">
            <span
              onClick={() => handleFormStep(0)}
              className={`tw-flex-shrink-0 tw-rounded-l-md tw-flex-shrink-0 tw-p-4 tw-text-sm tw-border-b-2 tw-cursor-pointer tw-border-gray-400  tw-block ${
                formStep === 0
                  ? "tw-bg-[#23426e] tw-text-white "
                  : "tw-bg-gray-100"
              }`}
            >
              Basics
            </span>
            <span
              onClick={() => handleFormStep(1)}
              className={`tw-flex-shrink-0 tw-p-4 tw-text-sm tw-border-b-2 tw-cursor-pointer tw-border-gray-400  tw-block ${
                formStep === 1
                  ? "tw-bg-[#23426e] tw-text-white "
                  : "tw-bg-gray-100"
              }`}
            >
              Details
            </span>
            <span
              onClick={() => handleFormStep(2)}
              className={`tw-flex-shrink-0 tw-p-4 tw-text-sm tw-border-b-2 tw-cursor-pointer tw-border-gray-400  tw-block ${
                formStep === 2
                  ? "tw-bg-[#23426e] tw-text-white "
                  : "tw-bg-gray-100"
              }`}
            >
              House Rules
            </span>
            <span
              onClick={() => handleFormStep(3)}
              className={`tw-flex-shrink-0 tw-p-4 tw-text-sm tw-border-b-2 tw-cursor-pointer tw-border-gray-400  tw-block ${
                formStep === 3
                  ? "tw-bg-[#23426e] tw-text-white "
                  : "tw-bg-gray-100"
              }`}
            >
              Photos
            </span>
            <span
              onClick={() => handleFormStep(4)}
              className={`tw-flex-shrink-0 tw-p-4 tw-text-sm tw-border-b-2 tw-cursor-pointer tw-border-gray-400  tw-block ${
                formStep === 4
                  ? "tw-bg-[#23426e] tw-text-white "
                  : "tw-bg-gray-100"
              }`}
            >
              Amenities
            </span>
            <span
              onClick={() => handleFormStep(5)}
              className={`tw-flex-shrink-0 tw-p-4 tw-text-sm tw-border-b-2 tw-cursor-pointer tw-border-gray-400  tw-block ${
                formStep === 5
                  ? "tw-bg-[#23426e] tw-text-white "
                  : "tw-bg-gray-100"
              }`}
            >
              Safety Features
            </span>
            <span
              onClick={() => handleFormStep(6)}
              className={`tw-flex-shrink-0 tw-p-4 tw-text-sm tw-border-b-2 tw-cursor-pointer tw-border-gray-400  tw-block ${
                formStep === 6
                  ? "tw-bg-[#23426e] tw-text-white "
                  : "tw-bg-gray-100"
              }`}
            >
              Sleeping Arrangements
            </span>
            <span
              onClick={() => handleFormStep(7)}
              className={`tw-flex-shrink-0 tw-p-4 tw-text-sm tw-border-b-2 tw-cursor-pointer tw-border-gray-400  tw-block ${
                formStep === 7
                  ? "tw-bg-[#23426e] tw-text-white "
                  : "tw-bg-gray-100"
              }`}
            >
              Pricing and Fees
            </span>
            <span
              onClick={() => handleFormStep(8)}
              className={`tw-flex-shrink-0 tw-p-4 tw-text-sm tw-border-b-2 tw-cursor-pointer tw-border-gray-400  tw-block ${
                formStep === 8
                  ? "tw-bg-[#23426e] tw-text-white "
                  : "tw-bg-gray-100"
              }`}
            >
              Terms
            </span>
            <span
              onClick={() => handleFormStep(9)}
              className={`tw-rounded-r-md  tw-flex-shrink-0 tw-p-4 tw-text-sm tw-border-b-2 tw-cursor-pointer tw-border-gray-400  tw-block ${
                formStep === 9
                  ? "tw-bg-[#23426e] tw-text-white "
                  : "tw-bg-gray-100"
              }`}
            >
              Security Checkpoint
            </span>
          </div>
          <BasicDetailsForm
            className={`${formStep === 0 ? "tw-block" : "tw-hidden"}`}
            onNext={() => handleNextStep(formStep)}
            onPrev={() => handlePrevStep(formStep)}
            formData={formData}
            setFormData={setFormData}
            save={save}
            updating={updating}
          />
          <Details
            className={`${formStep === 1 ? "tw-block" : "tw-hidden"}`}
            formData={formData}
            setFormData={setFormData}
            onNext={() => handleNextStep(formStep)}
            onPrev={() => handlePrevStep(formStep)}
            save={save}
            updating={updating}
          />
          <HouseRules
            onNext={() => handleNextStep(formStep)}
            onPrev={() => handlePrevStep(formStep)}
            formData={formData}
            setFormData={setFormData}
            className={`${formStep === 2 ? "tw-block" : "tw-hidden"}`}
            save={save}
            updating={updating}
          />
          <AddPhotos
            onNext={() => handleNextStep(formStep)}
            onPrev={() => handlePrevStep(formStep)}
            formData={formData}
            setFormData={setFormData}
            className={`${formStep === 3 ? "tw-block" : "tw-hidden"}`}
            savePhoto={savePhoto}
            updating={updating}
          />

          <Amenities
            className={`${formStep === 4 ? "tw-block" : "tw-hidden"}`}
            formData={formData}
            amenityList={amenityList}
            setFormData={setFormData}
            onNext={() => handleNextStep(formStep)}
            onPrev={() => handlePrevStep(formStep)}
            save={save}
            updating={updating}
          />
          <SafetyFeatures
            className={`${formStep === 5 ? "tw-block" : "tw-hidden"}`}
            formData={formData}
            safetyFeatureList={safetyFeatureList}
            setFormData={setFormData}
            onNext={() => handleNextStep(formStep)}
            onPrev={() => handlePrevStep(formStep)}
            save={save}
            updating={updating}
          />
          <SleepingArrangements
            formData={formData}
            className={`${formStep === 6 ? "tw-block" : "tw-hidden"}`}
            setFormData={setFormData}
            onNext={() => handleNextStep(formStep)}
            onPrev={() => handlePrevStep(formStep)}
            save={save}
            updating={updating}
          />
          <PricingAndFee
            className={`${formStep === 7 ? "tw-block" : "tw-hidden"}`}
            formData={formData}
            setFormData={setFormData}
            onNext={() => handleNextStep(formStep)}
            onPrev={() => handlePrevStep(formStep)}
            save={save}
            updating={updating}
          />
          <Terms
            className={`${formStep === 8 ? "tw-block" : "tw-hidden"}`}
            formData={formData}
            setFormData={setFormData}
            onNext={() => handleNextStep(formStep)}
            onPrev={() => handlePrevStep(formStep)}
            save={save}
            updating={updating}
          />

          <SecurityCheckPoints
            className={`${formStep === 9 ? "tw-block" : "tw-hidden"}`}
            formData={formData}
            hostList={hostList}
            setFormData={setFormData}
            requesting={requesting}
            onNext={() => handleFormSubmission()}
            onPrev={() => handlePrevStep(formStep)}
            save={save}
            updating={updating}
          />
          {/* </form> */}
        </div>
      )}
    </Wrapper>
  );
}

export default EditProperty;
