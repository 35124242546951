import React, { useState, useEffect, useRef, useCallback } from "react";
import { logout } from "../../_helpers";
import { useHistory } from "react-router-dom";
import Navbar from "./../../components/Navbar";
import TopBar from "./../../components/topbar";
import constant from "../../_config/constant";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getChatToken, updateUserResource } from "./../../_services/index";
import Conversation from "./Conversation";
import ChatList from "./ChatList";
import { Client } from "twilio-chat";

function Messages() {
  const history = useHistory();
  const client = useRef(null);
  const data = localStorage.getItem(constant.DATA_KEY);
  const user = data ? JSON.parse(data) : {};

  const [initialLoading, setInitialLoading] = useState(true);
  const [activeChannel, setActiveChannel] = useState(null);
  const [error, setError] = useState(null);
  const [viewList, setViewList] = useState(false);
  const [refreshChatlist, setRefreshChatlist] = useState(false);

  const fetchChatData = useCallback(async () => {
    try {
      setInitialLoading(true);
      const response = await getChatToken(user.id);
      // await updateUserResource(user.id, {
      //   id: user.id,
      //   name: user.name,
      //   profile: user.avatar,
      //   email: user.personalEmail,
      // });
      // Initialize Twilio Client
      client.current = new Client(response.data.data.token);
      // Handle successful initialization
      client.current.on("stateChanged", (state) => {
        console.log("Client state changed:", state);
      });

      client.current.on("connectionStateChanged", (state) => {
        console.log("Connection state changed:", state);
        if (state === "disconnected" || state === "denied") {
          console.warn("Connection lost, attempting to reconnect...");
          handleReconnection();
        }
      });

      client.current.on("tokenAboutToExpire", async () => {
        try {
          const { data } = await getChatToken(user.id);
          await client.current.updateToken(data.data.token);
          console.log("Token refreshed successfully");
        } catch (error) {
          console.error("Failed to refresh token:", error);
          setError("Token refresh failed!");
        }
      });

      client.current.on("messageAdded", (message) => {
        if (message.author !== user.id) {
          setRefreshChatlist((prev) => !prev);
        }
      });

      client.current.on("channelUpdated", (channel) => {
        setRefreshChatlist((prev) => !prev);
      });

      if (
        history &&
        history.location &&
        history.location.state &&
        history.location.state.channelId
      ) {
        setActiveChannel({ ...history.location.state });
        setViewList(true);
      }
    } catch (error) {
      console.log("error", error);
      setError("Failed to initialize chat. Please try again later.");
    } finally {
      setInitialLoading(false); // Stop showing loader after initial fetch
    }
  }, [history.location.state, user.id]);

  const handleReconnection = useCallback(async () => {
    if (client.current && client.current.connectionState === "disconnected") {
      try {
        const { data } = await getChatToken(user.id);
        await client.current.updateToken(data.data.token);
        console.log("Reconnection successful");
      } catch (error) {
        console.error("Reconnection attempt failed:", error);
        setError("Reconnection failed!");
      }
    }
  }, [user.id]);

  useEffect(() => {
    if (user.id) {
      fetchChatData();
    }

    const intervalId = setInterval(handleReconnection, 30000);

    return () => {
      if (client.current) {
        client.current.removeAllListeners();
        client.current.shutdown();
      }
      clearInterval(intervalId);
    };
  }, [fetchChatData, handleReconnection, user.id]);

  const handleChannelsLoaded = (channels) => {
    if (channels.length > 0 && !activeChannel) {
      setActiveChannel(channels[0]);
    }
  };

  return (
    <div id="main-wrapper">
      <ToastContainer />
      <TopBar user={user} showSearch={false} title="Messages" />
      <div className="deznav">
        <div className="deznav-scroll">
          <Navbar />
        </div>
      </div>
      <div className="content-body">
        <div className="">
          <div className="row tw-mx-4">
            <>
              {error && (
                <p className="tw-text-sm tw-text-center tw-text-gray-600 tw-my-12">
                  {error}
                </p>
              )}
              {initialLoading && (
                <p className="tw-text-sm tw-text-center tw-text-gray-600 tw-my-12">
                  Loading...
                </p>
              )}
              {!error && !initialLoading && (
                <div className="tw-grid md:tw-grid-cols-6 tw-h-[80vh] tw-relative tw-mt-10 tw-overflow-hidden">
                  <ChatList
                    chatId={history.location.state}
                    viewList={viewList}
                    setViewList={setViewList}
                    activeChannel={activeChannel}
                    onSelectChannel={setActiveChannel}
                    client={client}
                    refreshChatlist={refreshChatlist}
                    setRefreshChatlist={setRefreshChatlist}
                    onChannelsLoaded={handleChannelsLoaded}
                  />
                  <Conversation
                    setViewList={setViewList}
                    channelDetails={activeChannel}
                    client={client}
                    userId={user.id}
                  />
                </div>
              )}
            </>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Messages;
