import constant from "../_config/constant";
import { authHeader } from "../_helpers";
import { handleResponse } from "../_helpers/utils";

function list(data) {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  return fetch(
    `${constant.apiUrl}/review/list?search=${data.search}&page=${data.page}&limit=${data.limit}`,
    requestOptions
  )
    .then(handleResponse)
    .then((user) => {
      return user;
    });
}

function remove(data) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };
  return fetch(`${constant.apiUrl}/review/delete`, requestOptions)
    .then(handleResponse)
    .then((user) => {
      return user;
    });
}

// function logout() {
//   // remove user from local storage to log user out
//   localStorage.removeItem(constant.TOKEN_KEY);
//   localStorage.removeItem(constant.DATA_KEY);
// }

// function handleResponse(response) {
//   return response.text().then((text) => {
//     const data = text && JSON.parse(text);

//     if (data.statusCode == 401 || data.statusCode == 403) {
//       logout();
//       window.location.reload();
//     }
//     if (data.statusCode != 200) {
//       const error = (data && data.userMessage) || response.statusText;
//       return Promise.reject(error);
//     }
//     return data;
//   });
// }

export const reviewService = {
  list,
  remove,
};
