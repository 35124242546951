import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import constant from '../_config/constant';

const PublicRoute = ({ component: Component, ...rest }) => {
    return (
        <Route {...rest} render={props => (
            localStorage.getItem(constant.TOKEN_KEY) ? <Redirect to={{ pathname: '/profile', state: { from: props.location } }} /> : <Component {...props} /> 
        )} />
    );

};

export default PublicRoute;
