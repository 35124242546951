import React, { useEffect, useState, Component } from "react";
import axios from "axios";
import jwt_decode from "jwt-decode";
import Navbar from "./../../components/Navbar";
import TopBar from "./../../components/topbar";
import { amenityService } from "../../_services";
import constant from '../../_config/constant';
import Footer from "./../../layout/footer";
import { Wrapper } from "../Dashboard/Wrapper";

export default function addProperty(props) {
  const [fileData, setFileData] = useState("");
  const [requesting, setRequest] = useState(false);
  const getFile = (e) => { setFileData(e.target.files[0]); };
  const [title, setTitle] = useState("");
  const [status, setStatus] = useState("");
  const handleStatusChange = (e) => {
    setStatus(e.target.value.toString());
  };

  function onCreatePost(e) {
    const postData = { name: title, image: fileData, status };
    e.preventDefault();
    setRequest(true);
    amenityService.add(postData).then(
      objS => { props.history.push("/amenity"); },
      error => { console.log(error) }
    );
  }

  return (
    <Wrapper title="Add Amentity">
          <div className="row">
            <div className="col-xl-12">
              <div className="tab-content">
                <div className="tab-pane active show" id="All">
                  <form onSubmit={onCreatePost} enctype="multipart/form-data">
                    <div className="form-group row kimargin m-b-10">
                      <label
                        for="staticEmail"
                        className="col-sm-2 col-form-label"
                      >
                        Title
                      </label>
                      <div className="col-sm-10">
                        <input
                          type="text"
                          name="title"
                          value={title}
                          onChange={(e) => setTitle(e.target.value)}
                          className="form-control"
                          placeholder="Title"
                          required
                        />
                      </div>
                    </div>

                    <div className="form-group row kimargin m-b-10">
                      <label
                        for="staticEmail"
                        className="col-sm-2 col-form-label"
                      >
                        Image
                      </label>
                      <div className="col-sm-10">
                        <input
                          type="file"
                          name="images"
                          onChange={getFile}
                          required
                        />
                      </div>
                    </div>

                    {/* <div className="form-group row kimargin">
                      <label
                        for="staticEmail"
                        className="col-sm-2 col-form-label"
                      >
                        Status
                      </label>
                      <div className="col-sm-10">
                        <select
                          name="status"
                          value={status}
                          className="form-select kiselect"
                          onChange={(e) => handleStatusChange(e)}
                          aria-label="Default select example"
                          required
                        >
                          <option value="">Status</option>
                          <option value="Active">Active</option>
                          <option value="InActive">InActive</option>
                        </select>
                      </div>
                    </div> */}
                    <div className="form-group row kimargin m-b-10">
                      <label
                        for="inputPassword"
                        className="col-sm-2 col-form-label"
                      >
                        &nbsp;
                      </label>
                      <div className="col-sm-10">
                        <button type="submit" className="btn btn-primary">
                          {requesting ? "Please wait ..." : "Add Amenity"}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
      <Footer />
    </Wrapper>
  );
}
