import React, { Component } from "react";
import PageNotFound from "./pages/404/404-page";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Plugin from "./components/Plugin";
import { history } from "../src/_helpers";

import PrivateRoute from "./components/privateRoute";
import PublicRoute from "./components/publicRoute";
import Navbar from "./components/Navbar";
import Landing from "./components/Landing";

import Login from "./components/Login";
import Register from "./components/Register";
import Profile from "./components/Profile";
import Dashboard from "./components/Dashboard/";

import ListProperty from "./components/property/List";
import AddProperty from "./components/property/Add";
import EditProperty from "./components/property/Edit";
import UpdateDraftProperty from "./components/property/updateDraft";

import ListPropertyType from "./components/propertytype/List";
import AddPropertyType from "./components/propertytype/Add";
import EditPropertyType from "./components/propertytype/Edit";

import ListAmenity from "./components/amenity/List";
import AddAmenity from "./components/amenity/Add";
import EditAmenity from "./components/amenity/Edit";

import ListSafetyfeature from "./components/safetyfeature/List";
import AddSafetyfeature from "./components/safetyfeature/Add";
import EditSafetyfeature from "./components/safetyfeature/Edit";

import ListCategory from "./components/category/List";
import AddCategory from "./components/category/Add";
import EditCategory from "./components/category/Edit";

import ListSubcategory from "./components/subcategory/List";
import AddSubcategory from "./components/subcategory/Add";
import EditSubcategory from "./components/subcategory/Edit";

import ListBedType from "./components/bedtype/List";
import AddBedType from "./components/bedtype/Add";
import EditBedType from "./components/bedtype/Edit";

import ListDomicile from "./components/domicile/List";
import AddDomicile from "./components/domicile/Add";
import EditDomicile from "./components/domicile/Edit";

import ListFaqCategory from "./components/faqcategory/List";
import AddFaqCategory from "./components/faqcategory/Add";
import EditFaqCategory from "./components/faqcategory/Edit";

import ListFaqSubCategory from "./components/faqsubcategory/List";
import AddFaqSubCategory from "./components/faqsubcategory/Add";
import EditFaqSubCategory from "./components/faqsubcategory/Edit";

import ListFaq from "./components/faq/List";
import AddFaq from "./components/faq/Add";
import EditFaq from "./components/faq/Edit";

import ListCancellationName from "./components/cancellationname/List";
import AddCancellationName from "./components/cancellationname/Add";
import EditCancellationName from "./components/cancellationname/Edit";

import ListCancellationType from "./components/cancellationtype/List";
import AddCancellationType from "./components/cancellationtype/Add";
import EditCancellationType from "./components/cancellationtype/Edit";

import ListFees from "./components/fees/List";
import AddFees from "./components/fees/Add";

import ManageFee from "./components/feeManagement/manage";

import ListPenalty from "./components/penalty/List";
import AddPenalty from "./components/penalty/Add";
import EditPenalty from "./components/penalty/Edit";

// import ListDispute from "./components/dispute/List";
// import AddDispute from "./components/dispute/Add";
// import EditDispute from "./components/dispute/Edit";
// import ViewDispute from "./components/dispute/View";

import ListUserType from "./components/usertype/List";

import AddUserType from "./components/usertype/Add";
import EditUserType from "./components/usertype/Edit";

import ListBookingType from "./components/bookingtype/List";
import AddBookingType from "./components/bookingtype/Add";
import EditBookingType from "./components/bookingtype/Edit";

import ListMessageBoard from "./components/messageboard/List";
import AddMessageBoard from "./components/messageboard/Add";
import EditMessageBoard from "./components/messageboard/Edit";

import ListReservation from "./components/reservation/List";
import ReservationDetails from "./components/reservation/details";

import ListReview from "./components/reviews/list";

import ListCrewType from "./components/crewtype/List";
import AddCrewType from "./components/crewtype/Add";
import EditCrewType from "./components/crewtype/Edit";

import ContactusList from "./components/contactus/list";
import ReportList from "./components/report/list";

import TermsAndCondition from "./components/terms&condition";

import DisputeList from "./components/dispute";
import DisputeDetails from "./components/dispute/details";

import Message from "./components/Message/index";

import "./App.css";
import "./styles/main.css";

export default function App({ currentUser, checkUserSession }) {
  return (
    <Router history={history}>
      <Plugin />
      <Switch>
        <PublicRoute exact path="/register" component={Register} />
        <PublicRoute exact path="/" component={Login} />
        <PublicRoute exact path="/login" component={Login} />
        <PrivateRoute exact path="/profile" component={Profile} />
        <PrivateRoute exact path="/dashboard" component={Dashboard} />
        <PrivateRoute path="/message" component={Message} />;
        <PrivateRoute exact path="/properties" component={ListProperty} />
        <PrivateRoute exact path="/properties/add" component={AddProperty} />
        <PrivateRoute
          exact
          path="/properties/edit/:id"
          component={EditProperty}
        />
        <PrivateRoute
          exact
          path="/properties/add/:id"
          component={UpdateDraftProperty}
        />
        <PrivateRoute
          exact
          path="/propertiestype"
          component={ListPropertyType}
        />
        <PrivateRoute
          exact
          path="/propertiestype/add"
          component={AddPropertyType}
        />
        <PrivateRoute
          exact
          path="/propertiestype/edit/:id"
          component={EditPropertyType}
        />
        <PrivateRoute exact path="/amenity" component={ListAmenity} />
        <PrivateRoute exact path="/amenity/add" component={AddAmenity} />
        <PrivateRoute exact path="/amenity/edit/:id" component={EditAmenity} />
        <PrivateRoute
          exact
          path="/safetyfeature"
          component={ListSafetyfeature}
        />
        <PrivateRoute
          exact
          path="/safetyfeature/add"
          component={AddSafetyfeature}
        />
        <PrivateRoute
          exact
          path="/safetyfeature/edit/:id"
          component={EditSafetyfeature}
        />
        <PrivateRoute path="/dispute/list" component={DisputeList} />
        <PrivateRoute path="/dispute/details/:id" component={DisputeDetails} />
        <PrivateRoute exact path="/category" component={ListCategory} />
        <PrivateRoute exact path="/category/add" component={AddCategory} />
        <PrivateRoute
          exact
          path="/category/edit/:id"
          component={EditCategory}
        />
        <PrivateRoute exact path="/subcategory" component={ListSubcategory} />
        <PrivateRoute
          exact
          path="/subcategory/add"
          component={AddSubcategory}
        />
        <PrivateRoute
          exact
          path="/subcategory/edit/:id"
          component={EditSubcategory}
        />
        <PrivateRoute exact path="/bedtype" component={ListBedType} />
        <PrivateRoute exact path="/bedtype/add" component={AddBedType} />
        <PrivateRoute exact path="/bedtype/edit/:id" component={EditBedType} />
        <PrivateRoute exact path="/domicile" component={ListDomicile} />
        <PrivateRoute exact path="/domicile/add" component={AddDomicile} />
        <PrivateRoute
          exact
          path="/domicile/edit/:id"
          component={EditDomicile}
        />
        <PrivateRoute exact path="/managefee" component={ManageFee} />
        <PrivateRoute exact path="/faqcategory" component={ListFaqCategory} />
        <PrivateRoute
          exact
          path="/faqcategory/add"
          component={AddFaqCategory}
        />
        <PrivateRoute
          exact
          path="/faqcategory/edit/:id"
          component={EditFaqCategory}
        />
        <PrivateRoute
          exact
          path="/faqsubcategory"
          component={ListFaqSubCategory}
        />
        <PrivateRoute
          exact
          path="/faqsubcategory/add"
          component={AddFaqSubCategory}
        />
        <PrivateRoute
          exact
          path="/faqsubcategory/edit/:id"
          component={EditFaqSubCategory}
        />
        <PrivateRoute exact path="/faq" component={ListFaq} />
        <PrivateRoute exact path="/faq/add" component={AddFaq} />
        <PrivateRoute exact path="/faq/edit/:id" component={EditFaq} />
        <PrivateRoute
          exact
          path="/cancellationname"
          component={ListCancellationName}
        />
        <PrivateRoute
          exact
          path="/cancellationname/add"
          component={AddCancellationName}
        />
        <PrivateRoute
          exact
          path="/cancellationname/edit/:id"
          component={EditCancellationName}
        />
        <PrivateRoute
          exact
          path="/cancellationtype"
          component={ListCancellationType}
        />
        <PrivateRoute
          exact
          path="/cancellationtype/add"
          component={AddCancellationType}
        />
        <PrivateRoute
          exact
          path="/cancellationtype/edit/:id"
          component={EditCancellationType}
        />
        <PrivateRoute exact path="/fees" component={ListFees} />
        <PrivateRoute exact path="/fees/add" component={AddFees} />
        <PrivateRoute exact path="/penalty" component={ListPenalty} />
        <PrivateRoute exact path="/penalty/add" component={AddPenalty} />
        <PrivateRoute exact path="/penalty/edit/:id" component={EditPenalty} />
        {/* <PrivateRoute exact path="/dispute" component={ListDispute} />
        <PrivateRoute exact path="/dispute/add" component={AddDispute} />
        <PrivateRoute exact path="/dispute/edit/:id" component={EditDispute} />
        <PrivateRoute exact path="/dispute/view/:id" component={ViewDispute} /> */}
        <PrivateRoute exact path="/user" component={ListUserType} />
        {/* <PrivateRoute exact path="/usertype/add" component={AddUserType} />
        <PrivateRoute
          exact
          path="/usertype/edit/:id"
          component={EditUserType}
        /> */}
        <PrivateRoute exact path="/bookingtype" component={ListBookingType} />
        <PrivateRoute
          exact
          path="/bookingtype/add"
          component={AddBookingType}
        />
        <PrivateRoute
          exact
          path="/bookingtype/edit/:id"
          component={EditBookingType}
        />
        <PrivateRoute exact path="/messageboard" component={ListMessageBoard} />
        <PrivateRoute
          exact
          path="/messageboard/add"
          component={AddMessageBoard}
        />
        <PrivateRoute
          exact
          path="/messageboard/edit/:id"
          component={EditMessageBoard}
        />
        <PrivateRoute exact path="/reservation" component={ListReservation} />
        <PrivateRoute path="/reservation/:id" component={ReservationDetails} />
        <PrivateRoute exact path="/review" component={ListReview} />
        <PrivateRoute exact path="/crewtype" component={ListCrewType} />
        <PrivateRoute exact path="/crewtype/add" component={AddCrewType} />
        <PrivateRoute
          exact
          path="/crewtype/edit/:id"
          component={EditCrewType}
        />
        <PrivateRoute exact path="/contact-us" component={ContactusList} />
        <PrivateRoute exact path="/report" component={ReportList} />
        <PrivateRoute
          exact
          path="/terms-condition"
          component={TermsAndCondition}
        />
        <Route component={PageNotFound} />
      </Switch>
    </Router>
  );
}
