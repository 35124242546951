import constant from "../_config/constant";
import { authHeader } from "../_helpers";
import { handleResponse } from "../_helpers/utils";

function list(data) {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  return fetch(
    `${constant.apiUrl}/dispute?page=${data.page}&limit=${data.limit}`,
    requestOptions
  )
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

function details(id) {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  return fetch(`${constant.apiUrl}/dispute/${id}/details`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

function getAllDisputeDetails(id) {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(`${constant.apiUrl}/dispute/details/${id}`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

function reply(id, message) {
  const requestOptions = {
    method: "POST",
    body: JSON.stringify({
      disputeId: id,
      message,
      type: "Admin",
    }),
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  return fetch(`${constant.apiUrl}/dispute/reply`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

// function logout() {
//   // remove user from local storage to log user out
//   localStorage.removeItem(constant.TOKEN_KEY);
//   localStorage.removeItem(constant.DATA_KEY);
// }

// function handleResponse(response) {
//   return response.text().then((text) => {
//     const data = text && JSON.parse(text);

//     if (data.statusCode == 401 || data.statusCode == 403) {
//       logout();
//       window.location.reload();
//     }
//     if (data.statusCode != 200) {
//       const error = (data && data.userMessage) || response.statusText;
//       return Promise.reject(error);
//     }
//     return data;
//   });
// }

export const disputeService = {
  list,
  details,
  reply,
  getAllDisputeDetails,
};
