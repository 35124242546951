import React, { useState } from "react";
import Navbar from "../Navbar";
import TopBar from "../topbar";

import "react-toastify/dist/ReactToastify.css";

import constant from "../../_config/constant";
var Modal = require("react-bootstrap-modal");
export const Wrapper = (props) => {
  var data = localStorage.getItem(constant.DATA_KEY);
  const decoded = data ? JSON.parse(data) : {};
  return (
    <div id="main-wrapper">
      <TopBar
        user={decoded}
        title={props.title}
        type={props.type}
        showUserFilter={props.showUserFilter}
        handleSelect={props.handleSelect}
        showSearch={props.showSearch}
        handleSearch={props.handleSearch}
        search={props.search}
        showDownload={props.showDownload}
        exportProperties={props.exportProperties}
        handleSearchByState={props.handleSearchByState}
        showFilterPropertyByState={props.showFilterPropertyByState}
        exportUsersData={props.exportUsersData}
        exportContacts={props.exportContacts}
        handleSearchByStatus={props.handleSearchByStatus}
        handlePayoutVerificationStatus={props.handlePayoutVerificationStatus}
      />

      <div className={`deznav`}>
        {/* <div className="deznav-scroll"> */}
        <Navbar />
        {/* </div>   */}
      </div>

      <div className="content-body">
        <div className="container-fluid">{props.children}</div>
      </div>
    </div>
  );
};
