import React, { useEffect } from "react";
import ReactDOM from "react-dom";

const el = document.createElement("div");
const modal = document.getElementById("modal");

function Modal({ children }) {
  useEffect(() => {
    modal.appendChild(el);
    return () => {
      if (modal.hasChildNodes()) {
        modal.removeChild(el);
      }
    };
  });

  return ReactDOM.createPortal(
    <div className="react-modal tw-fixed tw-top-0 tw-right-0 tw-bottom-0 tw-left-0 tw-flex tw-justify-center tw-items-center tw-h-screen">
      <div className=" tw-rounded-2xl tw-w-8/12 tw-mx-auto"> {children}</div>
    </div>,
    el
  );
}

export default Modal;
