import React, { useEffect, useState, Component } from "react";
import axios from "axios";
import jwt_decode from "jwt-decode";
import Navbar from "./../../components/Navbar";
import TopBar from "./../../components/topbar";
import { categoryService } from "../../_services";
import constant from '../../_config/constant';
import Footer from "./../../layout/footer";
import { Wrapper } from "../Dashboard/Wrapper";


export default function addCategory(props) {
  const [requesting, setRequest] = useState(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [userType, setUserType] = useState("");

  function onCreatePost(e) {
    const postData = { name , description, userType };
    e.preventDefault();
    setRequest(true);
    categoryService.add(postData).then(
      objS => { props.history.push("/faqcategory"); },
      error => { console.log(error) }
    );
  }

  return (
    <Wrapper title="Add Category">
            <div className="row">
              <div className="col-xl-12">
                <div className="tab-content">
                  <div className="tab-pane active show" id="All">
                    <form onSubmit={onCreatePost} >
                      <div className="form-group row kimargin m-b-10">
                        <label
                          for="staticEmail"
                          className="col-sm-2 col-form-label"
                        >
                          Name
                        </label>
                        <div className="col-sm-10">
                          <input
                            type="text"
                            name="name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            className="form-control"
                            placeholder="Name"
                            required
                          />
                        </div>
                      </div>

                      <div className="form-group row kimargin m-b-10">
                        <label
                          for="staticEmail"
                          className="col-sm-2 col-form-label"
                        >
                          Description
                        </label>
                        <div className="col-sm-10">
                          <input
                            type="text"
                            name="description"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            className="form-control"
                            placeholder="Description"
                            required
                          />
                        </div>
                      </div>

                      <div className="form-group row kimargin tw-mb-2">
                      <label
                        for="staticEmail"
                        className="col-sm-2 col-form-label"
                      >
                        For User
                      </label>
                      <div className="col-sm-10">
                        <select
                          value={userType}
                          className="form-control"
                          onChange={(e) => setUserType(e.target.value)}
                          required
                        >
                          <option value="">Select</option>
                          <option value="Host">Host</option>
                          <option value="Crewmember">Crewmember</option>
                        </select>
                      </div>
                    </div>

                      <div className="form-group row kimargin m-b-10">
                        <label
                          for="inputPassword"
                          className="col-sm-2 col-form-label"
                        >
                          &nbsp;
                        </label>
                        <div className="col-sm-10">
                          <button type="submit" className="btn btn-primary">
                            {requesting ? "Please wait ..." : "Add Category"}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
        <Footer />
      </Wrapper>
  );
}
